import ListItemIcon from "@mui/material/ListItemIcon";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import {StepStatusState} from "types/NavigationController";

interface Props extends Pick<StepStatusState, "completed" | "visited" | "optional" | "loading"> {}

export const navbarStatusIcon = ({completed, visited, optional, loading}: Props) => {
  if (loading) {
    return <HourglassTopIcon sx={{color: "warning.main"}} />;
  } else if (completed) {
    return <CheckCircleOutlineIcon sx={{color: "success.light"}} />;
  } else if (visited && !optional) {
  // prevents showing completed before visiting page
    return <ErrorOutlineIcon sx={{color: "warning.main"}} />;
  }
  return null;
};

export default function NavigationBarStatusIcon({completed, visited, optional, loading}: Props) {
  return (
    <ListItemIcon sx={{justifyContent: "flex-end"}}>
      {/* 4 states: complete, incomplete, unvisited */}
      {navbarStatusIcon({completed, visited, optional, loading})}
    </ListItemIcon>
  );
}
