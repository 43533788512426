import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getContactInfo, getIsLocked, getLoanTypeConsolidated} from "store/selectors/basicInfo";
import {LoanType, TextType} from "@pinch-financial/pinch-ui-components";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {trackEvent} from "util/eventUtil";
import {MortgageTypePage as MortgageTypePageUI} from "@pinch-financial/pinch-ui-components";
import {getNavigationError} from "store/selectors/navigationController";
import i18next from "i18next";
import NavigationBar from "components/NavigationBar/NavigationBar";
import SaveButton from "components/SaveButton/SaveButton";
import {initialize, next} from "./MortgageTypePage.action";
import {getEnabledLoanTypes} from "store/selectors/providerconfigurations";
import usePageText from "hooks/usePageText";
import {useTranslation} from "react-i18next";

interface Props {
  onPrevPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const MortgageTypePage = ({containerRef, onPrevPage = () => {}}: Props) => {
  const {t} = useTranslation("", {keyPrefix: "mortgageTypePage"});
  const {getText, status: textStatus} = usePageText("MORTGAGE_INFO_URL_MORTGAGE_TYPE");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locked = useSelector(getIsLocked);
  const {email} = useSelector(getContactInfo);
  const storedLoanType = useSelector(getLoanTypeConsolidated);
  const [loanType, setLoanType] = useState(storedLoanType);
  const [fieldError, setFieldError] = useState<string>();
  const navigationError = useSelector(getNavigationError);
  const enabledLoanTypes = useSelector(getEnabledLoanTypes);
  const {isPending} = useSelector(getOperationResult)(OperationType.submitLoanType);

  function onBack() {
    trackEvent(TrackingEventType.mortgageSelectionClickBack);
    onPrevPage();
  }

  function onSetLoanType(loanType: LoanType, trackingEventType: TrackingEventType) {
    if (locked) {
      setFieldError(t("messages.appLocked")!);
    } else {
      trackEvent(trackingEventType);
      setLoanType(loanType);
      dispatch(next({navigate, data: loanType}));
    }
  }

  useEffect(() => {
    setFieldError(navigationError ?? "");
  }, [navigationError]);

  useEffect(() => {
    dispatch(initialize({navigate, enabledLoanTypes}));
  }, []);

  return (
    <MortgageTypePageUI
      containerRef={containerRef}
      loading={textStatus === "not-started" || textStatus === "pending"}
      header={getText(TextType.APP_PAGE_HEADER) ?? t("header")!}
      description={getText(TextType.APP_PAGE_BODY) ?? t("description")}
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? t("tipHeader")!}
      tipBody={
        <span
          dangerouslySetInnerHTML={{
            __html: getText(TextType.APP_PAGE_TIP_BODY, {email}) ?? t("tipBody", {email})!,
          }}
        />
      }
      navigationBar={<NavigationBar />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" />}
      backButtonText={i18next.t("backButton")}
      storedLoanType={loanType}
      pageError={fieldError}
      disabled={locked || isPending}
      onPrevPage={onBack}
      enabledLoanTypes={enabledLoanTypes}
      onClickNewPurchase={() => {
        onSetLoanType(LoanType.NEW_PURCHASE, TrackingEventType.mortgageSelectionClickNewPurchase);
      }}
      newPurchaseTitle={t("newMortgage")}
      newPurchaseSubtitle={t("newMortgageSubtitle")}
      onClickPreapproval={() => {
        onSetLoanType(LoanType.PREAPPROVAL, TrackingEventType.mortgageSelectionClickPreApproval);
      }}
      preapprovalTitle={t("preapproval")}
      preapprovalSubtitle={t("preapprovalSubtitle")}
      onClickRenewal={() => {
        onSetLoanType(LoanType.RENEWAL, TrackingEventType.mortgageSelectionClickRenewal);
      }}
      renewalTitle={t("renewal")}
      renewalSubtitle={t("renewalSubtitle")}
      iconAlt={t("iconAlt")}
    />
  );
};

export default MortgageTypePage;
