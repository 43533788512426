import {DeclinedPage as DeclinedPageUI} from "@pinch-financial/pinch-ui-components";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import {getContactInfo} from "store/selectors/basicInfo";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const DeclinedPage = ({containerRef}: Props) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "declinedPage"});
  const navigate = useNavigate();
  const {email} = useSelector(getContactInfo);
  return (
    <DeclinedPageUI
      containerRef={containerRef}
      navigationBar={<NavigationBar />}
      body={<span dangerouslySetInnerHTML={{__html: fixedT("body", {email: email})!}} />}
      onPrevPage={() => {
        navigate(AppRouteUrl.DISCLOSURE_URL);
      }}
      backButtonText={fixedT("backButton")}
    />
  );
};

export default DeclinedPage;
