import {useDispatch, useSelector} from "react-redux";
import {getBasicInfo} from "store/selectors/basicInfo";
import {OnNextPage, PageRef, QueryParamKeys} from "@pinch-financial/pinch-ui-components";
import {Province} from "types/enums/province";
import {
  ProvinceSelectPageFormData,
  ProvinceSelectPage as ProvinceSelectPageUI,
} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import i18next from "i18next";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import {useRef, useState} from "react";
import SaveButton from "components/SaveButton/SaveButton";
import {getSelectedProvince, setLocalStorageItem} from "util/localStorageUtil";
import {submit} from "./ProvinceSelectPage.action";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface Props {
  className?: string;
  onPrevPage?: () => any;
  onNextPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const ProvinceSelectPage = ({
  onPrevPage = () => {},
  onNextPage = () => {},
  containerRef,
}: Props) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "provinceSelectPage"});

  const pageRef = useRef<PageRef>(null);
  const basicInfo = useSelector(getBasicInfo);
  const isKeyboardOpen = useDetectKeyboardOpen();
  const [fieldError, setFieldError] = useState<string>();
  const selectedProvince = useSelector(getSelectedProvince);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const provinceOptions = (typeVal: Province) => {
    return fixedT(`provinces.${typeVal}`);
  };

  const onSubmit: OnNextPage<ProvinceSelectPageFormData> = (values) => {
    setFieldError("");
    if (values) {
      setLocalStorageItem(QueryParamKeys.SELECTED_PROVINCE, values.province);
      dispatch(submit({selectedProvince: values.province, onNextPage, navigate}));
    }
  };

  return (
    <ProvinceSelectPageUI
      containerRef={containerRef}
      ref={pageRef}
      onPrevPage={onPrevPage}
      onNextPage={onSubmit}
      isMobileKeyboardOpen={isKeyboardOpen}
      navigationBar={<NavigationBar pageRef={pageRef} />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" pageRef={pageRef} />}
      backButtonText={i18next.t("backButton")}
      nextButtonText={i18next.t("submitButton")}
      header={fixedT("header")!}
      description={fixedT("description")}
      pageError={fieldError}
      labelProvince={fixedT("labelProvince")}
      // Default values
      defaultProvince={
        (basicInfo?.residentialAddress?.province as Province) ??
        (selectedProvince as Province) ??
        undefined
      }
      provinceOptions={provinceOptions}
      missingProvince={fixedT("messages.missing", {
        context: "province",
      })}
    />
  );
};

export default ProvinceSelectPage;
