import {
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Box,
  useTheme,
} from "@mui/material";
import {DRAWER_WIDTH} from "appConstants";
import CompanyLogo from "components/CompanyLogo/CompanyLogo";
import useOnResize from "../../hooks/useOnResize/useOnResize";
import {useState, useEffect, RefObject} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {
  getAcceptedPrivacyPolicy,
  getConsolidatedBasicInfo,
  getIsBankRequestLoading,
  getIsLocked,
} from "store/selectors/basicInfo";
import {
  getActiveRouteSequence,
  getHasNavbarAssetLiabilitiesStepClicked,
  getNavigationError,
  getNavigationStepStatus,
  getStepURLs,
} from "store/selectors/navigationController";
import {getIsMortgageProviderLogoEnabled} from "store/selectors/theme";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {preventDefaultBehaviour} from "util/eventUtil";
import NavigationBarStatusIcon from "./NavigationBarStatusIcon/NavigationBarStatusIcon";
import NavigationBarTop from "./NavigationBarTop/NavigationBarTop";
import {handleNewUrl, navigateStep} from "./NavigationBar.action";
import * as styles from "./NavigationBar.module.scss";
import NavigationBarActionSection from "./NavigationBarActionSection/NavigationBarActionSection";
import NavigationBarMobile from "./NavigationBarMobile/NavigationBarMobile";
import NavigationBarDesktop from "./NavigationBarDesktop/NavigationBarDesktop";
import {useTranslation} from "react-i18next";
import {isCurrentUrlAnyOf} from "components/utils/urlUtil";
import {LanguageType} from "types/enums/languageType";
import {NavigationStep, NavStep, PageRef} from "@pinch-financial/pinch-ui-components";
import {NavStepCaption} from "types/enums/navStep";
import {usePreviewContext} from "contexts/PreviewContext";
import {useAnimationContext} from "contexts/AnimationContext";
import {getIsNavigationMenuEnabled} from "store/selectors/configurations";
import {getIsOperationDone, getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {findLastIndex} from "lodash";
import {getHasUser} from "store/selectors/user";
import {getFinancialInstitution} from "store/selectors/consolidatedSelectors";
import {getNextPageUrl, RoutesRequireFinancialInstitutionByAppOrAuthId} from "util/routeUtil";
import {isBlank} from "components/utils/stringUtil";

interface NavbarLink {
  step: NavStep;
  text: string;
  url: AppRouteUrl;
  current: boolean;
  completed: boolean;
  optional: boolean;
  visited: boolean;
  hidden: boolean;
  hasVisitedViaNavbar: boolean;
  loading?: boolean;
  enabled?: boolean;
  canSkipInNavigationBar?: boolean; // If current nav menu is (visited && canSkipInNavigationBar) or completed, then next nav menu will be enabled.
}

export interface NavigationBarProps {
  pageRef?: RefObject<PageRef> | null; // The ref to the page that exposes the submitForm method.
}

export default function NavigationBar({pageRef}: NavigationBarProps) {
  const {t: tNavBar} = useTranslation("", {keyPrefix: "enums.navStep"});
  const {t} = useTranslation("", {keyPrefix: "navigationBar"});

  const [isMobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [triggerRerender, setTriggerRerender] = useState(false);

  const {i18n} = useTranslation("errors");
  const provider = useSelector(getFinancialInstitution);
  const isMortgageProviderLogoEnabled = useSelector(getIsMortgageProviderLogoEnabled);
  const navigationStepStatus = useSelector(getNavigationStepStatus);
  const hasUser = useSelector(getHasUser);
  const hasUserAcceptedPrivacyPolicy = useSelector(getAcceptedPrivacyPolicy);
  const isBankRequestLoading = useSelector(getIsBankRequestLoading);
  const activeRouteSequence = useSelector(getActiveRouteSequence);
  const basicInfo = useSelector(getConsolidatedBasicInfo);
  const hasNavbarAssetLiabilitiesStepClicked = useSelector(getHasNavbarAssetLiabilitiesStepClicked);
  const isFetchingLendersDone = useSelector(getIsOperationDone)(OperationType.fetchLenders);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const {isScreenMobileWidth} = useOnResize(theme.breakpoints);
  const appIsLocked = useSelector(getIsLocked);

  const isPreview = usePreviewContext();
  const isAnimating = useAnimationContext();

  const handleDrawerToggle = () => {
    setMobileDrawerOpen(!isMobileDrawerOpen);
  };

  const welcomeURLs = useSelector(getStepURLs(NavigationStep.WELCOME));
  const mortgageTypeURLs = useSelector(getStepURLs(NavigationStep.MORTGAGE_TYPE));
  const lendersURLs = useSelector(getStepURLs(NavigationStep.LENDERS));
  const signUpURLs = useSelector(getStepURLs(NavigationStep.SIGN_UP));
  const personalProfileURLs = useSelector(getStepURLs(NavigationStep.PERSONAL_PROFILE));
  const bankingURLs = useSelector(getStepURLs(NavigationStep.BANKING));
  const creditURLs = useSelector(getStepURLs(NavigationStep.CREDIT));
  const navigationBarEnabled = useSelector(getIsNavigationMenuEnabled);
  const {isDone: fetchFinancialInstDone} = useSelector(getOperationResult)(
    OperationType.fetchAppAssociatedFinancialInstitutionByAppOrAuthId
  );
  const providerReady = RoutesRequireFinancialInstitutionByAppOrAuthId.includes(location.pathname)
    ? fetchFinancialInstDone
    : true;

  const onStepChangeHandler = (step?: NavStep) => {
    // Hacky solution for now. We'll be modifiying what pages being in the AppPages section
    // so there will be bigger changes
    const defaultStep =
      location.pathname === AppRouteUrl.LANDING_PAGE_URL ||
      location.pathname === AppRouteUrl.PROVINCE_SELECT_PAGE_URL
        ? NavStep.WELCOME
        : undefined;

    const finalStep = step ?? defaultStep;

    if (finalStep) {
      dispatch(handleNewUrl({url: location.pathname, step: finalStep}));
    }
  };

  const isInSignUpStep = isCurrentUrlAnyOf([
    AppRouteUrl.SIGN_UP,
    AppRouteUrl.SECONDARY_SIGNUP,
    AppRouteUrl.SIGN_IN,
    AppRouteUrl.DISCLOSURE_URL,
    AppRouteUrl.ACTIVATE_ACCOUNT_URL,
    AppRouteUrl.RESEND_EMAIL_URL,
    AppRouteUrl.RESET_PASSWORD_URL,
    AppRouteUrl.LOGIN_CALLBACK,
    AppRouteUrl.INVITE_SECONDARY_APPLICANT,
  ]);

  const NAVBAR_LINKS: NavbarLink[] = [
    {
      step: NavStep.WELCOME,
      text: NavStepCaption(tNavBar).WELCOME,
      url: AppRouteUrl.LANDING_PAGE_URL,
      current: isCurrentUrlAnyOf(welcomeURLs),
      completed: navigationStepStatus[NavStep.WELCOME]?.completed,
      optional: navigationStepStatus[NavStep.WELCOME]?.optional,
      visited: navigationStepStatus[NavStep.WELCOME]?.visited,
      hidden: navigationStepStatus[NavStep.WELCOME]?.hidden,
      hasVisitedViaNavbar: navigationStepStatus[NavStep.WELCOME]?.hasVisitedViaNavbar,
    },
    {
      step: NavStep.MORTGAGE_TYPE,
      text: NavStepCaption(tNavBar).MORTGAGE_TYPE,
      url: mortgageTypeURLs[0],
      current: isCurrentUrlAnyOf(mortgageTypeURLs),
      completed: navigationStepStatus[NavStep.MORTGAGE_TYPE]?.completed,
      optional: navigationStepStatus[NavStep.MORTGAGE_TYPE]?.optional,
      visited: navigationStepStatus[NavStep.MORTGAGE_TYPE]?.visited,
      hidden: navigationStepStatus[NavStep.MORTGAGE_TYPE]?.hidden,
      hasVisitedViaNavbar: navigationStepStatus[NavStep.MORTGAGE_TYPE]?.hasVisitedViaNavbar,
    },
    {
      step: NavStep.LENDERS,
      text: NavStepCaption(tNavBar).LENDERS,
      url: lendersURLs[0],
      current: isCurrentUrlAnyOf(lendersURLs),
      completed: navigationStepStatus[NavStep.LENDERS]?.completed,
      optional: navigationStepStatus[NavStep.LENDERS]?.optional,
      visited: navigationStepStatus[NavStep.LENDERS]?.visited,
      hidden: navigationStepStatus[NavStep.LENDERS]?.hidden,
      hasVisitedViaNavbar: navigationStepStatus[NavStep.LENDERS]?.hasVisitedViaNavbar,
    },
    {
      step: NavStep.SIGN_UP,
      text: NavStepCaption(tNavBar).SIGN_UP,
      url: signUpURLs[0],
      current: isCurrentUrlAnyOf(signUpURLs),
      completed: navigationStepStatus[NavStep.SIGN_UP]?.completed,
      optional: navigationStepStatus[NavStep.SIGN_UP]?.optional,
      visited: navigationStepStatus[NavStep.SIGN_UP]?.visited,
      hidden: navigationStepStatus[NavStep.SIGN_UP]?.hidden,
      hasVisitedViaNavbar: navigationStepStatus[NavStep.SIGN_UP]?.hasVisitedViaNavbar,
    },
    {
      step: NavStep.PERSONAL_PROFILE,
      text: NavStepCaption(tNavBar).PERSONAL_PROFILE,
      url:
        personalProfileURLs.filter((url) => url !== AppRouteUrl.INVITE_SECONDARY_APPLICANT)[0] ||
        getNextPageUrl(
          AppRouteUrl.INVITE_SECONDARY_APPLICANT,
          activeRouteSequence,
          basicInfo,
          hasNavbarAssetLiabilitiesStepClicked
        ),
      current: isCurrentUrlAnyOf(personalProfileURLs),
      completed: navigationStepStatus[NavStep.PERSONAL_PROFILE]?.completed,
      optional: navigationStepStatus[NavStep.PERSONAL_PROFILE]?.optional,
      visited: navigationStepStatus[NavStep.PERSONAL_PROFILE]?.visited,
      hidden: navigationStepStatus[NavStep.PERSONAL_PROFILE]?.hidden,
      hasVisitedViaNavbar: navigationStepStatus[NavStep.PERSONAL_PROFILE]?.hasVisitedViaNavbar,
    },
    {
      step: NavStep.BANKING,
      text: NavStepCaption(tNavBar).BANKING,
      url: bankingURLs[0],
      current: isCurrentUrlAnyOf(bankingURLs),
      completed: navigationStepStatus[NavStep.BANKING]?.completed,
      loading: isBankRequestLoading && !isFetchingLendersDone,
      optional: navigationStepStatus[NavStep.BANKING]?.optional,
      visited: navigationStepStatus[NavStep.BANKING]?.visited,
      hidden: navigationStepStatus[NavStep.BANKING]?.hidden,
      hasVisitedViaNavbar: navigationStepStatus[NavStep.BANKING]?.hasVisitedViaNavbar,
      canSkipInNavigationBar: true,
    },
    {
      step: NavStep.CREDIT,
      text: NavStepCaption(tNavBar).CREDIT,
      url: creditURLs[0],
      current: isCurrentUrlAnyOf(creditURLs),
      completed: navigationStepStatus[NavStep.CREDIT]?.completed,
      optional: navigationStepStatus[NavStep.CREDIT]?.optional,
      visited: navigationStepStatus[NavStep.CREDIT]?.visited,
      hidden: navigationStepStatus[NavStep.CREDIT]?.hidden,
      hasVisitedViaNavbar: navigationStepStatus[NavStep.CREDIT]?.hasVisitedViaNavbar,
    },
    {
      step: NavStep.SELECT_PRODUCT,
      text: NavStepCaption(tNavBar).SELECT_PRODUCT,
      url: appIsLocked ? AppRouteUrl.APPLICATION_COMPLETE_URL : AppRouteUrl.SELECT_LENDERS_URL,
      current: isCurrentUrlAnyOf([
        AppRouteUrl.DETAILS_COMPLETE_URL,
        AppRouteUrl.SELECT_LENDERS_URL,
        AppRouteUrl.APPLICATION_COMPLETE_URL,
      ]),
      completed: navigationStepStatus[NavStep.SELECT_PRODUCT]?.completed,
      optional: navigationStepStatus[NavStep.SELECT_PRODUCT]?.optional,
      visited: navigationStepStatus[NavStep.SELECT_PRODUCT]?.visited,
      hidden: navigationStepStatus[NavStep.SELECT_PRODUCT]?.hidden,
      hasVisitedViaNavbar: navigationStepStatus[NavStep.SELECT_PRODUCT]?.hasVisitedViaNavbar,
    },
  ].filter((link) => !link.hidden);

  const getCurrentStep = () => NAVBAR_LINKS.find((option) => option.current)?.step;
  const signUpStepIndex = NAVBAR_LINKS.findIndex((option) => option.step === NavStep.SIGN_UP);
  const lastNavCompletionIndex = findLastIndex(
    NAVBAR_LINKS,
    (link) => link.completed || (!!link.canSkipInNavigationBar && link.visited)
  );

  NAVBAR_LINKS.map((link, index) => {
    if (index < signUpStepIndex) {
      link.enabled = !hasUserAcceptedPrivacyPolicy;
    } else if (index === signUpStepIndex) {
      link.enabled = !hasUser && isInSignUpStep;
    } else {
      link.enabled =
        hasUserAcceptedPrivacyPolicy && !isInSignUpStep && index <= lastNavCompletionIndex + 1;
    }
  });

  const navigationBarDisplay = (
    <>
      <Toolbar disableGutters className={styles.toolbar}>
        <CompanyLogo />
      </Toolbar>
      <Divider />
      <List>
        {NAVBAR_LINKS.filter((item) => !item.hidden).map(
          ({text, current, url, step, visited, completed, optional, loading, enabled}) => {
            return (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  data-testid={`navbar-link-${step}`}
                  selected={current}
                  disabled={!enabled}
                  onClick={(e) => {
                    preventDefaultBehaviour(e);
                    dispatch(
                      navigateStep({
                        url, // Navbar should not skip page.
                        step,
                        navigate,
                      })
                    );
                  }}
                >
                  <ListItemText primary={text} />
                  <ListItemIcon sx={{justifyContent: "flex-end"}}>
                    <NavigationBarStatusIcon
                      completed={completed}
                      visited={visited}
                      optional={optional}
                      loading={loading}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            );
          }
        )}
      </List>
      <Divider sx={{marginTop: "auto"}} />
      <NavigationBarActionSection />
      <Divider />
      {isMortgageProviderLogoEnabled && providerReady && provider && (
        <List className={styles.providerLogoItemContainer}>
          <ListItem className={styles.providerLogoItem}>
            <ListItemIcon>
              <img
                src={
                  provider?.logo
                    ? `${window._env_.REACT_APP_IMGIX_DOMAIN}/${provider?.logo}?auto=format`
                    : ""
                }
                aria-label={provider?.name}
                alt={provider?.name}
              />
            </ListItemIcon>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                i18n.language === LanguageType.EN_CA
                  ? provider?.complianceText
                  : provider?.complianceTextFr
              }
              secondary={
                isBlank(provider?.licenceNumber)
                  ? undefined
                  : t("licenceNumber", {licenceNumber: provider.licenceNumber})
              }
            />
          </ListItem>
        </List>
      )}
    </>
  );

  useEffect(() => {
    onStepChangeHandler(getCurrentStep());
  }, [location.pathname]);

  useEffect(() => {
    if (isFetchingLendersDone) {
      setTriggerRerender(!triggerRerender);
    }
  }, [isFetchingLendersDone]);

  if (!navigationBarEnabled) {
    return null;
  }

  return (
    <>
      <Box
        component="nav"
        sx={{width: {laptop: "21.25%", desktop: DRAWER_WIDTH}}}
        aria-label="navigation bar"
        className={styles.root}
      >
        {!isPreview && !isAnimating && (
          <NavigationBarTop
            isMobileView={isScreenMobileWidth}
            handleDrawerToggle={handleDrawerToggle}
            pageRef={pageRef}
          />
        )}
        {isScreenMobileWidth ? (
          <>
            <NavigationBarMobile
              handleDrawerToggle={handleDrawerToggle}
              isMobileDrawerOpen={isMobileDrawerOpen}
            >
              {navigationBarDisplay}
            </NavigationBarMobile>
          </>
        ) : (
          !isPreview &&
          !isAnimating && <NavigationBarDesktop>{navigationBarDisplay}</NavigationBarDesktop>
        )}
      </Box>
    </>
  );
}
