import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getConsolidatedBasicInfo, getIsLocked} from "store/selectors/basicInfo";
import {DocumentType} from "types/enums/documentType";
import {
  submitFile,
  initialize,
  openUserAcceptedDocumentUploadWarningModal,
  acceptMissingDocumentUploadWarning,
} from "./DocumentUploadPage.action";
import {getIsAllOperationsDone, getIsAnyOperationPending} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {UPLOAD_FILE_SIZE_MAX_IN_BYTES, UPLOAD_FILE_SIZE_MAX_IN_MB} from "appConstants";
import {ConfigurationKey} from "@pinch-financial/pinch-ui-components";

import {getIsProviderConfigurationValueEnabled} from "store/selectors/providerconfigurations";
import {trackEvent} from "util/eventUtil";
import {TextType, TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {isMobile} from "react-device-detect";
import {preventDefaultBehaviour} from "util/eventUtil";
import i18next from "i18next";
import {
  APPLICANT_DOCUMENT_FILE_TYPES,
  DocumentUploadPage as DocumentUploadPageUI,
} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import {
  getApplicantMeta,
  getHasUserAcceptedDocumentUploadWarning,
  getShouldUserAcceptedDocumentUploadWarningModalOpen,
} from "store/selectors/applicantMeta";
import {AllDocStateAndRequirements} from "types/docStateAndRequirements";
import SaveButton from "components/SaveButton/SaveButton";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {getNextPageUrl} from "util/routeUtil";
import {
  getActiveRouteSequence,
  getHasNavbarAssetLiabilitiesStepClicked,
  getPreviousUrlFromHistory,
} from "store/selectors/navigationController";
import {useTranslation} from "react-i18next";
import usePageText from "hooks/usePageText";

interface DocStateAndRequirements {
  name: string;
  required: boolean;
  hidden: boolean;
  uploaded?: boolean;
}

const operationsTypesAllDocuments = [
  OperationType.submitT4,
  OperationType.submitNoa,
  OperationType.submitPaystub,
  OperationType.submitMls,
  OperationType.submitClosingDocument,
  OperationType.submitBankStatement,
];
export function isAllDocumentsHidden(docStateAndRequirements: AllDocStateAndRequirements) {
  return !Object.values(docStateAndRequirements).reduce((isHidden, docRequirement) => {
    return isHidden == true || docRequirement.hidden == false;
  }, false);
}

export function isAllDocumentsOptional(docStateAndRequirements: AllDocStateAndRequirements) {
  return !Object.values(docStateAndRequirements).reduce((isRequired, docRequirement) => {
    return isRequired == true || docRequirement.required == true;
  }, false);
}

export function isAnyDocumentsRequired(docStateAndRequirements: AllDocStateAndRequirements) {
  return Object.values(docStateAndRequirements).some((docRequirement) => docRequirement.required);
}

export function shouldSkipTheDocumentPage(docStateAndRequirements: AllDocStateAndRequirements) {
  // if any are required, stay on page otherwise, if all are hidden, skip page
  if (isAnyDocumentsRequired(docStateAndRequirements)) {
    return false;
  }
  return isAllDocumentsHidden(docStateAndRequirements);
}

export function isAllRequiredDocumentsUploaded(
  docStateAndRequirements: AllDocStateAndRequirements,
  callback?: ((arg0: string) => void) | undefined
) {
  return !Object.values(docStateAndRequirements).reduce(
    (requiredMissing, docRequirement, index) => {
      const requirementDocMissing =
        docRequirement.required == true && docRequirement.uploaded == false;
      if (requirementDocMissing && callback) {
        callback(Object.keys(docStateAndRequirements)[index]);
      }
      return (
        requiredMissing == true ||
        (docRequirement.required == true && docRequirement.uploaded == false)
      );
    },
    false
  );
}

interface Props {
  className?: () => any;
  onNextPage?: () => any;
  onPrevPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const DocumentUploadPage: React.FC<Props> = ({onNextPage = () => {}, onPrevPage, containerRef}) => {
  const {t} = useTranslation("", {keyPrefix: "documentUploadPage"});
  const {t: loadingT} = useTranslation("", {keyPrefix: "Loading"});
  const {getText, status: textStatus} = usePageText("BASIC_INFO_URL_UPLOAD_DOCUMENTS");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locked = useSelector(getIsLocked);
  const {
    uploadedT4,
    uploadedNOA,
    uploadedPayStub,
    uploadedBankStatement,
    uploadedMLS,
    uploadedClosingDocument,
    documents,
  } = useSelector(getConsolidatedBasicInfo);

  const documentTypes = Object.values(DocumentType);

  const [fieldError, setFieldError] = useState<string>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [progress, setProgress] = useState(-1);

  const hasLoaded = useSelector(getIsAllOperationsDone)(OperationType.fetchProviderConfigurations);
  const isPending = useSelector(getIsAnyOperationPending)(...operationsTypesAllDocuments);
  const previousURL = useSelector(getPreviousUrlFromHistory);

  const activeRouteSequence: AppRouteUrl[] = useSelector(getActiveRouteSequence);

  const hasUserAcceptedDocumentUploadWarning = useSelector(getHasUserAcceptedDocumentUploadWarning);

  const shouldUserAcceptedDocumentUploadWarning = useSelector(
    getShouldUserAcceptedDocumentUploadWarningModalOpen
  );
  const basicInfo = useSelector(getConsolidatedBasicInfo);

  const hasNavbarAssetLiabilitiesStepClicked = useSelector(getHasNavbarAssetLiabilitiesStepClicked);
  const docStateAndRequirements: {[key in DocumentType]: DocStateAndRequirements} = {
    [DocumentType.T4]: {
      name: t("documentType", {context: DocumentType.T4}),
      required: useSelector(
        getIsProviderConfigurationValueEnabled(ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_T4)
      ),
      hidden: useSelector(
        getIsProviderConfigurationValueEnabled(ConfigurationKey.ORGANIZATION_DOCUMENTS_HIDDEN_T4)
      ),
      uploaded: uploadedT4,
    },
    [DocumentType.NOA]: {
      name: t("documentType", {context: DocumentType.NOA}),
      required: useSelector(
        getIsProviderConfigurationValueEnabled(ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_NOA)
      ),
      hidden: useSelector(
        getIsProviderConfigurationValueEnabled(ConfigurationKey.ORGANIZATION_DOCUMENTS_HIDDEN_NOA)
      ),
      uploaded: uploadedNOA,
    },
    [DocumentType.PAYSTUB]: {
      name: t("documentType", {context: DocumentType.PAYSTUB}),
      required: useSelector(
        getIsProviderConfigurationValueEnabled(
          ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_PAYSTUB
        )
      ),
      hidden: useSelector(
        getIsProviderConfigurationValueEnabled(
          ConfigurationKey.ORGANIZATION_DOCUMENTS_HIDDEN_PAYSTUB
        )
      ),
      uploaded: uploadedPayStub,
    },
    [DocumentType.BANK_STATEMENT]: {
      name: t("documentType", {context: DocumentType.BANK_STATEMENT}),
      required: useSelector(
        getIsProviderConfigurationValueEnabled(
          ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_BANK_STATEMENT
        )
      ),
      hidden: useSelector(
        getIsProviderConfigurationValueEnabled(
          ConfigurationKey.ORGANIZATION_DOCUMENTS_HIDDEN_BANK_STATEMENT
        )
      ),
      uploaded: uploadedBankStatement,
    },
    [DocumentType.MLS]: {
      name: t("documentType", {context: DocumentType.MLS}),
      required: useSelector(
        getIsProviderConfigurationValueEnabled(ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_MLS)
      ),
      hidden: useSelector(
        getIsProviderConfigurationValueEnabled(ConfigurationKey.ORGANIZATION_DOCUMENTS_HIDDEN_MLS)
      ),
      uploaded: uploadedMLS,
    },
    [DocumentType.CLOSING_DOCUMENT]: {
      name: t("documentType", {context: DocumentType.CLOSING_DOCUMENT}),
      required: useSelector(
        getIsProviderConfigurationValueEnabled(
          ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_CLOSING_DOCUMENT
        )
      ),
      hidden: useSelector(
        getIsProviderConfigurationValueEnabled(
          ConfigurationKey.ORGANIZATION_DOCUMENTS_HIDDEN_CLOSING_DOCUMENT
        )
      ),
      uploaded: uploadedClosingDocument,
    },
  };

  function onModalUpdate(value: boolean) {
    dispatch(openUserAcceptedDocumentUploadWarningModal(value));
  }

  useEffect(() => {
    if (previousURL === AppRouteUrl.SELECT_LENDERS_URL) {
      setModalOpen(true);
    }
  }, []);

  useEffect(() => {
    dispatch(initialize({navigate, docStateAndRequirements}));
  }, [hasLoaded]);

  useEffect(() => {
    if (!hasUserAcceptedDocumentUploadWarning && shouldUserAcceptedDocumentUploadWarning) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [shouldUserAcceptedDocumentUploadWarning, hasUserAcceptedDocumentUploadWarning]);

  async function handleFileUpload(event: any, documentType: DocumentType): Promise<void> {
    preventDefaultBehaviour(event);
    if (locked) {
      setFieldError(t("messages.appLocked")!);
      return;
    }

    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    if (!file) {
      return;
    }
    if (!file.type || !APPLICANT_DOCUMENT_FILE_TYPES.includes(file.type)) {
      setFieldError(t("messages.fileTypeNotSupported")!);
      return;
    }
    if (file?.size > UPLOAD_FILE_SIZE_MAX_IN_BYTES) {
      setFieldError(t("messages.fileTooBig", {maxSizeInMB: UPLOAD_FILE_SIZE_MAX_IN_MB})!);
      return;
    }
    setFieldError("");
    dispatch(
      submitFile({
        file,
        documentType,
        progressCallback: (progressValue) => {
          if (progressValue === 100) {
            setProgress(-1);
          } else {
            setProgress(progressValue);
          }
        },
      })
    );
  }
  return (
    <DocumentUploadPageUI
      containerRef={containerRef}
      loading={textStatus === "not-started" || textStatus === "pending" || !hasLoaded}
      header={getText(TextType.APP_PAGE_HEADER) ?? t("header")}
      description={getText(TextType.APP_PAGE_BODY) ?? t("description")}
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? t("tipHeader")!}
      tipBody={getText(TextType.APP_PAGE_TIP_BODY) ?? t("tipBody")}
      onPrevPage={() => {
        trackEvent(TrackingEventType.uploadDocumentsClickBackButton);
        onPrevPage?.();
      }}
      onNextPage={() => {
        if (
          isAllRequiredDocumentsUploaded(docStateAndRequirements) ||
          hasUserAcceptedDocumentUploadWarning
        ) {
          trackEvent(TrackingEventType.uploadDocumentsClickNextButton);
          navigate(
            getNextPageUrl(
              AppRouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS,
              activeRouteSequence,
              basicInfo,
              hasNavbarAssetLiabilitiesStepClicked
            )
          );
        } else {
          dispatch(openUserAcceptedDocumentUploadWarningModal(true));
        }
      }}
      navigationBar={<NavigationBar />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" />}
      backButtonText={i18next.t("backButton")}
      pageError={fieldError}
      loadingMessage={loadingT("message")}
      isPending={isPending}
      documents={documents}
      uploadDocumentText={isMobile ? t("uploadDocumentMobile") : t("uploadDocument")}
      documentTypes={documentTypes}
      docStateAndRequirements={docStateAndRequirements}
      handleFileUpload={handleFileUpload}
      maxUploadSizeText={t("maxUploadSize", {maxSizeInMB: UPLOAD_FILE_SIZE_MAX_IN_MB})}
      trackEvent={trackEvent}
      uploadedText={t("uploaded")}
      completeText={t("complete")}
      loadingText={t("loading")}
      bytesText={t("bytes")}
      kbText={t("kb")}
      mbText={t("mb")}
      unknownText={t("unknown")}
      nextButtonText={t("nextButton")}
      uploadProgress={progress}
      shouldModalOpen={modalOpen}
      modalTitle={t("modalTitle")}
      modalSubtitle={t("modalSubtitle")}
      modalCancelButtonText={t("modalCancel")}
      modalConfirmButtonText={t("modalForward")}
      onModalUpdate={onModalUpdate}
      hasUserAcceptedDataWarning={!!hasUserAcceptedDocumentUploadWarning}
      onSkipAndNextPage={() => {
        trackEvent(TrackingEventType.uploadDocumentsClickSkipButton);
        dispatch(acceptMissingDocumentUploadWarning(true));
        onNextPage();
      }}
    />
  );
};

export default DocumentUploadPage;
