import {ResidentialApplicationBasicInfoRequest} from "@pinch-financial/pinch-ui-components";
import {WithNavigate} from "@pinch-financial/pinch-ui-components";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";

const namespace = "BanksAndFlinksConnectPage";

export const initialize = createAction<WithNavigate>(actionTypeOf(namespace, "initialize"));
export const submitBank = createAction<
  WithNavigate & Partial<ResidentialApplicationBasicInfoRequest>
>(actionTypeOf(namespace, "submitBank"));
