import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  registerPrimaryUser,
  RegisterPrimaryUserPayload,
} from "../../pages/SignUpPage/SignUpPage.action";
import {getIsAnyOperationPending, getOperationFailure} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {Typography, Link, Slide, Box, Fade} from "@mui/material";
import * as styles from "./SignUpForm.module.scss";
import Form from "components/Form/Form";
import {fetchPolicyApi} from "api/basicInfo";
import {getSelectedProviderName, getWhitelabelId} from "store/selectors/whitelabel";
import TextFieldControlled from "components//Form/TextFieldControlled/TextFieldControlled";
import {useForm} from "react-hook-form";
import {getSchema} from "./SignUpForm.schema";
import {yupResolver} from "@hookform/resolvers/yup";
import {getNavigationError} from "store/selectors/navigationController";
import {removeNavigationError} from "components/NavigationBar/NavigationBar.action";
import CheckboxControlled from "components/Form/CheckboxControlled/CheckboxControlled";
import {getMortgageProviderIdFromAppOrSelected} from "store/selectors/basicInfo";
import {Message, SubmitButton} from "@pinch-financial/pinch-ui-components";
import AcceptLegalInfo from "components/AcceptLegalInfo/AcceptLegalInfo";
import {ResidentialApplicationType} from "types/enums/residentialApplicationType";
import {useNavigate} from "react-router-dom";
import {getIsContactConsentDisplayed} from "store/selectors/configurations";
import {useTranslation} from "react-i18next";
import usePageText from "hooks/usePageText";

interface SignUpFormProps {
  headerText: string;
  subtitleText: string;
}

export const SignUpForm = ({headerText, subtitleText}: SignUpFormProps) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "signUpForm"});
  const {getText, status: textStatus} = usePageText("SIGN_UP");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [checked, setChecked] = React.useState(false);
  const containerRef = React.useRef<HTMLElement>(null);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const isPending = useSelector(getIsAnyOperationPending)(OperationType.registerPrimaryUser);
  const failureError = useSelector(getOperationFailure)(OperationType.registerPrimaryUser);
  const navigationError = useSelector(getNavigationError);
  const whiteLabelId = useSelector(getWhitelabelId);
  const organizationName = useSelector(getSelectedProviderName);
  const providerId = useSelector(getMortgageProviderIdFromAppOrSelected);
  const showContactConsent = useSelector(getIsContactConsentDisplayed);

  const formHook = useForm<RegisterPrimaryUserPayload>({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      applicationType: ResidentialApplicationType.SINGLE_APPLICANT, // TODO: remove from QM. This can be updated during the flow
      phoneNumber: "",
      basicInfo: {
        consentedToContact: showContactConsent ? false : true,
      },
    },
    resolver: yupResolver(getSchema()),
  });

  const [policyContent, setPolicyContent] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string>("");

  const onSubmit = (values: RegisterPrimaryUserPayload) => {
    dispatch(removeNavigationError());
    dispatch(registerPrimaryUser({...values, navigate}));
  };
  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;

  useEffect(() => {
    const fetchPolicy = async () => {
      try {
        const {data: privacyPolicy} = await fetchPolicyApi(whiteLabelId);
        setPolicyContent(privacyPolicy);
      } catch (error) {
        console.error("Failed to fetch policy", error);
      }
    };

    fetchPolicy();
  }, [whiteLabelId]);

  useEffect(() => {
    if (navigationError) {
      setError(navigationError ?? "");
    }
    if (!providerId) {
      setError(fixedT("error", {context: "missingFinancialInstitution"})!);
    } else if (failureError) {
      if (failureError?.status && (failureError.status === 400 || failureError.status === 409)) {
        setError(fixedT("emailExists")!);
      } else if (failureError?.status && failureError.status != 200) {
        setError(fixedT("error", {context: failureError?.status})!);
      }
    }
  }, [navigationError, failureError]);

  const timeout = {enter: 1800, exit: 800};

  return (
    <Box className={styles.root} ref={containerRef} data-testid="SignUpForm">
      <Slide
        direction="right"
        appear={false}
        in={!checked}
        mountOnEnter
        unmountOnExit
        container={containerRef.current}
        timeout={timeout}
      >
        <div>
          <Fade appear={false} in={!checked} timeout={timeout}>
            <Box className={styles.formWrapper}>
              <Typography variant="h1" align="left">
                {headerText}
              </Typography>
              <Message message={error} className={styles.error} />
              <Typography className={styles.subtitle}> {subtitleText}</Typography>
              <Form className={styles.fields} onEnter={formHook.handleSubmit(onSubmit)}>
                <div className={styles.twoColumns}>
                  <div className={styles.col}>
                    <TextFieldControlled
                      fullWidth
                      formHook={formHook}
                      name="firstName"
                      type="text"
                      label={fixedT("fields.firstName.label")}
                      placeholder={fixedT("fields.firstName.placeholder")!}
                      autoComplete="firstName"
                      disabled={isPending}
                      required
                    />
                  </div>
                  <div className={styles.col}>
                    <TextFieldControlled
                      fullWidth
                      formHook={formHook}
                      name="lastName"
                      type="text"
                      label={fixedT("fields.lastName.label")}
                      placeholder={fixedT("fields.lastName.placeholder")!}
                      autoComplete="lastName"
                      disabled={isPending}
                      required
                    />
                  </div>
                </div>
                <TextFieldControlled
                  fullWidth
                  formHook={formHook}
                  name="email"
                  type="email"
                  label={fixedT("fields.email.label")}
                  placeholder={fixedT("fields.email.placeholder")!}
                  autoComplete="email"
                  disabled={isPending}
                  required
                />
                <TextFieldControlled
                  fullWidth
                  formHook={formHook}
                  name="phoneNumber"
                  type="tel"
                  label={fixedT("fields.phoneNumber.label")}
                  placeholder={fixedT("fields.phoneNumber.placeholder")!}
                  autoComplete="phoneNumber"
                  required
                />
                <div className={styles.fineprint}>
                  <Typography variant="body2">
                    {fixedT("termsAndConditions")}{" "}
                    <Link tabIndex={0} variant="body2" onClick={handleChange}>
                      {fixedT("termsAndConditionsLink")}
                    </Link>
                  </Typography>
                </div>
                {organizationName && (
                  <div className={styles.checkboxContainer}>
                    <>
                      <div className={styles.checkboxWrapper}>
                        <CheckboxControlled formHook={formHook} name="emailOptIn" />
                      </div>

                      <Typography variant="body2" className={styles.typography} gutterBottom>
                        {fixedT("emailOptIn", {name: organizationName})}
                      </Typography>
                    </>
                  </div>
                )}

                {showContactConsent && (
                  <div className={styles.checkboxContainer}>
                    <div className={styles.checkboxWrapper}>
                      <CheckboxControlled formHook={formHook} name="consentedToContact" />
                    </div>

                    <Typography variant="body2" className={styles.typography} gutterBottom>
                      {fixedT("contactConsentText")}
                    </Typography>
                  </div>
                )}
              </Form>
              <SubmitButton
                className={styles.submitButton}
                isEnabled={!isPending}
                onClick={formHook.handleSubmit(onSubmit)}
              >
                {fixedT("forwardButton")}
              </SubmitButton>
            </Box>
          </Fade>
        </div>
      </Slide>

      <Slide
        direction="left"
        in={checked}
        mountOnEnter
        unmountOnExit
        container={containerRef.current}
        timeout={timeout}
      >
        <div>
          <Fade in={checked} timeout={timeout}>
            <Box sx={{position: "absolute"}}>
              <AcceptLegalInfo title={fixedT("policyTitle")!} onClose={handleChange}>
                {policyContent || fixedT("errorPolicy")}
              </AcceptLegalInfo>
            </Box>
          </Fade>
        </div>
      </Slide>
    </Box>
  );
};
