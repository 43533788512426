import {useEffect, useRef, useState} from "react";
import "dayjs/locale/fr";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getHasFeatureSin} from "store/selectors/theme";
import {
  getBasicInfo,
  getContactInfo,
  getCreditReportFailedAttempts,
  getDidUserConsentToCreditPull,
  getHasCreditSuccess,
  getIsLocked,
  getIsSinRequired,
  getNamesConsolidated,
} from "store/selectors/basicInfo";
import {
  acceptMissingCreditReportWarning,
  EquifaxPageFormData,
  openUserAcceptedMissingCreditReportWarning,
  submit,
} from "./EquifaxPage.action";
import {
  getIsOperationSuccess,
  getOperationFailure,
  getOperationResult,
} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {AppRouteUrl, OnNextPage, PageRef, TextType} from "@pinch-financial/pinch-ui-components";
import i18next from "i18next";
import {EquifaxPage as EquifaxPageUI, LanguageType} from "@pinch-financial/pinch-ui-components";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import NavigationBar from "components/NavigationBar/NavigationBar";
import SaveButton from "components/SaveButton/SaveButton";
import {getPreviousUrlFromHistory} from "store/selectors/navigationController";
import {useTranslation} from "react-i18next";
import usePageText from "hooks/usePageText";
import {getIsCreditPullConsentDisplayedEnabled} from "~/store/selectors/providerconfigurations";
import {getAppAssociatedFinancialInstitution} from "~/store/selectors/financialInstitution";
import {
  getHasUserAcceptedMissingCreditReportWarning,
  getShouldUserAcceptedMissingCreditReportWarning,
} from "~/store/selectors/applicantMeta";

interface Props {
  className?: () => any;
  onPrevPage?: () => any;
  onNextPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const EquifaxPage: React.FC<Props> = ({
  onPrevPage = () => {},
  onNextPage = () => {},
  containerRef,
}) => {
  const {t} = useTranslation("", {keyPrefix: "equifaxPage"});
  const {t: consentModalT} = useTranslation("", {keyPrefix: "equifaxPage.consentModal"});
  const {getText, status: textStatus} = usePageText("BASIC_INFO_URL_SIN_FORM");

  const pageRef = useRef<PageRef>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const basicInfo = useSelector(getBasicInfo);
  const {isPending} = useSelector(getOperationResult)(OperationType.submitLegalNamesSin);
  const failureError = useSelector(getOperationFailure)(OperationType.submitLegalNamesSin);
  const {email} = useSelector(getContactInfo);
  const locked = useSelector(getIsLocked);
  const hasCreditSuccess = useSelector(getHasCreditSuccess);
  const creditReportFailedAttempts = useSelector(getCreditReportFailedAttempts);
  const previousURL = useSelector(getPreviousUrlFromHistory);
  const isFetchConfigSuccess = useSelector(getIsOperationSuccess)(
    OperationType.fetchProviderConfigurations
  );
  const isCreditPullConsentDisplayedEnabled = useSelector(getIsCreditPullConsentDisplayedEnabled);
  const didUserConsentToCreditPull = useSelector(getDidUserConsentToCreditPull);
  const isKeyboardOpen = useDetectKeyboardOpen();

  const hasSinFeature = useSelector(getHasFeatureSin);
  const SINRequired = useSelector(getIsSinRequired);
  const {firstName, lastName} = useSelector(getNamesConsolidated);
  const financialInstitution = useSelector(getAppAssociatedFinancialInstitution);

  const [isSkipButton, setIsSkipButton] = useState(false);
  const [fieldError, setFieldError] = useState<string>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const hasUserAcceptedMissingCreditReportWarning = useSelector(
    getHasUserAcceptedMissingCreditReportWarning
  );
  const shouldUserAcceptedMissingCreditReportWarning = useSelector(
    getShouldUserAcceptedMissingCreditReportWarning
  );

  function onModalUpdate() {
    dispatch(openUserAcceptedMissingCreditReportWarning(false));
    setModalOpen(false);
  }

  const onSubmit: OnNextPage<EquifaxPageFormData> = (values, _event, shouldStay) => {
    const hasCreditPullConsentValue = !!values?.consentedToCreditPull;
    setFieldError("");
    if (isSkipButton && !shouldStay) {
      onNextPage?.();
      return;
    }

    if (
      isCreditPullConsentDisplayedEnabled &&
      !hasCreditPullConsentValue &&
      !hasUserAcceptedMissingCreditReportWarning &&
      !modalOpen
    ) {
      setModalOpen(true);
      return;
    }
    // If user consented to credit pull, then we submit.
    // If user doesn't consent and accepted missing credit warning, then we submit as well to save other data.
    // we want to save the legal names and DOB if provided
    dispatch(
      submit({
        data: {
          ...values,
          dateOfBirth: values?.dateOfBirth
            ? new Date(values.dateOfBirth).toISOString().split("T")[0] // QM only accepts it in this format; yyyy-mm-dd
            : null,
        } as EquifaxPageFormData,
        navigate,
        onNextPage,
        shouldStay,
      })
    );
  };

  useEffect(() => {
    if (hasCreditSuccess) {
      setFieldError("");
    } else {
      // Failed to pull credit report once, allow one retry.
      if (creditReportFailedAttempts == 1 || failureError?.status == 400) {
        setFieldError(t("badInputError")!);
      }

      if (failureError?.status == 500) {
        setIsSkipButton(true);
        setFieldError(t("unknownError")!);
      }
    }
  }, [failureError, creditReportFailedAttempts, hasCreditSuccess]);

  // when user tries to navigate away from the page
  useEffect(() => {
    if (
      !hasUserAcceptedMissingCreditReportWarning &&
      shouldUserAcceptedMissingCreditReportWarning &&
      !modalOpen
    ) {
      setModalOpen(true);
    } else {
      modalOpen && setModalOpen(false);
    }
  }, [shouldUserAcceptedMissingCreditReportWarning, hasUserAcceptedMissingCreditReportWarning]);

  const buttonText = isSkipButton ? t("skipButton") : t("nextButton");

  return (
    <EquifaxPageUI
      containerRef={containerRef}
      ref={pageRef}
      loading={!isFetchConfigSuccess || textStatus === "not-started" || textStatus === "pending"}
      header={getText(TextType.APP_PAGE_HEADER) ?? t("header")}
      description={getText(TextType.APP_PAGE_BODY) ?? t("description")}
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? t("tipHeader")}
      tipBody={
        <span
          dangerouslySetInnerHTML={{
            __html: getText(TextType.APP_PAGE_TIP_BODY, {email}) ?? t("tipBody", {email})!,
          }}
        />
      }
      onPrevPage={onPrevPage}
      onNextPage={onSubmit}
      isMobileKeyboardOpen={isKeyboardOpen}
      navigationBar={<NavigationBar pageRef={pageRef} />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" pageRef={pageRef} />}
      nextButtonText={buttonText}
      backButtonText={i18next.t("backButton")}
      locked={locked || hasCreditSuccess}
      validateOnRender={previousURL === AppRouteUrl.SELECT_LENDERS_URL}
      legalFirstNameLabel={t("form.legalFirstName")}
      legalLastNameLabel={t("form.legalLastName")}
      dateOfBirthLabel={t("form.dateOfBirth")}
      sinLabel={t("form.sin")}
      pageError={fieldError}
      isPending={isPending}
      hasSinFeature={hasSinFeature}
      SINRequired={SINRequired}
      isFrench={i18next.language === LanguageType.FR_CA}
      // Schema props
      firstName={firstName || ""}
      lastName={lastName || ""}
      legalFirstName={basicInfo?.legalFirstName || ""}
      legalLastName={basicInfo?.legalFirstName || ""}
      dateOfBirth={basicInfo?.dateOfBirth}
      missingLegalFirstName={t("form.messages.missing", {context: "legalFirstName"})}
      missingLegalLastName={t("form.messages.missing", {context: "legalLastName"})}
      invalidBirthDate={t("form.messages.invalidBirthDate")}
      invalidUnderage={t("form.messages.invalidUnderage")}
      missingSinNumbers={t("form.messages.missing", {context: "sinNumbers"})}
      invalidSocialInsuranceNumber={t("form.messages.invalidSocialInsuranceNumber")}
      iconAlt={t("iconAlt")}
      consentedToCreditPull={didUserConsentToCreditPull}
      creditPullConsentText={
        isCreditPullConsentDisplayedEnabled
          ? (t("creditPullConsentText", {
              financialInstitution: financialInstitution!.name,
            }) as string)
          : undefined
      }
      // Consent Modal props
      modalTitle={consentModalT("modalTitle")}
      modalSubtitle={consentModalT("modalSubtitle")}
      modalCancelButtonText={consentModalT("modalCancelButtonText")}
      modalConfirmButtonText={consentModalT("modalConfirmButtonText")}
      onSkipAndNextPage={(data: EquifaxPageFormData) => {
        dispatch(acceptMissingCreditReportWarning(true));
        onSubmit(data, null, false);
      }}
      onModalUpdate={onModalUpdate}
      shouldModalOpen={modalOpen}
    />
  );
};

export default EquifaxPage;
