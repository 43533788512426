import {ReactNode} from "react";

export interface WithClassName {
  className?: string;
}

export interface WithChildren {
  children?: ReactNode;
}

export type BaseProps = WithClassName & WithChildren;

export type OnChange<T = string> = (value: T) => void;

export type AnyFunction<T = any, R = any> = (value: T) => R;

export type InputEventHandler = (e: React.FormEvent<HTMLInputElement>) => void;
export interface WithNavigate {
  navigate?: any;
}

export interface WithOnSuccess {
  onSuccess?: (...arg: any[]) => void;
}

export enum MessageType {
  info = "info",
  error = "error",
}

export type PageStatus = "loading" | "submitting" | "success" | "error" | undefined;
