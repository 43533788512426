import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {trackEvent} from "util/eventUtil";
import {TextType, TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {getNavigationError} from "store/selectors/navigationController";
import {PurchaseTimeframe} from "types/enums/purchaseTimeframe";
import {PurchaseTimeframePage as PurchaseTimeframePageUI} from "@pinch-financial/pinch-ui-components";
import {getContactInfo, getIsLocked} from "store/selectors/basicInfo";
import {getPurchaseTimeframe} from "store/selectors/applicantMeta";
import i18next from "i18next";
import NavigationBar from "components/NavigationBar/NavigationBar";
import SaveButton from "components/SaveButton/SaveButton";
import {submit} from "./PurchaseTimeframePage.action";
import {useTranslation} from "react-i18next";
import usePageText from "hooks/usePageText";

interface Props {
  className?: () => any;
  onPrevPage?: () => any;
  onNextPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const PurchaseTimeframePage: React.FC<Props> = ({
  onPrevPage = () => {},
  onNextPage = () => {},
  containerRef,
}) => {
  const {t} = useTranslation("", {keyPrefix: "purchaseTimeframePage"});
  const {getText, status: textStatus} = usePageText("PURCHASE_TIMEFRAME_URL");
  const locked = useSelector(getIsLocked);
  const dispatch = useDispatch();
  const {email} = useSelector(getContactInfo);
  const navigationError = useSelector(getNavigationError);
  const [fieldError, setFieldError] = useState<string>();
  const storedPurchaseTimeframe = useSelector(getPurchaseTimeframe);
  const [purchaseTimeframe, setPurchaseTimeframe] = useState(storedPurchaseTimeframe);

  function onSetPurchaseTimeframe(
    purchaseTimeframe: PurchaseTimeframe,
    trackingEventType: TrackingEventType
  ) {
    trackEvent(trackingEventType);
    setPurchaseTimeframe(purchaseTimeframe);
    dispatch(submit({purchaseTimeframe, onNextPage}));
  }

  useEffect(() => {
    setFieldError(navigationError ?? "");
  }, [navigationError]);

  return (
    <PurchaseTimeframePageUI
      containerRef={containerRef}
      loading={textStatus === "not-started" || textStatus === "pending"}
      header={getText(TextType.APP_PAGE_HEADER) ?? t("header")!}
      description={getText(TextType.APP_PAGE_BODY) ?? t("description")!}
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? t("tipHeader")!}
      tipBody={
        <span
          dangerouslySetInnerHTML={{
            __html: getText(TextType.APP_PAGE_TIP_BODY, {email}) ?? t("tipBody", {email})!,
          }}
        />
      }
      onPrevPage={() => {
        trackEvent(TrackingEventType.purchaseTimeframePageClickBackButton);
        onPrevPage();
      }}
      navigationBar={<NavigationBar />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" />}
      backButtonText={i18next.t("backButton")}
      storedPurchaseTimeframe={purchaseTimeframe}
      pageError={fieldError}
      disabled={locked}
      onClickWithin30Days={() => {
        onSetPurchaseTimeframe(
          PurchaseTimeframe.WITHIN_30_DAYS,
          TrackingEventType.purchaseTimeframePageWithin30Days
        );
      }}
      within30DaysTitle={t("timeframe.within30Days")}
      onClick2to3Months={() => {
        onSetPurchaseTimeframe(
          PurchaseTimeframe.BETWEEN_2_TO_3_MONTHS,
          TrackingEventType.purchaseTimeframePage2to3Months
        );
      }}
      between2to3MonthsTitle={t("timeframe.2to3Months")}
      onClick3to6Months={() => {
        onSetPurchaseTimeframe(
          PurchaseTimeframe.BETWEEN_3_TO_6_MONTHS,
          TrackingEventType.purchaseTimeframePage3to6Months
        );
      }}
      between3to6MonthsTitle={t("timeframe.3to6Months")}
      onClickMoreThan6Months={() => {
        onSetPurchaseTimeframe(
          PurchaseTimeframe.MORE_THAN_6_MONTHS,
          TrackingEventType.purchaseTimeframePageMoreThan6Months
        );
      }}
      moreThan6MonthsTitle={t("timeframe.moreThan6Months")}
      iconAlt={t("iconAlt")}
    />
  );
};

export default PurchaseTimeframePage;
