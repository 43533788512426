import {useEffect, useState} from "react";
import "dayjs/locale/fr";
import {useDispatch, useSelector} from "react-redux";
import {getSelfReportedCreditScore, getIsLocked} from "store/selectors/basicInfo";
import {CreditScoreType} from "types/enums/creditScoreType";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {TextType, TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {trackEvent} from "util/eventUtil";
import {SelfReportedCreditScorePage as SelfReportedCreditScorePageUI} from "@pinch-financial/pinch-ui-components";
import {getUserCreditScore} from "store/selectors/applicantMeta";
import {getNavigationError} from "store/selectors/navigationController";
import i18next from "i18next";
import NavigationBar from "components/NavigationBar/NavigationBar";
import SaveButton from "components/SaveButton/SaveButton";
import {submit} from "./SelfReportedCreditScorePage.action";
import {useTranslation} from "react-i18next";
import usePageText from "hooks/usePageText";

interface Props {
  className?: string;
  onPrevPage?: () => any;
  onNextPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const SelfReportedCreditScorePage = ({
  onPrevPage = () => {},
  onNextPage = () => {},
  containerRef,
}: Props) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "selfReportedCreditScorePage"});
  const {t: errorsT} = useTranslation("", {keyPrefix: "errors"});
  const {getText, status: textStatus} = usePageText("SELF_REPORTED_CREDIT_DIRECT");
  const dispatch = useDispatch();
  const locked = useSelector(getIsLocked);
  const {isPending, failure: backendError} = useSelector(getOperationResult)(
    OperationType.submitSelfReportedCreditScore
  );
  const navigationError = useSelector(getNavigationError);
  const [fieldError, setFieldError] = useState<string>();
  const creditScoreInMeta = useSelector(getUserCreditScore);
  const creditScoreInApp = useSelector(getSelfReportedCreditScore);
  const [creditScore, setCreditScore] = useState(creditScoreInMeta);

  function onChangeScore(newCreditScore: CreditScoreType, trackingEvent: TrackingEventType): void {
    trackEvent(trackingEvent);
    dispatch(submit({creditScore: newCreditScore, onNextPage}));
  }

  useEffect(() => {
    setCreditScore(creditScoreInApp ?? creditScoreInMeta);
  }, [creditScoreInApp, creditScoreInMeta]);

  useEffect(() => {
    setFieldError(navigationError ?? "");
  }, [navigationError]);

  return (
    <SelfReportedCreditScorePageUI
      containerRef={containerRef}
      loading={textStatus === "not-started" || textStatus === "pending"}
      header={getText(TextType.APP_PAGE_HEADER) ?? fixedT("header")!}
      description={getText(TextType.APP_PAGE_BODY) ?? fixedT("description")}
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? fixedT("tipHeader")!}
      tipBody={getText(TextType.APP_PAGE_TIP_BODY) ?? fixedT("tipBody")}
      onPrevPage={() => {
        trackEvent(TrackingEventType.creditPageClickBackButton);
        onPrevPage();
      }}
      navigationBar={<NavigationBar />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" />}
      backButtonText={i18next.t("backButton")}
      storedCreditScore={creditScore}
      pageError={fieldError || (backendError && errorsT("generalMessage"))}
      disabled={locked || isPending}
      onClickCreditScoreExcellent={() => {
        onChangeScore(
          CreditScoreType.EXCELLENT,
          TrackingEventType.creditPageSelectCreditScoreExcellent
        );
      }}
      creditScoreExcellentTitle={fixedT("creditScore", {context: CreditScoreType.EXCELLENT})}
      onClickCreditScoreVeryGood={() => {
        onChangeScore(
          CreditScoreType.VERY_GOOD,
          TrackingEventType.creditPageSelectCreditScoreVeryGood
        );
      }}
      creditScoreVeryGoodTitle={fixedT("creditScore", {context: CreditScoreType.VERY_GOOD})}
      onClickCreditScoreGood={() => {
        onChangeScore(CreditScoreType.GOOD, TrackingEventType.creditPageSelectCreditScoreGood);
      }}
      creditScoreGoodTitle={fixedT("creditScore", {context: CreditScoreType.GOOD})}
      onClickCreditScoreFair={() => {
        onChangeScore(CreditScoreType.FAIR, TrackingEventType.creditPageSelectCreditScoreFair);
      }}
      creditScoreFairTitle={fixedT("creditScore", {context: CreditScoreType.FAIR})}
      onClickCreditScorePoor={() => {
        onChangeScore(CreditScoreType.POOR, TrackingEventType.creditPageSelectCreditScoreUnsure);
      }}
      creditScorePoorTitle={fixedT("creditScore", {context: CreditScoreType.POOR})}
      iconAlt={fixedT("iconAlt")}
    />
  );
};

export default SelfReportedCreditScorePage;
