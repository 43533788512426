import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  submit,
  acceptMissingAssetsDataWarning,
  openUserAcceptedAssetsDataWarningModal,
} from "./SelfReportedAssetsPage.action";
import {getConsolidatedBasicInfo, getIsLocked} from "store/selectors/basicInfo";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {trackEvent} from "util/eventUtil";
import {
  getHasUserAcceptedAssetsDataWarning,
  getShouldUserAcceptedAssetsDataWarningModalOpen,
} from "store/selectors/applicantMeta";
import {
  LanguageType,
  OnNextPage,
  PageRef,
  SelfReportedAssetsPageFormData,
  SelfReportedAssetsPage as SelfReportedAssetsPageUI,
  TextType,
  TrackingEventType,
} from "@pinch-financial/pinch-ui-components";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import NavigationBar from "components/NavigationBar/NavigationBar";
import i18next from "i18next";
import SaveButton from "components/SaveButton/SaveButton";
import {useTranslation} from "react-i18next";
import usePageText from "hooks/usePageText";

interface Props {
  className?: () => any;
  onPrevPage?: () => any;
  onNextPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const SelfReportedAssetsPage: React.FC<Props> = ({
  onPrevPage = () => {},
  onNextPage = () => {},
  containerRef,
}) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "selfReportedAssetsPage"});
  const {getText, status: textStatus} = usePageText("BASIC_INFO_URL_SELF_REPORTED_ASSETS");

  const pageRef = useRef<PageRef>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locked = useSelector(getIsLocked);
  const selfReportedAssets = useSelector(getConsolidatedBasicInfo)?.selfReportedAssets;
  const {isPending, failure} = useSelector(getOperationResult)(
    OperationType.submitSelfReportedIncomeAssets
  );
  const [fieldError, setFieldError] = useState<string>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const hasUserAcceptedAssetsDataWarning = useSelector(getHasUserAcceptedAssetsDataWarning);

  const isKeyboardOpen = useDetectKeyboardOpen();

  const shouldUserAcceptedAssetsDataWarning = useSelector(
    getShouldUserAcceptedAssetsDataWarningModalOpen
  );

  useEffect(() => {
    if (
      !hasUserAcceptedAssetsDataWarning &&
      shouldUserAcceptedAssetsDataWarning &&
      !Boolean(selfReportedAssets?.length)
    ) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [shouldUserAcceptedAssetsDataWarning, hasUserAcceptedAssetsDataWarning]);

  useEffect(() => {
    if (failure) {
      console.error(`Operation error found: ${failure}`);
    }
  }, [failure]);

  function onBack() {
    trackEvent(TrackingEventType.assetsClickBackButton);
    onPrevPage?.();
  }

  function onModalUpdate(value: boolean) {
    dispatch(openUserAcceptedAssetsDataWarningModal(value));
  }

  const onSubmit: OnNextPage<SelfReportedAssetsPageFormData> = (values, event, shouldStay) => {
    trackEvent(TrackingEventType.assetsClickNextButton);
    dispatch(
      submit({
        data: {...values},
        navigate,
        shouldStay,
        onNextPage,
      })
    );
  };

  return (
    <SelfReportedAssetsPageUI
      containerRef={containerRef}
      ref={pageRef}
      loading={textStatus === "not-started" || textStatus === "pending"}
      header={getText(TextType.APP_PAGE_HEADER) ?? fixedT("header")!}
      description={getText(TextType.APP_PAGE_BODY) ?? fixedT("description")}
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? fixedT("tipHeader")!}
      tipBody={getText(TextType.APP_PAGE_TIP_BODY) ?? fixedT("tipBody")}
      onPrevPage={onBack}
      onNextPage={onSubmit}
      isMobileKeyboardOpen={isKeyboardOpen}
      navigationBar={<NavigationBar pageRef={pageRef} />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" pageRef={pageRef} />}
      isPending={isPending}
      locked={locked}
      pageError={fieldError}
      backButtonText={i18next.t("backButton")}
      nextButtonText={i18next.t("submitButton")}
      selfReportedAssets={selfReportedAssets}
      shouldModalOpen={modalOpen}
      hasUserAcceptedDataWarning={hasUserAcceptedAssetsDataWarning}
      onSkipAndNextPage={(data: SelfReportedAssetsPageFormData) => {
        trackEvent(TrackingEventType.assetsClickSkipButton);
        dispatch(acceptMissingAssetsDataWarning(true));
        dispatch(
          submit({
            data: {...data},
            navigate,
            shouldStay: false,
            onNextPage,
          })
        );
      }}
      savingsLabel={fixedT("savings")}
      chequingLabel={fixedT("chequing")}
      rrspLabel={fixedT("rrsp")}
      tfsaLabel={fixedT("tfsa")}
      otherAssetLabel={(index: number) => {
        return fixedT("otherAssetLabel", {index: index});
      }}
      valueLabel={fixedT("value")}
      addAsset={fixedT("addAsset")}
      modalTitle={fixedT("modalTitle")}
      modalSubtitle={fixedT("modalSubtitle")}
      modalCancelButtonText={fixedT("modalCancel")}
      modalConfirmButtonText={fixedT("modalForward")}
      requiredMessage={fixedT("required")}
      onModalUpdate={onModalUpdate}
      isFrench={i18next.language === LanguageType.FR_CA}
      iconAlt={fixedT("iconAlt")}
    />
  );
};

export default SelfReportedAssetsPage;
