import {
  SecondaryApplicantCompletePage as SecondaryApplicantCompletePageUI,
  TextType,
} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import usePageText from "hooks/usePageText";
import {useTranslation} from "react-i18next";

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const SecondaryApplicantCompletePage = ({containerRef}: Props) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "secondaryApplicantCompletePage"});
  const {getText, status: textStatus} = usePageText("JOINT_COMPLETED_URL");

  return (
    <SecondaryApplicantCompletePageUI
      containerRef={containerRef}
      loading={textStatus === "not-started" || textStatus === "pending"}
      header={getText(TextType.APP_PAGE_HEADER) ?? fixedT("header")}
      body={getText(TextType.APP_PAGE_BODY) ?? fixedT("body")}
      navigationBar={<NavigationBar />}
    />
  );
};

export default SecondaryApplicantCompletePage;
