import {UNKNOWN_API_ERROR} from "./error";

export class ApiError extends Error {
  constructor(
    public message: string | any = UNKNOWN_API_ERROR,
    public status: number | undefined = undefined
  ) {
    super(message);
  }
}
