import {LoanType, WithNavigate, WithNavigatePayload} from "@pinch-financial/pinch-ui-components";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";

const namespace = "MortgageTypePage";

export type InitializePayload = WithNavigate & {
  enabledLoanTypes: LoanType[];
};

export const initialize = createAction<InitializePayload>(actionTypeOf(namespace, "initialize"));

export const next = createAction<WithNavigatePayload<LoanType>>(actionTypeOf(namespace, "next"));
