import {useState, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {Navigate, useNavigate} from "react-router-dom";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {useDispatch, useSelector} from "react-redux";
import {getProfileFromRecovery} from "store/selectors/auth";
import {SetPasswordPagePayload, initialize, submit} from "./SetPasswordPage.action";
import {
  getAllOperationFailures,
  getIsAnyOperationPending,
  getIsOperationFailure,
  getIsOperationNotStarted,
  getOperationFailure,
  getOperationResult,
} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {HttpStatusCode} from "axios";
import {TextField} from "@mui/material";
import {useForm} from "react-hook-form";
import {getSchema} from "./SetPasswordPage.schema";
import {yupResolver} from "@hookform/resolvers/yup";
import {
  DoubleColumnPageTemplate,
  Form,
  lock,
  Message,
  SubmitButton,
  TextFieldControlled,
} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import {getSearchParamsOf} from "components/utils/urlUtil";
import {useTranslation} from "react-i18next";

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const SetPasswordPage = ({containerRef}: Props) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "setPasswordPage"});
  const dispatch = useDispatch();

  const [inputError, setInputError] = useState<string>("");
  const isActivationNotStarted = useSelector(getIsOperationNotStarted)(
    OperationType.verifyRecoveryToken
  );
  const profileFromActivation = useSelector(getProfileFromRecovery);
  const failures = useSelector(getAllOperationFailures)(
    OperationType.verifyRecoveryToken,
    OperationType.setPassword
  );
  const isAnyPending = useSelector(getIsAnyOperationPending)(
    OperationType.verifyRecoveryToken,
    OperationType.setPassword
  );
  const {isDone: fetchFinancialInstDone} = useSelector(getOperationResult)(
    OperationType.fetchAppAssociatedFinancialInstitutionByAppOrAuthId
  );

  const isKeyboardOpen = useDetectKeyboardOpen();

  const isActivationFailure = useSelector(getIsOperationFailure)(OperationType.verifyRecoveryToken);
  const isSetPasswordOperationFailure = useSelector(getOperationFailure)(OperationType.setPassword);

  const navigate = useNavigate();

  const formHook = useForm<SetPasswordPagePayload>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(getSchema()),
  });

  const onSubmit = (values: SetPasswordPagePayload) => {
    dispatch(submit({...values, navigate}));
  };

  useEffect(() => {
    if (isSetPasswordOperationFailure) {
      if (isSetPasswordOperationFailure.status === HttpStatusCode.Conflict) {
        setInputError(fixedT("passwordReused")!);
      } else {
        setInputError(fixedT("passwordInvalid")!);
      }
    } else {
      setInputError(failures?.[0] ? fixedT(`messages.backendErrors.${failures[0].type}`)! : "");
    }
  }, [isSetPasswordOperationFailure, failures]);

  useEffect(() => {
    if (isActivationNotStarted) {
      const {token, appId} = getSearchParamsOf("token", "appId");
      dispatch(initialize({token, appId}));
    }
  }, [isActivationNotStarted]);

  if (isActivationFailure) {
    return <Navigate to={AppRouteUrl.RESEND_EMAIL_URL} />;
  }

  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;

  return (
    <DoubleColumnPageTemplate
      loading={!fetchFinancialInstDone}
      isMobileKeyboardOpen={isKeyboardOpen}
      containerRef={containerRef}
      iconSrc={lock}
      header={fixedT("header")!}
      description={fixedT("description")!}
      tipHeader={fixedT("tipHeader")!}
      tipBody={<span dangerouslySetInnerHTML={{__html: fixedT("tipBody")!}}></span>}
      navigationBar={<NavigationBar />}
      nextButton={
        <SubmitButton
          isEnabled={
            !isAnyPending &&
            !!formHook.getValues("password") &&
            formHook.getValues("password") === formHook.getValues("confirmPassword")
          }
          onClick={formHook.handleSubmit(onSubmit)}
        >
          {!isAnyPending ? (
            fixedT("forwardButton")
          ) : (
            <FontAwesomeIcon icon={faSyncAlt} spin={true} />
          )}
        </SubmitButton>
      }
    >
      <Form onEnter={formHook.handleSubmit(onSubmit)}>
        <TextField
          fullWidth
          name="firstName"
          value={profileFromActivation?.firstName}
          type="text"
          disabled
        />
        <TextField
          fullWidth
          name="lastName"
          type="text"
          value={profileFromActivation?.lastName}
          disabled
        />
        <TextField
          fullWidth
          name="email"
          type="text"
          value={profileFromActivation?.login}
          disabled
        />
        <TextFieldControlled
          fullWidth
          formHook={formHook}
          label={fixedT("password")}
          placeholder={fixedT("password")!}
          name="password"
          type="password"
          disabled={isAnyPending}
          autoComplete="new-password"
        />
        <TextFieldControlled
          formHook={formHook}
          label={fixedT("confirmPassword")}
          placeholder={fixedT("confirmPassword")!}
          fullWidth
          name="confirmPassword"
          type="password"
          disabled={isAnyPending}
          triggerOnChange
        />
        {inputError && <Message message={inputError} />}
      </Form>
    </DoubleColumnPageTemplate>
  );
};

export default SetPasswordPage;
