import {camelCase as _camelCase} from "lodash";

export function camelCaseString(str?: string): string {
  if (!str) {
    return "";
  }
  return _camelCase(str);
}

function isObjectNonNull(value: any): boolean {
  return value != null && typeof value === "object";
}

export function camelCaseObject(obj: any | any[] | object): any {
  if (typeof obj !== "object") {
    // console.warn("Not going to apply camelCaseObject() on non-object type value: " + obj);
    return obj;
  }

  if (!isObjectNonNull(obj)) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(camelCaseObject);
  }

  let newObj: any = {};
  for (let key in obj) {
    let camelKey = camelCaseString(key);
    let value = obj[key];
    newObj[camelKey] = isObjectNonNull(value) ? camelCaseObject(value) : value;
  }
  return newObj;
}

export function JSONParseOrUndefined(str: string | null): any | undefined {
  try {
    if (!str) {
      return undefined;
    }
    return JSON.parse(str);
  } catch (e) {
    console.error("Fallback to undefined, due to error in JSON.parse for str=" + str);
    return undefined;
  }
}

export function JSONParseOrSelf(str: string | null): any {
  try {
    if (!str) {
      return undefined;
    }
    return JSON.parse(str);
  } catch (e) {
    console.warn("Fallback to value itself, due to error in JSON.parse for str=" + str);
    return str;
  }
}

export function notEmptyEffectively(value: Object | any): boolean {
  return !isEmptyEffectively(value);
}

export function isEmptyEffectively(value: Object | any): boolean {
  if (value == null || undefined) {
    return true;
  }
  if (typeof value === "string") {
    return value.trim().length === 0;
  }

  for (let key in value) {
    if (
      value[key] != null &&
      ((typeof value[key] === "object" && !isEmptyEffectively(value[key])) ||
        (typeof value[key] !== "object" && String(value[key]).trim().length > 0))
    ) {
      return false;
    }
  }
  return true;
}

type KT = string | number | symbol;

export function allMatch<K extends KT, V>(map: Record<K, V>, value: V): boolean {
  return Array.from(Object.values(map))?.every((entry) => entry === value);
}

export function anyMatch<K extends KT, V>(map: Record<K, V>, value: V): boolean {
  return Array.from(Object.values(map))?.some((entry) => entry === value);
}

export function anyMatchCollection<K extends KT, V>(map: Record<K, V>, values: V[]): boolean {
  return Array.from(Object.values(map))?.some((entry) => values.includes(entry as V));
}

function isCleanValue(value: any): boolean {
  return (
    value !== null && value !== undefined && !(typeof value === "number" && Number.isNaN(value))
  );
}

export function cleanObject<T = any>(obj: T): T {
  if (typeof obj !== "object" || obj == null) return obj;

  if (Array.isArray(obj)) {
    return obj.map(cleanObject).filter(isCleanValue) as T;
  }

  // json object
  return Object.entries(obj)
    .filter(([_, value]) => isCleanValue(value)) // Keep only clean values
    .reduce((acc, [key, value]) => {
      acc[key] = cleanObject(value); // Recursively clean nested objects
      return acc;
    }, {} as any);
}
