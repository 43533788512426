import {all} from "redux-saga/effects";
import navigationBarActionSectionSaga from "components/NavigationBar/NavigationBarActionSection/NavigationBarActionSection.saga";
import rootComponentSaga from "pages/Root/Root.saga";
import authSaga from "./auth.saga";
import navigationHistorySaga from "components/NavigationBar/NavigationBar.saga";
import employmentInfoSaga from "pages/EmploymentInfoPage/EmploymentInfoPage.saga";
import equifaxPageSaga from "pages/EquifaxPage/EquifaxPage.saga";
import documentUploadSaga from "pages/DocumentUploadPage/DocumentUploadPage.saga";
import bankOrBrokerPageSaga from "pages/BankOrBrokerPage/BankOrBrokerPage.saga";
import signUpPageSaga from "pages/SignUpPage/SignUpPage.saga";
import setPasswordSaga from "pages/SetPasswordPage/SetPasswordPage.saga";
import secondarySignUpPageSaga from "pages/SecondarySignUpPage/SecondarySignUpPage.saga";
import inviteSecondaryApplicantSaga from "pages/InviteSecondaryApplicantPage/InviteSecondaryApplicantPage.saga";
import signInFormSaga from "pages/SignInPage/SignInPage.saga";
import addressInfoSaga from "pages/AddressInfoPage/AddressInfoPage.saga";
import propertyAddressInfoPageSaga from "pages/PropertyAddressInfoPage/PropertyAddressInfoPage.saga";
import mortgageInfoPageSaga from "pages/MortgageInfoPage/MortgageInfoPage.saga";
import productSelectPageSaga from "pages/ProductSelectPage/ProductSelectPage.saga";
import selfReportedAssetsSaga from "pages/SelfReportedAssetsPage/SelfReportedAssetsPage.saga";
import selfReportedLiabilitiesSaga from "pages/SelfReportedLiabilitiesPage/SelfReportedLiabilitiesPage.saga";
import unresolvedAVMPageSaga from "pages/UnresolvedAVMPage/UnresolvedAVMPage.saga";
import disclosurePageSaga from "pages/DisclosurePage/DisclosurePage.saga";
import banksAndFlinksPageSaga from "pages/BanksAndFlinksPage/BanksAndFlinksPage.saga";
import mortgageSelectionSaga from "pages/MortgageTypePage/MortgageTypePage.saga";
import creditScorePageSaga from "pages/SelfReportedCreditScorePage/SelfReportedCreditScorePage.saga";
import provinceSelectPageSaga from "pages/ProvinceSelectPage/ProvinceSelectPage.saga";
import purchaseTimeframePageSaga from "pages/PurchaseTimeframePage/PurchaseTimeframePage.saga";
import purposeOfPurchasePageSaga from "pages/PurposeOfPurchasePage/PurposeOfPurchasePage.saga";

function* rootSaga() {
  yield all([
    authSaga(),
    setPasswordSaga(),
    rootComponentSaga(),
    provinceSelectPageSaga(),
    addressInfoSaga(),
    propertyAddressInfoPageSaga(),
    secondarySignUpPageSaga(),
    disclosurePageSaga(),
    inviteSecondaryApplicantSaga(),
    navigationBarActionSectionSaga(),
    setPasswordSaga(),
    signInFormSaga(),
    navigationHistorySaga(),
    signUpPageSaga(),
    employmentInfoSaga(),
    selfReportedAssetsSaga(),
    selfReportedLiabilitiesSaga(),
    equifaxPageSaga(),
    documentUploadSaga(),
    bankOrBrokerPageSaga(),
    unresolvedAVMPageSaga(),
    productSelectPageSaga(),
    mortgageInfoPageSaga(),
    banksAndFlinksPageSaga(),
    mortgageSelectionSaga(),
    creditScorePageSaga(),
    purchaseTimeframePageSaga(),
    purposeOfPurchasePageSaga(),
  ]);
}

export default rootSaga;
