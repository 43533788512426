import {equalIgnoreCase} from "components/utils/stringUtil";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {submitMortgageInfoOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getLoanTypeConsolidated} from "store/selectors/basicInfo";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {
  ConfigurationKey,
  LoanType,
  MortgageInfoPageFormData,
  WithNavigatePayload,
} from "@pinch-financial/pinch-ui-components";
import {Step} from "@pinch-financial/pinch-ui-components";
import {submitMortgageInfo, navigatePreviousStep, initialize} from "./MortgageInfoPage.action";
import {WithNavigate} from "types/ui";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {ResidentialApplicationBasicInfoRequest} from "@pinch-financial/pinch-ui-components";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {saveBasicInfo} from "store/actions/applicantMeta.action";
import {getIsDownPaymentPercentageEnabled} from "~/store/selectors/providerconfigurations";

export function* onInitialize({payload: {navigate}}: WithPayload<WithNavigate>) {
  const loanType: LoanType = yield select(getLoanTypeConsolidated);
  if (!loanType) {
    console.info("Redirect to select loan type due to loan type missing");
    navigate(AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE);
  }
}

export function* onSubmit({
  payload,
}: WithPayload<WithNavigatePayload<MortgageInfoPageFormData>>): any {
  const userId: string = yield select(getUserId);
  const loanType: string = yield select(getLoanTypeConsolidated);

  let {
    navigate,
    onNextPage,
    shouldStay,
    data: {downPaymentPercentMultipled100, ...mortgageDetails},
  } = payload;

  if (equalIgnoreCase(LoanType.PREAPPROVAL, loanType)) {
    const isDownPaymentPercentageEnabled = yield select(getIsDownPaymentPercentageEnabled);
    mortgageDetails = {
      ...mortgageDetails,
      downPaymentPercent: isDownPaymentPercentageEnabled
        ? downPaymentPercentMultipled100! / 100
        : undefined,
    };
  }

  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    mortgageDetails,
  };

  yield put(saveBasicInfo(basicInfoRequest));

  if (!userId) {
    onNextPage?.();
    return;
  }

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitMortgageInfoOperation.saga,
    userId,
    basicInfoRequest,
    Step.MORTGAGE_INFO
  );
  if (Operation.isSuccess(result) && !shouldStay) {
    onNextPage?.();
  }
}

export function* onNavigatePreviousStep({payload}: WithPayload<WithNavigate>) {
  const {navigate} = payload;
  const loanType: string = yield select(getLoanTypeConsolidated);
  if (
    equalIgnoreCase(LoanType.RENEWAL, loanType) ||
    equalIgnoreCase(LoanType.NEW_PURCHASE, loanType)
  ) {
    navigate(AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO);
  }
}

export default function* mortgageInfoPageSaga() {
  yield takeEvery(initialize, onInitialize);
  yield takeEvery(submitMortgageInfo, onSubmit);
  yield takeEvery(navigatePreviousStep, onNavigatePreviousStep);
}
