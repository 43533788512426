import {createSelector} from "@reduxjs/toolkit";
import {State} from "types/store";
import {
  NavigationState,
  NavigationHistory,
  StepStatusRecord,
  StepStatusState,
} from "types/NavigationController";
import {
  getAcceptedPrivacyPolicy,
  getBasicInfo,
  getConsolidatedBasicInfo,
  getHasEquifaxCreditOrSelfReported,
  getHasPulledEquifax,
  getHasSecondary,
  getHasSelfReportedCreditScore,
  getIsPrimary,
  getIsSecondary,
} from "./basicInfo";
import {
  getOnlyDocumentUploadRequirements,
  getIsAllDocumentUploadHidden,
  getIsProviderConfigurationValueEnabled,
} from "./providerconfigurations";
import {Configuration} from "types/configurations";
import {getHasUserSelectedProvider} from "./whitelabel";
import {isConfigurationValueTrue} from "util/configUtil";
import {getHasUser} from "./user";
import {AppRouteUrl, ConfigurationKey} from "@pinch-financial/pinch-ui-components";
import {
  NavigationStep,
  NavStep,
  NavStepKey,
  StepsWithoutSelectProductOrPersonalProfile,
} from "@pinch-financial/pinch-ui-components";

import {
  getHasClickedGetStarted,
  getHasUserAcceptedAssetsDataWarning,
  getHasUserAcceptedLiabilitiesDataWarning,
  getLoanType,
} from "./applicantMeta";
import {PageSequence} from "types/pagesequence";
import {nextItem} from "util/util";
import {Step} from "@pinch-financial/pinch-ui-components";

export const getNavigationController = (rootState: State): NavigationState =>
  rootState.navigationController;

export const getNavigationHistory = (rootState: State): NavigationHistory =>
  rootState.navigationController?.history;

export const getNavigationStepStatus = (rootState: State): StepStatusRecord =>
  rootState.navigationController?.stepStatus;

export const getActiveRouteSequence = (rootState: State): AppRouteUrl[] =>
  rootState.navigationController?.activePageSequence?.map(
    (page) => AppRouteUrl[page.route as unknown as keyof typeof AppRouteUrl]
  ) ?? [];

export const getActivePageSequence = (rootState: State): PageSequence[] =>
  rootState.navigationController?.activePageSequence ?? [];

export const getStepURLs = (step: NavigationStep) =>
  createSelector(getActivePageSequence, (pageSequence) =>
    pageSequence
      .filter((page) => page.category === step)
      .map((page) => AppRouteUrl[page.route as unknown as keyof typeof AppRouteUrl])
  );

export const getNavbarClickedStep = (rootState: State): NavStep | undefined =>
  rootState.navigationController?.navbarClickedStep;

export const getHasVisitedUrl = createSelector(
  getNavigationHistory,
  (navigationHistory: any) =>
    (url: string): boolean =>
      (navigationHistory ?? []).includes(url)
);

export const getCurrentStep = createSelector(
  getNavigationController,
  (navigationController: NavigationState) => navigationController.currentStep
);

export const getNextUrl = createSelector(
  getNavigationController,
  (navigationController: NavigationState) => navigationController.nextUrl
);

export const getHasVisitedAnyUrls = createSelector(
  getNavigationHistory,
  (navigationHistory: any) => (urls: string[]) =>
    !!urls.find((url) => navigationHistory.includes(url))
);

export const getHasCompletedStep = createSelector(
  getNavigationStepStatus,
  (stepStatus: StepStatusRecord) =>
    (step: NavStep): boolean =>
      stepStatus[step].completed
);

export const getHasCompletedSteps = (step: NavigationStep) =>
  createSelector(
    [
      // Using anonymous function here to avoid import order issue
      // Uncaught Error: Selector creators expect all input-selectors to be functions, instead received the following types: [undefined, ...]
      (state) => getHasCompletedUploadDocuments(state),
      getConsolidatedBasicInfo,
      getLoanType,
      getHasUserSelectedProvider,
      getHasVisitedUrl,
      getHasUser,
      getHasPulledEquifax,
      getHasSelfReportedCreditScore,
      getHasUserAcceptedAssetsDataWarning,
      getHasUserAcceptedLiabilitiesDataWarning,
      getActivePageSequence,
      getHasClickedGetStarted,
    ],
    (
      hasCompletedUploadDocuments,
      basicInfo,
      applicantMetaLoanType,
      hasUserSelectedProvider,
      hasVisitedUrl,
      hasUser,
      pulledEquifax,
      hasSelfReportedCreditScore,
      hasUserAcceptedAssetsDataWarning,
      hasUserAcceptedLiabilitiesDataWarning,
      pageSequence,
      hasClickedGetStarted
    ) => {
      if (!pageSequence || pageSequence.length === 0) {
        console.warn("No page sequence found");
        return false;
      }
      return pageSequence
        .filter((page) => page.category === step)
        .every((page) => {
          switch (AppRouteUrl[page.route as unknown as keyof typeof AppRouteUrl]) {
            case AppRouteUrl.LANDING_PAGE_URL:
              return hasClickedGetStarted || hasUser; // hasUser is a fallback for returning users
            case AppRouteUrl.SELF_REPORTED_CREDIT_DIRECT:
              return hasSelfReportedCreditScore;
            case AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_ASSETS:
              return (
                hasUserAcceptedAssetsDataWarning || Boolean(basicInfo.selfReportedAssets?.length)
              );
            case AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_LIABILITIES:
              return (
                hasUserAcceptedLiabilitiesDataWarning ||
                Boolean(basicInfo.selfReportedLiabilities?.length)
              );
            case AppRouteUrl.INVITE_SECONDARY_APPLICANT:
              return hasUser;
            case AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE:
              return !!basicInfo?.mortgageDetails?.loanType || !!applicantMetaLoanType;
            case AppRouteUrl.PURCHASE_TIMEFRAME_URL:
              return !!basicInfo?.purchaseTimeframe;
            case AppRouteUrl.BASIC_INFO_URL_SIN_FORM:
              return pulledEquifax || hasSelfReportedCreditScore;
            case AppRouteUrl.BANK_OR_BROKER:
              return (
                hasUserSelectedProvider && (hasVisitedUrl(AppRouteUrl.BANK_OR_BROKER) || hasUser)
              );
            case AppRouteUrl.SIGN_UP:
            case AppRouteUrl.DISCLOSURE_URL:
              return hasUser;
            case AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO:
              return !!basicInfo?.mortgageDetails?.downPayment;
            case AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION:
              return (
                !!basicInfo?.mortgageDetails?.closingDate ||
                !!basicInfo?.mortgageDetails?.renewalDate ||
                !!basicInfo?.mortgageDetails?.downPayment
              );
            case AppRouteUrl.BASIC_INFO_URL_EMPLOYMENT:
              return !!basicInfo?.employmentHistory;
            case AppRouteUrl.BASIC_INFO_URL_ADDRESS:
              return !!basicInfo?.residentialAddress;
            case AppRouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS:
              return hasCompletedUploadDocuments;
            case AppRouteUrl.BASIC_INFO_URL_ADD_BANK_INFO:
              return (
                Boolean(basicInfo?.firstBankFinancialDataPublicId) ||
                Boolean(basicInfo?.selfReportedAssets?.length)
              );
            case AppRouteUrl.SELECT_LENDERS_URL:
              return !!basicInfo?.recordIsLocked;
            default:
              return false;
          }
        });
    }
  );

export const getNavigationError = createSelector(
  getNavigationController,
  (navigationState: NavigationState): string | undefined => navigationState.error
);

export const getAllRequiredIncompleteSteps = createSelector(
  getNavigationStepStatus,
  (stepStatus: {[x in string]: StepStatusState}): StepsWithoutSelectProductOrPersonalProfile[] =>
    Object.keys(NavStep)
      .filter((item) => NavStep.SELECT_PRODUCT !== item)
      .filter((item) => NavStep.PERSONAL_PROFILE !== item)
      .filter((item) => !stepStatus[item].hidden)
      .filter((item) => !stepStatus[item].optional)
      .filter((item) => !stepStatus[item].completed) as StepsWithoutSelectProductOrPersonalProfile[]
);

export const getAllOptionalIncompleteSteps = createSelector(
  getNavigationStepStatus,
  (stepStatus: {[x in string]: StepStatusState}): StepsWithoutSelectProductOrPersonalProfile[] =>
    Object.keys(NavStep)
      .filter((item) => NavStep.SELECT_PRODUCT !== item)
      .filter((item) => !stepStatus[item].hidden)
      .filter((item) => stepStatus[item].optional)
      .filter((item) => !stepStatus[item].completed) as StepsWithoutSelectProductOrPersonalProfile[]
);

export const getHasAllRequiredStepsCompleted = createSelector(
  getAllRequiredIncompleteSteps,
  (allRequiredIncompleteSteps: NavStepKey[]): boolean => !allRequiredIncompleteSteps.length
);

export const getHasCompletedWelcome = createSelector(
  getHasCompletedSteps(NavigationStep.WELCOME),
  (hasCompletedWelcomeSteps): boolean => hasCompletedWelcomeSteps
);

export const getHasCompletedLenders = createSelector(
  getHasCompletedSteps(NavigationStep.LENDERS),
  (hasCompletedLenderSteps): boolean => hasCompletedLenderSteps
);

export const getHasCompletedSignUp = createSelector(
  getHasUser,
  (hasUser: boolean): boolean => hasUser
);

export const getHasCompletedMortgageInformation = createSelector(
  getHasCompletedSteps(NavigationStep.MORTGAGE_TYPE),
  (hasCompletedMortgageTypeSteps): boolean => hasCompletedMortgageTypeSteps
);

export const getHasCompletedUploadDocuments = createSelector(
  getBasicInfo,
  getOnlyDocumentUploadRequirements,
  (basicInfo, docUploadReq): boolean => {
    if (basicInfo && docUploadReq) {
      const configToInputMap: Map<ConfigurationKey, boolean> = new Map();
      configToInputMap.set(
        ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_T4,
        basicInfo.uploadedT4
      );
      configToInputMap.set(
        ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_NOA,
        basicInfo.uploadedNOA
      );
      configToInputMap.set(
        ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_PAYSTUB,
        basicInfo.uploadedPayStub
      );
      configToInputMap.set(
        ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_BANK_STATEMENT,
        basicInfo.uploadedBankStatement
      );
      configToInputMap.set(
        ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_MLS,
        basicInfo.uploadedMLS
      );
      configToInputMap.set(
        ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_CLOSING_DOCUMENT,
        basicInfo.uploadedClosingDocument
      );
      const isAllRequiredDocsUploaded = !docUploadReq?.find((configuration: Configuration) => {
        return isConfigurationValueTrue(configuration) && !configToInputMap.get(configuration.key);
      });
      return isAllRequiredDocsUploaded;
    }
    return false;
  }
);

export const getHasCompletedPersonalProfile = createSelector(
  getHasCompletedSteps(NavigationStep.PERSONAL_PROFILE),
  (hasCompletedPersonalProfileSteps): boolean => hasCompletedPersonalProfileSteps
);

export const getHasCompletedIncomeVerification = createSelector(
  getHasCompletedSteps(NavigationStep.BANKING),
  (hasCompletedBankingSteps): boolean => hasCompletedBankingSteps
);

export const getHasCompletedEquifax = createSelector(
  getHasCompletedSteps(NavigationStep.CREDIT),
  (hasCompletedCreditSteps): boolean => hasCompletedCreditSteps
);

export const getHasSelectedProduct = createSelector(
  getBasicInfo,
  (basicInfo): boolean => !!basicInfo?.recordIsLocked
);

export const getCompleteStatus = createSelector(
  getHasCompletedWelcome,
  getHasCompletedLenders,
  getHasCompletedSignUp,
  getHasCompletedPersonalProfile,
  getHasCompletedIncomeVerification,
  getHasCompletedEquifax,
  getHasCompletedMortgageInformation,
  getHasSelectedProduct,
  (
    hasCompletedWelcome,
    hasCompletedLenders,
    hasCompletedSignUp,
    hasCompletedPersonalProfile,
    hasCompletedIncomeVerification,
    hasCompletedCreditVerification,
    hasCompletedMortgageInformation,
    hasSelectedLender
  ): Record<NavStep, boolean> => {
    return {
      [NavStep.WELCOME]: hasCompletedWelcome,
      [NavStep.LENDERS]: hasCompletedLenders,
      [NavStep.SIGN_UP]: hasCompletedSignUp,
      [NavStep.MORTGAGE_TYPE]: hasCompletedMortgageInformation,
      [NavStep.PERSONAL_PROFILE]: hasCompletedPersonalProfile,
      [NavStep.BANKING]: hasCompletedIncomeVerification,
      [NavStep.CREDIT]: hasCompletedCreditVerification,
      [NavStep.SELECT_PRODUCT]: hasSelectedLender,
    };
  }
);

export const getDataFilledOutStatus = createSelector(
  getHasCompletedWelcome,
  getHasCompletedLenders,
  getHasCompletedSignUp,
  getHasCompletedPersonalProfile,
  getHasCompletedIncomeVerification,
  getHasEquifaxCreditOrSelfReported,
  getHasCompletedMortgageInformation,
  getHasSelectedProduct,
  (
    hasCompletedWelcome,
    hasCompletedLenders,
    hasCompletedSignUp,
    hasCompletedPersonalProfile,
    hasCompletedIncomeVerification,
    hasCompletedCreditVerification,
    hasCompletedMortgageInformation,
    hasSelectedLender
  ): Record<NavStep, boolean> => {
    return {
      [NavStep.WELCOME]: hasCompletedWelcome,
      [NavStep.LENDERS]: hasCompletedLenders,
      [NavStep.SIGN_UP]: hasCompletedSignUp,
      [NavStep.MORTGAGE_TYPE]: hasCompletedMortgageInformation,
      [NavStep.PERSONAL_PROFILE]: hasCompletedPersonalProfile,
      [NavStep.BANKING]: hasCompletedIncomeVerification,
      [NavStep.CREDIT]: hasCompletedCreditVerification,
      [NavStep.SELECT_PRODUCT]: hasSelectedLender,
    };
  }
);

export const getOptionalStatus = createSelector(
  getActiveRouteSequence,
  (activeRouteSequence): Record<NavStep, boolean> => {
    return {
      [NavStep.WELCOME]: true, // Welcome is optional because it does not provide any data and prevents showing warning icon on navbar
      [NavStep.LENDERS]: false,
      [NavStep.SIGN_UP]: false,
      [NavStep.MORTGAGE_TYPE]: false,
      [NavStep.PERSONAL_PROFILE]: false,
      [NavStep.BANKING]: true,
      // Credit is optional when the Equifax page is disabled, because self reported pages can be skipped
      [NavStep.CREDIT]: !activeRouteSequence.includes(AppRouteUrl.BASIC_INFO_URL_SIN_FORM),
      [NavStep.SELECT_PRODUCT]: false,
    };
  }
);

export const getHiddenStatus = createSelector(
  getIsAllDocumentUploadHidden,
  (isAllDocumentUploadHidden): Record<NavStep, boolean> => {
    return {
      [NavStep.WELCOME]: false,
      [NavStep.LENDERS]: false,
      [NavStep.SIGN_UP]: false,
      [NavStep.MORTGAGE_TYPE]: false,
      [NavStep.PERSONAL_PROFILE]: false,
      [NavStep.BANKING]: false,
      [NavStep.CREDIT]: false,
      [NavStep.SELECT_PRODUCT]: false,
    };
  }
);

export const getStepStatusWelcome = createSelector(
  getCompleteStatus,
  getOptionalStatus,
  getHasVisitedAnyUrls,
  getStepURLs(NavigationStep.WELCOME),
  getNavigationStepStatus,
  (
    completeStatus,
    optionalStatus,
    hasVisitedUrls,
    stepUrls,
    navigationStepStatus
  ): StepStatusState => {
    return {
      hidden: stepUrls.length === 0,
      optional: optionalStatus[NavStep.WELCOME],
      completed: completeStatus[NavStep.WELCOME],
      visited: hasVisitedUrls(stepUrls),
      hasVisitedViaNavbar: navigationStepStatus[NavStep.WELCOME].hasVisitedViaNavbar,
    };
  }
);

export const getStepStatusLenders = createSelector(
  getCompleteStatus,
  getOptionalStatus,
  getHasVisitedAnyUrls,
  getStepURLs(NavigationStep.LENDERS),
  getNavigationStepStatus,
  (
    completeStatus,
    optionalStatus,
    hasVisitedUrls,
    stepUrls,
    navigationStepStatus
  ): StepStatusState => {
    return {
      hidden: stepUrls.length === 0,
      optional: optionalStatus[NavStep.LENDERS],
      completed: completeStatus[NavStep.LENDERS],
      visited: hasVisitedUrls(stepUrls),
      hasVisitedViaNavbar: navigationStepStatus[NavStep.LENDERS].hasVisitedViaNavbar,
    };
  }
);

export const getStepStatusSignUp = createSelector(
  getCompleteStatus,
  getOptionalStatus,
  getHasVisitedAnyUrls,
  getStepURLs(NavigationStep.SIGN_UP),
  getNavigationStepStatus,
  (
    completeStatus,
    optionalStatus,
    hasVisitedUrls,
    stepUrls,
    navigationStepStatus
  ): StepStatusState => {
    return {
      hidden: stepUrls.length === 0,
      optional: optionalStatus[NavStep.SIGN_UP],
      completed: completeStatus[NavStep.SIGN_UP],
      visited: hasVisitedUrls(stepUrls),
      hasVisitedViaNavbar: navigationStepStatus[NavStep.SIGN_UP].hasVisitedViaNavbar,
    };
  }
);

export const getStepStatusMortgageType = createSelector(
  getCompleteStatus,
  getOptionalStatus,
  getHasVisitedAnyUrls,
  getIsPrimary,
  getHasUser,
  getStepURLs(NavigationStep.MORTGAGE_TYPE),
  getNavigationStepStatus,
  (
    completeStatus,
    optionalStatus,
    hasVisitedUrls,
    isPrimary,
    hasUser,
    stepUrls,
    navigationStepStatus
  ): StepStatusState => {
    return {
      hidden: stepUrls.length === 0 || (hasUser && !isPrimary),
      optional: optionalStatus[NavStep.MORTGAGE_TYPE],
      completed: completeStatus[NavStep.MORTGAGE_TYPE],
      visited: hasVisitedUrls(stepUrls),
      hasVisitedViaNavbar: navigationStepStatus[NavStep.MORTGAGE_TYPE].hasVisitedViaNavbar,
    };
  }
);

export const getStepStatusPersonalProfile = createSelector(
  getCompleteStatus,
  getOptionalStatus,
  getHasVisitedAnyUrls,
  getStepURLs(NavigationStep.PERSONAL_PROFILE),
  getNavigationStepStatus,
  (
    completeStatus,
    optionalStatus,
    hasVisitedUrls,
    stepUrls,
    navigationStepStatus
  ): StepStatusState => {
    return {
      hidden: stepUrls.length === 0,
      optional: optionalStatus[NavStep.PERSONAL_PROFILE],
      completed: completeStatus[NavStep.PERSONAL_PROFILE],
      visited: hasVisitedUrls(stepUrls),
      hasVisitedViaNavbar: navigationStepStatus[NavStep.PERSONAL_PROFILE].hasVisitedViaNavbar,
    };
  }
);

export const getStepStatusBanking = createSelector(
  getCompleteStatus,
  getOptionalStatus,
  getHasVisitedAnyUrls,
  getStepURLs(NavigationStep.BANKING),
  getNavigationStepStatus,
  (
    completeStatus,
    optionalStatus,
    hasVisitedUrls,
    stepUrls,
    navigationStepStatus
  ): StepStatusState => {
    return {
      hidden: stepUrls.length === 0,
      optional: optionalStatus[NavStep.BANKING],
      completed: completeStatus[NavStep.BANKING],
      visited: hasVisitedUrls(stepUrls),
      hasVisitedViaNavbar: navigationStepStatus[NavStep.BANKING].hasVisitedViaNavbar,
    };
  }
);
export const getStepStatusCredit = createSelector(
  getCompleteStatus,
  getOptionalStatus,
  getHasVisitedAnyUrls,
  getStepURLs(NavigationStep.CREDIT),
  getNavigationStepStatus,
  (
    completeStatus,
    optionalStatus,
    hasVisitedUrls,
    stepUrls,
    navigationStepStatus
  ): StepStatusState => {
    let visited = [AppRouteUrl.SELF_REPORTED_CREDIT_DIRECT, AppRouteUrl.BASIC_INFO_URL_SIN_FORM];
    if (
      getIsProviderConfigurationValueEnabled(
        ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_ASSETS_LIABILITIES
      )
    ) {
      visited = [AppRouteUrl.BASIC_INFO_URL_SIN_FORM];
    }
    return {
      hidden: stepUrls.length === 0,
      optional: optionalStatus[NavStep.CREDIT],
      completed: completeStatus[NavStep.CREDIT],
      visited: hasVisitedUrls(stepUrls),
      hasVisitedViaNavbar: navigationStepStatus[NavStep.CREDIT].hasVisitedViaNavbar,
    };
  }
);

export const getStepStatusSelectProduct = createSelector(
  getCompleteStatus,
  getOptionalStatus,
  getHasVisitedUrl,
  getIsPrimary,
  getNavigationStepStatus,
  (
    completeStatus,
    optionalStatus,
    hasVisitedUrl,
    isPrimary,
    navigationStepStatus
  ): StepStatusState => {
    return {
      hidden: !isPrimary,
      optional: optionalStatus[NavStep.SELECT_PRODUCT],
      completed: completeStatus[NavStep.SELECT_PRODUCT],
      visited: hasVisitedUrl(AppRouteUrl.SELECT_LENDERS_URL),
      hasVisitedViaNavbar: navigationStepStatus[NavStep.SELECT_PRODUCT].hasVisitedViaNavbar,
    };
  }
);

export const getHasNavbarAssetLiabilitiesStepClicked = createSelector(
  getNavigationStepStatus,
  (navigationStepStatus) => navigationStepStatus?.[NavStep.BANKING]?.hasVisitedViaNavbar
);

export const getPreviousUrlFromHistory = createSelector(
  getNavigationHistory,
  (history): AppRouteUrl => {
    return history[history.length - 2] as AppRouteUrl;
  }
);

export const getLatestUrlFromHistory = createSelector(
  getNavigationHistory,
  (history): AppRouteUrl => {
    return history[history.length - 1] as AppRouteUrl;
  }
);

export const getSlideDirection = createSelector(
  getNavigationController,
  (navigationController): -1 | 1 => navigationController.slideDirection ?? 1
);

export const getIsSkipAnimation = createSelector(
  getNavigationController,
  (navigationController): boolean => navigationController.skipAnimation ?? false
);

export const getActivePageSequenceVisible = createSelector(
  getActivePageSequence, // this includes both primary/secondary pages
  getIsSecondary,
  (pageSequence, isSecondary): PageSequence[] => {
    return (
      pageSequence?.filter((page) => (isSecondary ? page.visibleSecondary : page.visiblePrimary)) ??
      []
    );
  }
);

export const getActiveRouteSequenceVisible = createSelector(
  getActivePageSequenceVisible,
  (pageSequence): AppRouteUrl[] => {
    return (
      pageSequence?.map((page) => AppRouteUrl[page.route as unknown as keyof typeof AppRouteUrl]) ??
      []
    );
  }
);

export const getFirstRouteUrlAfterSignUp = createSelector(
  getActiveRouteSequenceVisible,
  getAcceptedPrivacyPolicy,
  getHasSecondary,
  (routeSequence, acceptedPrivacy, hasSecondary): AppRouteUrl => {
    let nextRoute = acceptedPrivacy
      ? nextItem(routeSequence, AppRouteUrl.DISCLOSURE_URL)
      : AppRouteUrl.DISCLOSURE_URL;

    if (hasSecondary && nextRoute === AppRouteUrl.INVITE_SECONDARY_APPLICANT) {
      nextRoute = nextItem(routeSequence, nextRoute);
    }

    return nextRoute ?? AppRouteUrl.DISCLOSURE_URL;
  }
);

export const getIncompletePersonalProfileRequiredSteps = createSelector(
  getBasicInfo,
  getHasCompletedUploadDocuments,
  getActiveRouteSequence,
  (basicInfo, hasCompletedUploadDocuments, activeRouteSequence): Step[] => {
    const incompleteSteps: Step[] = [];

    if (activeRouteSequence.includes(AppRouteUrl.BASIC_INFO_URL_EMPLOYMENT)) {
      if (!basicInfo?.employmentHistory) {
        incompleteSteps.push(Step.EMPLOYMENT_INFO);
      }
    }

    if (activeRouteSequence.includes(AppRouteUrl.BASIC_INFO_URL_ADDRESS)) {
      if (!basicInfo?.residentialAddress) {
        incompleteSteps.push(Step.ADDRESS_INFO);
      }
    }

    if (activeRouteSequence.includes(AppRouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS)) {
      if (!hasCompletedUploadDocuments) {
        incompleteSteps.push(Step.SELF_REPORTED_DOCUMENTS);
      }
    }

    if (activeRouteSequence.includes(AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION)) {
      if (
        !(
          basicInfo?.mortgageDetails?.closingDate ||
          basicInfo?.mortgageDetails?.renewalDate ||
          basicInfo?.mortgageDetails?.downPayment
        )
      ) {
        incompleteSteps.push(Step.MORTGAGE_INFO);
      }
    }

    if (activeRouteSequence.includes(AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO)) {
      if (!basicInfo?.mortgageDetails?.downPayment) {
        incompleteSteps.push(Step.PROPERTY_INFO);
      }
    }

    return incompleteSteps;
  }
);
