import {call, put, select, takeEvery} from "redux-saga/effects";
import {submitPurposeOfPurchaseOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {Step} from "@pinch-financial/pinch-ui-components";
import {ResidentialApplicationBasicInfoRequest} from "@pinch-financial/pinch-ui-components";
import {saveBasicInfo} from "store/actions/applicantMeta.action";
import {PurposeOfPurchasePagePayload, submit} from "./PurposeOfPurchasePage.action";

export function* onSubmit({
  payload: {purposeOfPurchase, onNextPage},
}: WithPayload<PurposeOfPurchasePagePayload>) {
  const userId: string = yield select(getUserId);

  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    purposeOfPurchase,
  };

  yield put(saveBasicInfo(basicInfoRequest));

  if (!userId) {
    onNextPage?.();
    return;
  }

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitPurposeOfPurchaseOperation.saga,
    userId,
    basicInfoRequest,
    Step.PURPOSE_OF_PURCHASE
  );
  if (Operation.isSuccess(result)) {
    onNextPage && onNextPage();
  }
}

export default function* purposeOfPurchasePageSaga() {
  yield takeEvery(submit, onSubmit);
}
