import {
  AppRouteUrlKey,
  interpolate,
  LanguageType,
  LocalizedText,
  TextType,
  toTextMap,
} from "@pinch-financial/pinch-ui-components";
import {fetchAppPageContent} from "api/appPages";
import {DLA_STORAGE_KEY} from "appConstants";
import i18next from "i18next";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getFinancialInstitution} from "store/selectors/consolidatedSelectors";
import useLocalStorage from "./useLocalStorage";

const usePageText = (pageRoute: AppRouteUrlKey) => {
  const [texts, setTexts] = useState<Record<TextType, LocalizedText | undefined>>(
    {} as Record<TextType, LocalizedText | undefined>
  );
  const [status, setStatus] = useState<"not-started" | "pending" | "error" | "success">(
    "not-started"
  );
  const [error, setError] = useState<any>(undefined);

  const appFiOrSelected = useSelector(getFinancialInstitution);
  const dlaFi = useLocalStorage(DLA_STORAGE_KEY);

  const loadText = async () => {
    setStatus("pending");
    setError(undefined);

    const {data, error} = await fetchAppPageContent(pageRoute, appFiOrSelected?.publicId ?? dlaFi);

    setTexts(toTextMap(data));
    setError(error);
    setStatus(error ? "error" : "success");

    if (error) {
      console.error(
        `Failed to fetch page content with override, for page route: ${pageRoute}`,
        error
      );
    }
  };

  useEffect(() => {
    loadText();
  }, [pageRoute, appFiOrSelected, dlaFi]);

  const getText = (
    type: TextType,
    placeholders?: Record<string, string | undefined>
  ): string | undefined => {
    const isFetchDone = status === "success" || status === "error";
    let localizedText = texts[type];
    if (!localizedText) {
      isFetchDone && console.warn(`Missing text: type=${type}`);
      return undefined;
    }

    let copy =
      i18next.language === LanguageType.FR_CA ? localizedText.inFrench : localizedText.inEnglish;
    if (!copy) {
      isFetchDone && console.warn(`Missing text: type=${type}, lang=${i18next.language}`);
    }
    return placeholders ? interpolate(copy, placeholders as Record<string, string>) : copy;
  };

  return {getText, status};
};

export default usePageText;
