import {call, put, select, takeEvery} from "redux-saga/effects";
import {
  NextWithBankPayload,
  nextWithBank,
  nextWithBroker,
  selectProvider,
} from "./BankOrBrokerPage.action";
import {WithPayload} from "types/basic";
import {setUserSelectedProviderId} from "store/actions/whiteLabel.action";
import {getBrokerageOrganizationId} from "store/selectors/whitelabel";
import {WithNavigate} from "types/ui";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {getNextPageUrl} from "util/routeUtil";
import {UUID} from "@pinch-financial/pinch-ui-components";
import {onFetchProviderConfigs} from "pages/Root/Root.saga";
import {
  getActiveRouteSequence,
  getHasNavbarAssetLiabilitiesStepClicked,
} from "store/selectors/navigationController";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {getConsolidatedBasicInfo, getMortgageProviderIdFromApp} from "store/selectors/basicInfo";
import {fetchInitRouteSequenceNavStatus} from "components/NavigationBar/NavigationBar.saga";
import {unsetFinancialInstitution} from "store/actions/financialInstitution";
import {setPageStatus} from "~/store/actions/applicantMeta.action";

export function* doNavigate(navigate: any) {
  const activeRouteSequence: AppRouteUrl[] = yield select(getActiveRouteSequence);

  const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(getConsolidatedBasicInfo);
  const hasNavbarAssetLiabilitiesStepClicked: boolean = yield select(
    getHasNavbarAssetLiabilitiesStepClicked
  );
  const nextPage: AppRouteUrl = yield call(
    getNextPageUrl,
    AppRouteUrl.BANK_OR_BROKER,
    activeRouteSequence,
    basicInfo,
    hasNavbarAssetLiabilitiesStepClicked
  );

  yield call(navigate, nextPage);
}

export function* onNextWithBank({
  payload: {providerId, navigate},
}: WithPayload<NextWithBankPayload>) {
  yield put(setPageStatus({page: "BANK_OR_BROKER", status: "submitting"}));
  yield put(setUserSelectedProviderId(providerId));
  yield put(selectProvider(true));

  const appAssociatedFiId: UUID | undefined = yield select(getMortgageProviderIdFromApp);
  if (!appAssociatedFiId) {
    yield put(unsetFinancialInstitution());
  }

  // fetch provider config
  yield call(onFetchProviderConfigs, navigate);
  // Initialize Route Sequence again, because the provider config changed
  yield call(fetchInitRouteSequenceNavStatus);

  yield call(doNavigate, navigate);
}

export function* onNextWithBroker({payload: {navigate}}: WithPayload<WithNavigate>) {
  yield put(setPageStatus({page: "BANK_OR_BROKER", status: "submitting"}));
  const brokerageId: UUID = yield select(getBrokerageOrganizationId);
  yield put(setUserSelectedProviderId(brokerageId));
  yield put(selectProvider(true));

  const appAssociatedFiId: UUID | undefined = yield select(getMortgageProviderIdFromApp);
  if (!appAssociatedFiId) {
    yield put(unsetFinancialInstitution());
  }

  // fetch provider config
  yield call(onFetchProviderConfigs, navigate);
  // Initialize Route Sequence again, because the provider config changed
  yield call(fetchInitRouteSequenceNavStatus);

  yield call(doNavigate, navigate);
}

export default function* bankOrBrokerPageSaga() {
  yield takeEvery(nextWithBank, onNextWithBank);
  yield takeEvery(nextWithBroker, onNextWithBroker);
}
