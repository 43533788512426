import {createReducer} from "@reduxjs/toolkit";
import {saveBasicInfo, setPageStatus, unsetPageStatus} from "store/actions/applicantMeta.action";
import {signOutOperation} from "store/operation/operations";
import {
  AppRouteUrlKey,
  ResidentialApplicationBasicInfoRequest,
} from "@pinch-financial/pinch-ui-components";
import {
  acceptMissingAssetsDataWarning,
  openUserAcceptedAssetsDataWarningModal,
} from "pages/SelfReportedAssetsPage/SelfReportedAssetsPage.action";
import {
  acceptMissingLiabilitiesDataWarning,
  openUserAcceptedLiabilitiesDataWarningModal,
} from "pages/SelfReportedLiabilitiesPage/SelfReportedLiabilitiesPage.action";
import {
  acceptMissingDocumentUploadWarning,
  openUserAcceptedDocumentUploadWarningModal,
} from "pages/DocumentUploadPage/DocumentUploadPage.action";
import {selectProvider} from "pages/BankOrBrokerPage/BankOrBrokerPage.action";
import {Province} from "types/enums/province";
import {hasClickedGetStarted} from "pages/WelcomePage/WelcomePage.action";
import {PageStatus} from "~/types/ui";
import {
  acceptMissingCreditReportWarning,
  openUserAcceptedMissingCreditReportWarning,
} from "~/pages/EquifaxPage/EquifaxPage.action";

export interface ApplicantMetaState {
  shouldUserAcceptedAssetsDataWarningModalOpen: any;
  shouldUserAcceptedLiabilitiesDataWarningModalOpen: any;
  shouldUserAcceptedDocumentUploadWarningModalOpen: any;
  shouldUserAcceptedMissingCreditReportWarning: any;
  hasUserAcceptedAssetsDataWarning?: boolean;
  hasUserAcceptedLiabilitiesDataWarning?: boolean;
  hasUserAcceptedDocumentUploadWarning?: boolean;
  hasUserAcceptedMissingCreditReportWarning?: boolean;
  hasUserSelectedProvider?: boolean;
  hasClickedGetStarted: boolean;
  basicInfo?: Partial<ResidentialApplicationBasicInfoRequest>;
  province?: Province;
  pageStatuses: Partial<Record<AppRouteUrlKey, PageStatus>>;
}

export const initialState: ApplicantMetaState = {
  shouldUserAcceptedAssetsDataWarningModalOpen: undefined,
  shouldUserAcceptedLiabilitiesDataWarningModalOpen: undefined,
  shouldUserAcceptedDocumentUploadWarningModalOpen: undefined,
  shouldUserAcceptedMissingCreditReportWarning: undefined,
  hasUserAcceptedAssetsDataWarning: undefined,
  hasUserAcceptedLiabilitiesDataWarning: undefined,
  hasUserAcceptedDocumentUploadWarning: undefined,
  hasUserAcceptedMissingCreditReportWarning: undefined,
  hasUserSelectedProvider: undefined,
  hasClickedGetStarted: false,
  province: undefined,
  pageStatuses: {},
};

const applicantMetaReducer = createReducer<ApplicantMetaState>(initialState, (builder) =>
  builder
    .addCase(signOutOperation.pending, () => initialState)
    .addCase(acceptMissingAssetsDataWarning, (state, {payload: hasUserAcceptedAssetsWarning}) => {
      return {...state, hasUserAcceptedAssetsDataWarning: hasUserAcceptedAssetsWarning};
    })
    .addCase(
      acceptMissingLiabilitiesDataWarning,
      (state, {payload: hasUserAcceptedLiabilitiesWarning}) => {
        return {...state, hasUserAcceptedLiabilitiesDataWarning: hasUserAcceptedLiabilitiesWarning};
      }
    )
    .addCase(
      acceptMissingDocumentUploadWarning,
      (state, {payload: hasUserAcceptedDocumentUploadWarning}) => {
        return {...state, hasUserAcceptedDocumentUploadWarning};
      }
    )
    .addCase(selectProvider, (state, {payload: hasUserSelectedProvider}) => {
      return {...state, hasUserSelectedProvider};
    })
    .addCase(
      openUserAcceptedAssetsDataWarningModal,
      (state, {payload: shouldUserAcceptedAssetsDataWarningModalOpen}) => {
        return {
          ...state,
          shouldUserAcceptedAssetsDataWarningModalOpen,
        };
      }
    )
    .addCase(
      openUserAcceptedLiabilitiesDataWarningModal,
      (state, {payload: shouldUserAcceptedLiabilitiesDataWarningModalOpen}) => {
        return {
          ...state,
          shouldUserAcceptedLiabilitiesDataWarningModalOpen,
        };
      }
    )
    .addCase(
      acceptMissingCreditReportWarning,
      (state, {payload: hasUserAcceptedMissingCreditReportWarning}) => ({
        ...state,
        hasUserAcceptedMissingCreditReportWarning,
      })
    )
    .addCase(
      openUserAcceptedMissingCreditReportWarning,
      (state, {payload: shouldUserAcceptedMissingCreditReportWarning}) => ({
        ...state,
        shouldUserAcceptedMissingCreditReportWarning,
      })
    )
    .addCase(
      openUserAcceptedDocumentUploadWarningModal,
      (state, {payload: shouldUserAcceptedDocumentUploadWarningModalOpen}) => {
        return {
          ...state,
          shouldUserAcceptedDocumentUploadWarningModalOpen,
        };
      }
    )
    .addCase(saveBasicInfo, (state, {payload}) => {
      return {...state, basicInfo: {...state.basicInfo, ...payload}};
    })
    .addCase(setPageStatus, (state, {payload: {page, status}}) => {
      return {...state, pageStatuses: {...state.pageStatuses, [page]: status}};
    })
    .addCase(unsetPageStatus, (state, {payload}) => {
      return {...state, pageStatuses: {...state.pageStatuses, [payload]: undefined}};
    })
    .addCase(hasClickedGetStarted, (state) => {
      return {...state, hasClickedGetStarted: true};
    })
);

export default applicantMetaReducer;
