import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {trackEvent} from "util/eventUtil";
import {TextType, TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {DisclosurePage as DisclosurePageUI} from "@pinch-financial/pinch-ui-components";
import {acceptPrivacy} from "./DisclosurePage.action";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import SaveButton from "components/SaveButton/SaveButton";
import {useTranslation} from "react-i18next";
import usePageText from "hooks/usePageText";

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const DisclosurePage = ({containerRef}: Props) => {
  const {t} = useTranslation("", {keyPrefix: "disclosurePage"});
  const {getText, status: textStatus} = usePageText("DISCLOSURE_URL");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <DisclosurePageUI
      containerRef={containerRef}
      loading={textStatus === "not-started" || textStatus === "pending"}
      header={getText(TextType.APP_PAGE_HEADER) ?? t("header")}
      navigationBar={<NavigationBar />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" />}
      handleAgree={() => {
        trackEvent(TrackingEventType.personalProfileConsentAgree);
        dispatch(acceptPrivacy({navigate}));
      }}
      handleDisagree={() => {
        trackEvent(TrackingEventType.personalProfileConsentDisagree);
        navigate(AppRouteUrl.DECLINED_TERMS_URL);
      }}
      backButtonText={t("backButton")}
      nextButtonText={t("forwardButton")}
      items={t("consentItems", {returnObjects: true})}
    />
  );
};

export default DisclosurePage;
