import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getPluralFormatRule, isCurrentFrench} from "util/languageUtil";
import {
  AppRouteUrl,
  NavigationStep,
  LoadingProps,
  NavStep,
  ProductSelectPage as ProductSelectPageUI,
  StepsWithoutSelectProductOrPersonalProfile,
  ResidentialApplicationQualificationsResponseDisplay,
  TextType,
  Step,
  StepLink,
  ResidentialApplicationQualificationsResponse,
  formatAsPercentage,
  LoanType,
} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import {useTheme} from "@mui/material";
import useOnResize from "hooks/useOnResize/useOnResize";
import {monthToYear, waitSeconds} from "util/timeUtil";
import {submit, submitConsent} from "./ProductSelectPage.action";
import {formatNumberAsPercentage} from "util/numberUtil";
import {
  getAllOptionalIncompleteSteps,
  getIncompletePersonalProfileRequiredSteps,
  getStepURLs,
} from "store/selectors/navigationController";
import {getIsAuthorized} from "store/selectors/auth";
import {
  getContactInfo,
  getDidConsentToContact,
  getDownPaymentPercent,
  getIsLocked,
  getLoanTypeConsolidated,
} from "store/selectors/basicInfo";
import i18next from "i18next";
import {BACKGROUND_COLOR_IMAGE_DEFAULT} from "appConstants";
import {isCurrentUrl} from "components/utils/urlUtil";
import {hasPendingData} from "pages/CalculateQualificationPage/CalculateQualificationPage.util";
import {getUserId} from "store/selectors/user";
import {fetchLendersApi, fetchQualificationStatusApi} from "api/qualification";
import {WithDataError} from "types/api";
import {ApplicationStatusResponse} from "types/dto/applicationStatusResponse";
import {toRequiredStepLinks} from "./ProductSelectPage.util";
import {ApplicationStep} from "types/enums/applicationStep";
import {useTranslation} from "react-i18next";
import {distinct} from "components/utils/listUtil";
import usePageText from "hooks/usePageText";
import {getIsContactConsentDisplayed} from "store/selectors/configurations";
import {isNull} from "components/utils/booleanUtil";
import {getIsAllOperationsDone} from "~/store/selectors/operation";
import {OperationType} from "~/types/operation";

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const MAX_TRIES = 31; // 8 seconds for 4 loading messages, minus 1 for the first call

interface PageState {
  appStatusFetchStatus: "not-started" | "pending" | "success" | "timeout";
  appStatusFetchCount: number;
  appStatus?: ApplicationStatusResponse;

  products: ResidentialApplicationQualificationsResponse[];
  productsFetchStatus: "not-started" | "pending" | "success" | "failure" | "wont-start";

  requiredStepsPrimary: StepLink[]; // derived from backend appStatus
  requiredStepsSecondary: StepLink[]; // derived from backend appStatus
}

const ProductSelectPage = ({containerRef}: Props) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "productSelectPage"});
  const {getText, status: textStatus} = usePageText("SELECT_LENDERS_URL");
  const {t: consentModalT} = useTranslation("", {keyPrefix: "consentModal"});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [pageState, setPageState] = useState<PageState>({
    appStatusFetchStatus: "not-started",
    appStatusFetchCount: 0,
    appStatus: undefined,
    requiredStepsPrimary: [],
    requiredStepsSecondary: [],
    products: [],
    productsFetchStatus: "not-started",
  });
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const hasFetchedProviderConfigs = useSelector(getIsAllOperationsDone)(
    OperationType.fetchProviderConfigurations
  );

  const didUserConsentToContact = useSelector(getDidConsentToContact);
  const [contactConsent, setContactConsent] = useState<boolean | null>(didUserConsentToContact);

  const userId = useSelector(getUserId);

  const allOptionalIncompleteSteps = useSelector(getAllOptionalIncompleteSteps);
  const requiredTodoPeronalProfileSteps: Step[] = useSelector(
    getIncompletePersonalProfileRequiredSteps
  );
  const isAuthorized = useSelector(getIsAuthorized);
  const {email} = useSelector(getContactInfo);
  const showContactConsent = useSelector(getIsContactConsentDisplayed);
  const downPaymentPercent = useSelector(getDownPaymentPercent);
  const downPaymentFormatted = downPaymentPercent ? formatAsPercentage((downPaymentPercent || 0) * 100, isCurrentFrench(), 0, 0) : "-"
  const loanType = useSelector(getLoanTypeConsolidated);

  const {isScreenMobileWidth} = useOnResize(theme.breakpoints);
  const [displayLenderDetails, setDisplayLenderDetails] = useState(false);
  const [selectedLender, setSelectedLender] =
    useState<ResidentialApplicationQualificationsResponse | null>(null);

  const welcomePages = useSelector(getStepURLs(NavigationStep.WELCOME));
  const lenderPages = useSelector(getStepURLs(NavigationStep.LENDERS));
  const signUpPages = useSelector(getStepURLs(NavigationStep.SIGN_UP));
  const mortgageTypePages = useSelector(getStepURLs(NavigationStep.MORTGAGE_TYPE));
  const personalProfilePages = useSelector(getStepURLs(NavigationStep.PERSONAL_PROFILE));
  const bankingPages = useSelector(getStepURLs(NavigationStep.BANKING));
  const creditPages = useSelector(getStepURLs(NavigationStep.CREDIT));
  const selectProductPages = useSelector(getStepURLs(NavigationStep.SELECT_PRODUCT));
  const NAVBAR_LINKS: Record<string, StepLink> = {
    [NavStep.WELCOME]: {
      step: NavStep.WELCOME,
      stepText: fixedT([`links.${NavStep.WELCOME}`, "links.unknown"]),
      url: welcomePages[0],
    },
    [NavStep.LENDERS]: {
      step: NavStep.LENDERS,
      stepText: fixedT([`links.${NavStep.LENDERS}`, "links.unknown"]),
      url: lenderPages[0],
    },
    [NavStep.SIGN_UP]: {
      step: NavStep.SIGN_UP,
      stepText: fixedT([`links.${NavStep.SIGN_UP}`, "links.unknown"]),
      url: signUpPages[0],
    },
    [NavStep.MORTGAGE_TYPE]: {
      step: NavStep.MORTGAGE_TYPE,
      stepText: fixedT([`links.${NavStep.MORTGAGE_TYPE}`, "links.unknown"]),
      url: mortgageTypePages[0],
    },
    [NavStep.PERSONAL_PROFILE]: {
      step: NavStep.PERSONAL_PROFILE,
      stepText: fixedT([`links.${NavStep.PERSONAL_PROFILE}`, "links.unknown"]),
      url: personalProfilePages.find((page) => page !== AppRouteUrl.INVITE_SECONDARY_APPLICANT)!, // Use first non-invite secondary applicant page
    },
    [NavStep.BANKING]: {
      step: NavStep.BANKING,
      stepText: fixedT([`links.${NavStep.BANKING}`, "links.unknown"]),
      url: bankingPages[0],
    },
    [NavStep.CREDIT]: {
      step: NavStep.CREDIT,
      stepText: fixedT([`links.${NavStep.CREDIT}`, "links.unknown"]),
      url: creditPages[0],
    },
    [NavStep.SELECT_PRODUCT]: {
      step: NavStep.SELECT_PRODUCT,
      stepText: fixedT([`links.${NavStep.SELECT_PRODUCT}`, "links.unknown"]),
      url: selectProductPages[0],
    },
    [Step.SELF_REPORTED_DOCUMENTS]: {
      step: ApplicationStep.DOCUMENT, // Use same step as document to avoid duplicates.
      stepText: fixedT([`links.${ApplicationStep.DOCUMENT}`, "links.unknown"]),
      url: AppRouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS,
    },
    [ApplicationStep.DOCUMENT]: {
      step: ApplicationStep.DOCUMENT,
      stepText: fixedT([`links.${ApplicationStep.DOCUMENT}`, "links.unknown"]),
      url: AppRouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS,
    },
    [ApplicationStep.MORTGAGE_INFORMATION]: {
      step: ApplicationStep.MORTGAGE_INFORMATION,
      stepText: fixedT([`links.${ApplicationStep.DOCUMENT}`, "links.unknown"]),
      url: AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION,
    },
    // profile
    [Step.EMPLOYMENT_INFO]: {
      step: Step.EMPLOYMENT_INFO,
      stepText: fixedT([`links.${Step.EMPLOYMENT_INFO}`, "links.unknown"]),
      url: AppRouteUrl.BASIC_INFO_URL_EMPLOYMENT,
    },
    [Step.ADDRESS_INFO]: {
      step: Step.ADDRESS_INFO,
      stepText: fixedT([`links.${Step.ADDRESS_INFO}`, "links.unknown"]),
      url: AppRouteUrl.BASIC_INFO_URL_ADDRESS,
    },
    [Step.MORTGAGE_INFO]: {
      step: ApplicationStep.MORTGAGE_INFORMATION,
      stepText: fixedT([`links.${ApplicationStep.MORTGAGE_INFORMATION}`, "links.unknown"]),
      url: AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION,
    },
    [Step.PROPERTY_INFO]: {
      step: Step.PROPERTY_INFO,
      stepText: fixedT([`links.${Step.PROPERTY_INFO}`, "links.unknown"]),
      url: AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO,
    },
  };

  const isLocked = useSelector(getIsLocked);
  const lenderDetails: ResidentialApplicationQualificationsResponseDisplay[] =
    pageState.products.map((lender) => ({
      ...lender,
      productBackgroundColor: `RGB(${
        lender.productBackgroundColor ?? BACKGROUND_COLOR_IMAGE_DEFAULT
      })`,
      iconFileName: lender.productIconFileName
        ? `${window._env_.REACT_APP_IMGIX_DOMAIN}/${lender.productIconFileName}`
        : undefined,
      interestRate: formatNumberAsPercentage(lender.interestRate),
      termInMonthsText: fixedT("year", {
        count: monthToYear(lender.termInMonths),
        context: getPluralFormatRule(lender.termInMonths),
      }),
    }));

  const fetchStatusUntilNoPending = async () => {
    setPageState((prev) => ({...prev, appStatusFetchStatus: "pending"}));
    let count = 0;
    let succeeded = false;
    let response: ApplicationStatusResponse | undefined | null = undefined;
    while (count < MAX_TRIES && !succeeded) {
      count++;
      try {
        const {data, error}: WithDataError<ApplicationStatusResponse> =
          await fetchQualificationStatusApi(userId!);
        if (error) {
          console.warn("Api error, will retry: ", error?.message);
        } else {
          let hasPending = hasPendingData(data!);
          if (hasPending) {
            console.info("Something is pending, will retry...");
          } else {
            succeeded = true;
            response = data;
            break;
          }
        }
      } catch (e) {
        console.warn("Unexpected error, will continue to retry...", e);
      }
      !succeeded && (await waitSeconds(1));
    }

    let requiredStepsPrimary: StepLink[] = [];
    let requiredStepsSecondary: StepLink[] = [];
    if (succeeded && response) {
      const links = toRequiredStepLinks(response, NAVBAR_LINKS);
      requiredStepsPrimary = links.requiredStepsPrimary;
      requiredStepsSecondary = links.requiredStepsSecondary;
    }

    setPageState((prev) => ({
      ...prev,
      appStatusFetchCount: count,
      appStatus: response,
      appStatusFetchStatus: succeeded ? "success" : "timeout",
      requiredStepsPrimary,
      requiredStepsSecondary,
      productsFetchStatus:
        requiredStepsPrimary.length || requiredStepsSecondary.length ? "wont-start" : "not-started",
    }));
  };

  useEffect(() => {
    if (!userId) {
      navigate(AppRouteUrl.LANDING_PAGE_URL);
      return;
    }
    if (isLocked) {
      navigate(AppRouteUrl.APPLICATION_COMPLETE_URL);
      return;
    }
    if (pageState.appStatusFetchStatus === "not-started") {
      fetchStatusUntilNoPending();
    }
    if (pageState.appStatusFetchStatus === "timeout") {
      navigate(AppRouteUrl.PRODUCT_FETCH_TIMEOUT_URL);
    }
  }, [isLocked, userId, pageState.appStatusFetchStatus]);

  const loadProducts = async () => {
    setPageState((prev) => ({...prev, productsFetchStatus: "pending"}));
    const {data, error} = await fetchLendersApi(userId!);
    if (error || !data?.length) {
      navigate(AppRouteUrl.NO_LENDERS_URL);
      return;
    }
    setPageState((prev) => ({
      ...prev,
      products: data!,
      productsFetchStatus: "success",
    }));
  };

  useEffect(() => {
    if (
      (requiredTodoPeronalProfileSteps?.length ||
        pageState.requiredStepsPrimary?.length ||
        pageState.requiredStepsSecondary?.length) &&
      hasFetchedProviderConfigs
    ) {
      setPageState((prev) => ({
        ...prev,
        productsFetchStatus: "wont-start",
      }));
    } else if (
      pageState.appStatusFetchStatus === "success" &&
      pageState.productsFetchStatus === "not-started"
    ) {
      loadProducts();
    }
  }, [
    userId,
    pageState.appStatusFetchStatus,
    pageState.productsFetchStatus,
    pageState.requiredStepsPrimary,
    pageState.requiredStepsSecondary,
    requiredTodoPeronalProfileSteps,
    hasFetchedProviderConfigs,
  ]);

  const enumNavStep = (step: StepsWithoutSelectProductOrPersonalProfile) => {
    return i18next.t(`enums.navStep.${step}`);
  };

  const handleBackToAllRatesClick = () => {
    setDisplayLenderDetails(false);
  };

  const handleSeeMoreLenderDetailsClick = (
    lender: ResidentialApplicationQualificationsResponse
  ) => {
    setSelectedLender(lender);
    setDisplayLenderDetails(true);
  };

  const [lenderForModal, setLenderForModal] =
    useState<ResidentialApplicationQualificationsResponse | null>(null);

  const handleSubmitClick = (lender: ResidentialApplicationQualificationsResponse) => {
    setLenderForModal(lender);
    if (!contactConsent && showContactConsent && !modalOpen) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
      dispatch(submit({navigate, lender: lender || lenderForModal}));
    }
  };

  const onCheckConsent = (consentedToContact: boolean) => {
    setContactConsent(consentedToContact);
    dispatch(submitConsent({consentedToContact}));
  };

  let rotatingText = fixedT("rotatingText", {returnObjects: true}) as LoadingProps["rotatingText"];

  // This is to stop it from rotation when this page is a preview
  if (!isCurrentUrl(AppRouteUrl.SELECT_LENDERS_URL)) {
    rotatingText = [rotatingText?.[0]!];
  }

  const primaryIncompleteRequiredPages = distinct(
    [
      ...(requiredTodoPeronalProfileSteps ?? [])?.map((step) => NAVBAR_LINKS[step]),
      ...(pageState.requiredStepsPrimary ?? []),
    ],
    (step) => step.step
  );

  for (let page of primaryIncompleteRequiredPages) {
    if (!page.url) {
      // Likely caused by misconfig of app-page. Instead of providing a broken link, default to a the first page of the flow.
      console.warn("Defaulting page link for: ", page.step);
      page.url = AppRouteUrl.BASIC_INFO_URL_EMPLOYMENT;
    }
  }

  useEffect(() => {
    if (isNull(contactConsent)) {
      setContactConsent(!showContactConsent);
    }
  }, [showContactConsent]);

  return (
    <ProductSelectPageUI
      containerRef={containerRef}
      loading={
        textStatus === "not-started" ||
        textStatus === "pending" ||
        [pageState.appStatusFetchStatus, pageState.productsFetchStatus].some((status) =>
          ["timeout", "not-started", "pending"].includes(status)
        )
      }
      header={
        primaryIncompleteRequiredPages.length || pageState.requiredStepsSecondary.length ? (
          fixedT("missingStepsHeaderMessage")
        ) : (
          <span
            dangerouslySetInnerHTML={{
              __html: getText(TextType.APP_PAGE_HEADER) ?? fixedT("header")!,
            }}
          />
        )
      }
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? fixedT("tipHeader")}
      tipBody={getText(TextType.APP_PAGE_TIP_BODY) ?? fixedT("tipBody")}
      body1={getText(TextType.APP_PAGE_BODY) ?? fixedT("body1")}
      isConfetti={pageState.productsFetchStatus === "success" && pageState.products.length > 0}
      loadingMessage={fixedT("loadingMessage")}
      rotatingText={rotatingText}
      isFrench={isCurrentFrench()}
      lenders={lenderDetails as any[]} // ResidentialApplicationQualificationsResponseDisplay[]
      selectedLender={selectedLender as any} // ResidentialApplicationQualificationsResponseDisplay
      showLenderDetails={displayLenderDetails}
      moreDetailsButtonText={fixedT("moreDetailsText")}
      lessDetailsButtonText={fixedT("lessDetailsText")}
      selectRateButtonText={fixedT("selectRateButtonText")}
      navigateToAllRatesText={fixedT("navigateToAllRatesText")}
      rateLowestRateText={fixedT("rateLowestRateText")}
      viewRatesText={fixedT("viewRatesText", {numberOfProducts: 1})} // This is hardcoded for now as we are only showing 1 product from a lender
      interestRateHeader={fixedT("interestRateText")}
      monthlyPaymentHeader={fixedT("monthlyPaymentText")}
      monthlyPaymentNotAvailablePlaceholder={fixedT("monthlyPaymentNotAvailablePlaceholder")}
      monthlyPaymentNotAvailableTooltip={fixedT("monthlyPaymentNotAvailableTooltip")}
      fixedInterestText={fixedT("fixedRate")}
      variableInterestText={fixedT("variableRate")}
      isScreenMobileWidth={isScreenMobileWidth}
      onClickSeeMore={handleSeeMoreLenderDetailsClick}
      onClickBackToAllRates={handleBackToAllRatesClick}
      onClickSubmit={handleSubmitClick}
      navigationBar={<NavigationBar />}
      missingStepsMessage={fixedT("missingStepsMessage")}
      optionalStepsMessage={fixedT("optionalStepsMessage")}
      missingSecondaryApplicantDataMessage={
        pageState.requiredStepsSecondary.length > 0
          ? fixedT("missingDualApplicantDataMessage")!
          : ""
      }
      contactLenderMessage={
        <span dangerouslySetInnerHTML={{__html: fixedT("lender", {email: email}) as string}} />
      }
      unauthorizedMessage={
        !isAuthorized ? (
          <span
            dangerouslySetInnerHTML={{
              __html: fixedT("explanation_unauthorized", {
                route: AppRouteUrl.RESEND_EMAIL_URL,
              }) as string,
            }}
          />
        ) : undefined
      }
      incompleteOptionalSteps={
        pageState.productsFetchStatus === "success" && pageState.products.length > 0
          ? []
          : allOptionalIncompleteSteps
      }
      incompleteRequiredPages={primaryIncompleteRequiredPages}
      enumNavStep={enumNavStep}
      NAVBAR_LINKS={NAVBAR_LINKS}
      isFetchingLenders={pageState.productsFetchStatus === "pending"}
      isLocked={isLocked}
      contactConsentText={
        showContactConsent && !didUserConsentToContact
          ? consentModalT("contactConsentText") ?? undefined
          : undefined
      }
      // Consent Modal props
      modalTitle={consentModalT("modalTitle")}
      modalSubtitle={consentModalT("modalSubtitle")}
      modalCancelButtonText={consentModalT("modalCancelButtonText")}
      modalConfirmButtonText={consentModalT("modalConfirmButtonText")}
      onModalUpdate={() => setModalOpen(false)}
      shouldModalOpen={modalOpen}
      onCheckConsent={onCheckConsent}
      downPaymentPercentageHeader={fixedT("downPaymentPercentageText")}
      downPaymentPercentage={downPaymentFormatted}
      maxPurchaseAmountHeader={fixedT("maxPurchaseAmountText")}
      hideDownPayment={loanType === LoanType.RENEWAL}
      hideMaxPurchaseAmount={loanType === LoanType.RENEWAL}
    />
  );
};

export default ProductSelectPage;
