import {useEffect, useRef, useState} from "react";
import "dayjs/locale/fr";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  getContactInfo,
  getIsLocked,
  getLoanTypeConsolidated,
  getMortgageDetailsOrEmpty,
} from "store/selectors/basicInfo";
import {AppRouteUrl, LoanType, OnNextPage, TextType} from "@pinch-financial/pinch-ui-components";
import {submitMortgageInfo, initialize} from "./MortgageInfoPage.action";
import {trackEvent} from "util/eventUtil";
import {Province} from "types/enums/province";
import {
  LanguageType,
  LOAN_TYPES,
  MortgageInfoPage as MortgageInfoPageUI,
  PageRef,
  moneyOrZero,
} from "@pinch-financial/pinch-ui-components";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import NavigationBar from "components/NavigationBar/NavigationBar";
import i18next from "i18next";
import {getIsOperationSuccess, getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import SaveButton from "components/SaveButton/SaveButton";
import {getPreviousUrlFromHistory} from "store/selectors/navigationController";
import {useTranslation} from "react-i18next";
import usePageText from "hooks/usePageText";
import {getIsDownPaymentPercentageEnabled} from "~/store/selectors/providerconfigurations";

interface Props {
  onPrevPage?: () => any;
  onNextPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const MortgageInfoPage = ({onPrevPage, onNextPage = () => {}, containerRef}: Props) => {
  const {t} = useTranslation("", {keyPrefix: "mortgageInfoPage"});
  const {getText, status: textStatus} = usePageText("MORTGAGE_INFO_URL_MORTGAGE_INFORMATION");
  const isDownPaymentPercentageEnabled = useSelector(getIsDownPaymentPercentageEnabled);
  const isFetchConfigSuccess = useSelector(getIsOperationSuccess)(
    OperationType.fetchProviderConfigurations
  );
  const pageRef = useRef<PageRef>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locked = useSelector(getIsLocked);
  const loanType = useSelector<any, LoanType | undefined>(getLoanTypeConsolidated);
  const mortgageDetails = useSelector(getMortgageDetailsOrEmpty);
  const {email} = useSelector(getContactInfo);
  const {isPending, failure} = useSelector(getOperationResult)(OperationType.submitMortgageInfo);
  const previousURL = useSelector(getPreviousUrlFromHistory);
  const [fieldError, setFieldError] = useState<string>();

  const isKeyboardOpen = useDetectKeyboardOpen();

  const provinceOptions = (province: Province) => {
    return t(`preapproval.province.${province}`);
  };

  function onBack() {
    if (loanType) {
      trackEvent(LOAN_TYPES[loanType].trackingEventBack);
    }
    onPrevPage?.();
  }

  const onSubmit: OnNextPage = (values, e, shouldStay) => {
    if (loanType) {
      trackEvent(LOAN_TYPES[loanType].trackingEventNext);
    }
    setFieldError("");
    dispatch(
      submitMortgageInfo({
        navigate,
        shouldStay,
        onNextPage,
        data: {
          ...values,
          closingDate: values?.closingDate,
        },
      })
    );
  };

  useEffect(() => {
    if (failure) {
      console.error(`Operation error found: ${failure}`);
    }
  }, [failure]);

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);

  return (
    <MortgageInfoPageUI
      containerRef={containerRef}
      ref={pageRef}
      loading={
        !isFetchConfigSuccess ||
        textStatus === "not-started" ||
        textStatus === "pending" ||
        !loanType
      }
      header={getText(TextType.APP_PAGE_HEADER) ?? t("header")!}
      description={getText(TextType.APP_PAGE_BODY) ?? t("description")}
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? t("tipHeader")!}
      tipBody={
        <span
          dangerouslySetInnerHTML={{
            __html: getText(TextType.APP_PAGE_TIP_BODY, {email}) ?? t("tipBody", {email})!,
          }}
        />
      }
      onPrevPage={onBack}
      onNextPage={onSubmit}
      isMobileKeyboardOpen={isKeyboardOpen}
      navigationBar={<NavigationBar pageRef={pageRef} />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" pageRef={pageRef} />}
      backButtonText={i18next.t("backButton")}
      nextButtonText={i18next.t("submitButton")}
      pageError={fieldError}
      isPending={isPending}
      locked={locked}
      validateOnRender={previousURL === AppRouteUrl.SELECT_LENDERS_URL}
      selectedLoanType={loanType}
      newPurchasePurchasePriceLabel={t("newPurchase.purchasePrice")}
      newPurchaseDownPaymentSizeLabel={t("newPurchase.downPaymentSize")}
      newPurchaseClosingDateLabel={t("newPurchase.closingDate")}
      renewalMortgageBalanceLabel={t("renewal.mortgageBalance")}
      renewalAmortizationRemainingLabel={t("renewal.amortizationRemaining")}
      renewalEstimatedPropertyValueLabel={t("renewal.estimatedPropertyValue")}
      renewalRenewalDateLabel={t("renewal.renewalDate")}
      preapprovalPurchaseCityLabel={t("preapproval.purchaseCity")}
      preapprovalPurchaseProvinceLabel={t("preapproval.purchaseProvince")}
      preapprovalDownPaymentAmountLabel={t("preapproval.downPaymentAmount")}
      showDownPaymentPercentage={isDownPaymentPercentageEnabled}
      preapprovalDownPaymentPercentageLabel={t("preapproval.downPaymentPercentage")}
      firstTimeHomeBuyer={mortgageDetails.firstTimeHomeBuyer}
      firstTimeHomeBuyerLabel={t("firstTimeHomeBuyerLabel")}
      provinceOptions={provinceOptions}
      mortgageDetailsCity={mortgageDetails?.city}
      mortgageDetailsProvince={mortgageDetails?.province}
      mortgageDetailsDownPaymentAmount={moneyOrZero(mortgageDetails?.downPayment?.amount)}
      mortgageDetailsDownPaymentPercent={mortgageDetails?.downPaymentPercent}
      mortgageDetailsDownPaymentPercentMultipled100={Math.round(
        (mortgageDetails.downPaymentPercent || 0) * 100
      )}
      mortgageDetailsRemainingAmount={moneyOrZero(mortgageDetails?.remainingAmount?.amount)}
      mortgageDetailsRemainingMonths={mortgageDetails?.remainingMonths}
      mortgageDetailsEstimatedPropertyValue={moneyOrZero(
        mortgageDetails?.estimatedPropertyValue?.amount
      )}
      mortgageDetailsPurchasePrice={moneyOrZero(mortgageDetails?.purchasePrice?.amount)}
      mortgageDetailsClosingDate={mortgageDetails?.closingDate}
      mortgageDetailsRenewalDate={mortgageDetails?.renewalDate}
      missingDownPayment={t("messages.missing", {context: "downPayment"})}
      downPaymentLargerThanLoan={t("messages.downPaymentLargerThanLoan")}
      invalidDownPaymentPercentMultipled100={t("messages.invalid", {
        context: "downPaymentPercentMultipled100",
      })}
      missingRemainingAmount={t("messages.missing", {context: "remainingAmount"})}
      missingEstimatedPropertyValue={t("messages.missing", {
        context: "estimatedPropertyValue",
      })}
      missingRemainingMonths={t("messages.missing", {context: "remainingMonths"})}
      missingPurchasePrice={t("messages.missing", {context: "purchasePrice"})}
      invalidClosingDate={t("messages.invalid", {context: "closingDate"})}
      invalidClosingDateWithin6Months={t("messages.invalid", {
        context: "closingDateWithin6Months",
      })}
      invalidRenewalDate={t("messages.invalid", {context: "renewalDate"})}
      invalidRenewalDateWithin6Months={t("messages.invalid", {
        context: "renewalDateWithin6Months",
      })}
      requiredRenewalDate={t("messages.required", {context: "renewalDate"})}
      isFrench={i18next.language === LanguageType.FR_CA}
      iconAlt={t("iconAlt")}
    />
  );
};

export default MortgageInfoPage;
