import {ResidentialApplicationBasicInfoRequest} from "@pinch-financial/pinch-ui-components";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";

const namespace = "PurposeOfPurchasePage";

export type PurposeOfPurchasePagePayload = {
  onNextPage?: () => any;
} & Pick<ResidentialApplicationBasicInfoRequest, "purposeOfPurchase">;

export const submit = createAction<PurposeOfPurchasePagePayload>(actionTypeOf(namespace, "submit"));
