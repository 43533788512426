import {call, select, takeEvery} from "redux-saga/effects";
import {initialize, submitBank} from "./BanksAndFlinksPage.action";
import {getSearchParamsOf} from "components/utils/urlUtil";
import {ResidentialApplicationBasicInfoRequest} from "@pinch-financial/pinch-ui-components";
import {submitNewBankOperation} from "store/operation/basicInfo";
import {getUserId} from "store/selectors/user";
import {Step} from "@pinch-financial/pinch-ui-components";
import {WithPayload} from "types/basic";
import {WithNavigate} from "types/ui";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";

export function* onInitialize({payload: {navigate}}: WithPayload<WithNavigate>) {
  // If there's loginId in the url, then it should be redirected back from flinks page.
  const {loginId: bankLoginId, institution: bankInstitution} = yield call(
    getSearchParamsOf,
    "loginId",
    "institution"
  );

  if (bankLoginId) {
    const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
      bankLoginId,
      bankInstitution,
    };

    const userId: string = yield select(getUserId);
    yield call(submitNewBankOperation.saga, userId, basicInfoRequest, Step.ADD_BANK);
    yield call(navigate, AppRouteUrl.BASIC_INFO_URL_ADD_BANK_INFO, {replace: true});
  }
}

export function* onSubmitBank({
  payload: {navigate, bankLoginId, bankInstitution},
}: WithPayload<WithNavigate & Partial<ResidentialApplicationBasicInfoRequest>>) {
  if (bankLoginId) {
    const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
      bankLoginId,
      bankInstitution,
    };

    const userId: string = yield select(getUserId);
    yield call(submitNewBankOperation.saga, userId, basicInfoRequest, Step.ADD_BANK);
    yield call(navigate, AppRouteUrl.BASIC_INFO_URL_ADD_BANK_INFO, {replace: true});
  }
}

export default function* banksAndFlinksPageSaga() {
  yield takeEvery(initialize, onInitialize);
  yield takeEvery(submitBank, onSubmitBank);
}
