import {useState, useEffect} from "react";

function useLocalStorage(key: string) {
  const [value, setValue] = useState<string | null>(() => localStorage.getItem(key));

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent | CustomEvent | any) => {
      if (event instanceof StorageEvent && event.key === key) {
        setValue(localStorage.getItem(key));
      } else if (event instanceof CustomEvent && event.detail === key) {
        setValue(localStorage.getItem(key));
      }
    };

    // Listen for storage changes from another tab
    window.addEventListener("storage", handleStorageChange);

    // Listen for custom storage updates (same tab)
    document.addEventListener("localStorageUpdate", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      document.removeEventListener("localStorageUpdate", handleStorageChange);
    };
  }, [key]);

  return value;
}

export default useLocalStorage;
