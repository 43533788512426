import {call, put, select, takeEvery} from "redux-saga/effects";
import {
  submitBankStatementOperation,
  submitClosingDocumentOperation,
  submitMlsOperation,
  submitNoaOperation,
  submitPaystubOperation,
  submitT4Operation,
} from "store/operation/basicInfo";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {DocumentType} from "types/enums/documentType";
import {WithNavigate} from "types/ui";
import {
  UploadDocumentsPayload,
  initialize,
  submitFile,
  navigatePreviousStep,
  navigateNextStep,
  openUserAcceptedDocumentUploadWarningModal,
  InitializePayload,
} from "./DocumentUploadPage.action";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {getConsolidatedBasicInfo, getIsSecondary} from "store/selectors/basicInfo";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {shouldSkipTheDocumentPage} from "./DocumentUploadPage";
import {getShouldUserAcceptedDocumentUploadWarningModalOpen} from "store/selectors/applicantMeta";
import {isCurrentUrl} from "components/utils/urlUtil";
import {getNextPageUrl, getPreviousPageUrl} from "util/routeUtil";
import {
  getActiveRouteSequence,
  getHasNavbarAssetLiabilitiesStepClicked,
  getLatestUrlFromHistory,
} from "store/selectors/navigationController";

export function* onInitialize({payload}: WithPayload<InitializePayload>) {
  const shouldUserAcceptedDocumentUploadWarningModalOpen: boolean = yield select(
    getShouldUserAcceptedDocumentUploadWarningModalOpen
  );

  if (
    shouldSkipTheDocumentPage(payload.docStateAndRequirements) &&
    isCurrentUrl(AppRouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS) // Prevents navigating when the preview is initialized
  ) {
    const previousUrl: AppRouteUrl = yield select(getLatestUrlFromHistory);
    const activeRouteSequence: AppRouteUrl[] = yield select(getActiveRouteSequence);
    const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(
      getConsolidatedBasicInfo
    );
    const hasNavbarAssetLiabilitiesStepClicked: boolean = yield select(
      getHasNavbarAssetLiabilitiesStepClicked
    );
    const nextPageUrl: AppRouteUrl = getNextPageUrl(
      AppRouteUrl.BASIC_INFO_URL_ADDRESS,
      activeRouteSequence,
      basicInfo,
      hasNavbarAssetLiabilitiesStepClicked
    );
    if (previousUrl === nextPageUrl) {
      console.info(
        "Redirecting to the previous page in the sequence, due to all docs being hidden"
      );
      yield call(onNavigatePreviousStep, {payload: {navigate: payload.navigate}});
    }

    console.info("Redirecting to the next page in the sequence, due to all docs being hidden");
    yield call(onNavigateNextStep, {payload: {navigate: payload.navigate}});
  }

  if (shouldUserAcceptedDocumentUploadWarningModalOpen) {
    yield put(openUserAcceptedDocumentUploadWarningModal(false));
  }
}

export function* onSubmitFile({
  payload: {file, documentType, progressCallback},
}: WithPayload<UploadDocumentsPayload>) {
  const userId: string = yield select(getUserId);

  const submitFileSagas: Record<
    DocumentType,
    (...args: any[]) => Promise<SagaResult<ResidentialApplicationBasicInfoResponse>>
  > = {
    [DocumentType.T4]: submitT4Operation.saga,
    [DocumentType.NOA]: submitNoaOperation.saga,
    [DocumentType.PAYSTUB]: submitPaystubOperation.saga,
    [DocumentType.BANK_STATEMENT]: submitBankStatementOperation.saga,
    [DocumentType.MLS]: submitMlsOperation.saga,
    [DocumentType.CLOSING_DOCUMENT]: submitClosingDocumentOperation.saga,
  };
  const saga = submitFileSagas[documentType as DocumentType] || function* () {};

  yield call(saga, userId, file, documentType, progressCallback);
}

export function* onNavigatePreviousStep({payload: {navigate}}: WithPayload<WithNavigate>) {
  const activeRouteSequence: AppRouteUrl[] = yield select(getActiveRouteSequence);
  const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(getConsolidatedBasicInfo);
  const hasNavbarAssetLiabilitiesStepClicked: boolean = yield select(
    getHasNavbarAssetLiabilitiesStepClicked
  );
  const onPrevPage = getPreviousPageUrl(
    AppRouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS,
    activeRouteSequence,
    basicInfo,
    hasNavbarAssetLiabilitiesStepClicked
  );
  navigate(onPrevPage);
}

export function* onNavigateNextStep({payload: {navigate}}: WithPayload<WithNavigate>) {
  const isSecondary: boolean = yield select(getIsSecondary);
  if (isSecondary) {
    navigate(AppRouteUrl.JOINT_COMPLETED_URL);
  } else {
    const activeRouteSequence: AppRouteUrl[] = yield select(getActiveRouteSequence);
    const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(
      getConsolidatedBasicInfo
    );
    const hasNavbarAssetLiabilitiesStepClicked: boolean = yield select(
      getHasNavbarAssetLiabilitiesStepClicked
    );

    const onNextPage = getNextPageUrl(
      AppRouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS,
      activeRouteSequence,
      basicInfo,
      hasNavbarAssetLiabilitiesStepClicked
    );
    navigate(onNextPage);
  }
}

export default function* documentUploadSaga() {
  yield takeEvery(initialize, onInitialize);
  yield takeEvery(submitFile, onSubmitFile);
  yield takeEvery(navigatePreviousStep, onNavigatePreviousStep);
  yield takeEvery(navigateNextStep, onNavigateNextStep);
}
