import {call, put, select, takeEvery} from "redux-saga/effects";
import {submitLoanTypeOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {LoanType, Step} from "@pinch-financial/pinch-ui-components";
import {
  ResidentialApplicationBasicInfoRequest,
  WithNavigatePayload,
} from "@pinch-financial/pinch-ui-components";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {initialize, InitializePayload, next} from "./MortgageTypePage.action";
import {
  getActiveRouteSequence,
  getHasNavbarAssetLiabilitiesStepClicked,
  getLatestUrlFromHistory,
  getPreviousUrlFromHistory,
} from "store/selectors/navigationController";
import {getNextPageUrl, getPreviousPageUrl} from "util/routeUtil";
import {getConsolidatedBasicInfo} from "store/selectors/basicInfo";
import {saveBasicInfo} from "store/actions/applicantMeta.action";

export function* saveLoanTypeAndNavigate(
  loanType: LoanType,
  navigate: (url: AppRouteUrl) => void,
  nextPageUrl: AppRouteUrl
) {
  const userId: string = yield select(getUserId);
  const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(getConsolidatedBasicInfo);

  if (basicInfo?.mortgageDetails?.loanType !== loanType) {
    const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
      mortgageDetails: {
        ...basicInfo.mortgageDetails,
        loanType,
      },
    };
    yield put(saveBasicInfo(basicInfoRequest));

    if (userId) {
      const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
        submitLoanTypeOperation.saga,
        userId,
        {mortgageDetails: {loanType}},
        Step.LOAN_TYPE
      );

      if (Operation.isError(result)) {
        console.error("Failed to submit loan type", result);
        return;
      }
    }
  }
  yield call(navigate, nextPageUrl);
}

export function* onInitialize({
  payload: {navigate, enabledLoanTypes},
}: WithPayload<InitializePayload>) {
  if (enabledLoanTypes.length === 0) {
    console.error(
      "No loan types enabled by the financial institution. Please check your configuration."
    );
    return;
  }

  if (enabledLoanTypes.length === 1) {
    const activeRouteSequence: AppRouteUrl[] = yield select(getActiveRouteSequence);
    const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(
      getConsolidatedBasicInfo
    );
    const hasNavbarAssetLiabilitiesStepClicked: boolean = yield select(
      getHasNavbarAssetLiabilitiesStepClicked
    );
    const previousUrl: AppRouteUrl = yield select(getLatestUrlFromHistory);

    const previousPageUrl: AppRouteUrl = yield call(
      getPreviousPageUrl,
      AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE,
      activeRouteSequence,
      basicInfo,
      hasNavbarAssetLiabilitiesStepClicked
    );

    const nextPageUrl: AppRouteUrl = yield call(
      getNextPageUrl,
      AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE,
      activeRouteSequence,
      basicInfo,
      hasNavbarAssetLiabilitiesStepClicked
    );

    let navigateToUrl;
    if (previousUrl === AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION) {
      navigateToUrl = AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION;
    } else if (previousUrl === nextPageUrl) {
      console.info(
        "Redirecting to the previous page in the sequence, due to only 1 mortgage type available"
      );
      navigateToUrl = previousPageUrl;
    } else {
      console.info(
        "Redirecting to the next page in the sequence, due to only 1 mortgage type available"
      );
      navigateToUrl = nextPageUrl;
    }

    yield call(saveLoanTypeAndNavigate, enabledLoanTypes[0], navigate, navigateToUrl);
  }
}

export function* onNext({payload: {data: loanType, navigate}}: WithPayload<WithNavigatePayload>) {
  const activeRouteSequence: AppRouteUrl[] = yield select(getActiveRouteSequence);
  const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(getConsolidatedBasicInfo);
  const hasNavbarAssetLiabilitiesStepClicked: boolean = yield select(
    getHasNavbarAssetLiabilitiesStepClicked
  );
  const previousUrl: AppRouteUrl = yield select(getPreviousUrlFromHistory);

  let nextPageUrl: AppRouteUrl = yield call(
    getNextPageUrl,
    AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE,
    activeRouteSequence,
    basicInfo,
    hasNavbarAssetLiabilitiesStepClicked
  );

  if (previousUrl === AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION) {
    nextPageUrl = AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION;
  }

  yield call(saveLoanTypeAndNavigate, loanType, navigate, nextPageUrl);
}

export default function* mortgageSelectionSaga() {
  yield takeEvery(initialize, onInitialize);
  yield takeEvery(next, onNext);
}
