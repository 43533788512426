import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  getContactInfo,
  getPotentialAddressMatchesOrEmpty,
  getPurchaseOrRenewalPropertyAddress,
} from "store/selectors/basicInfo";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import {UnresolvedAVMPage as UnresolvedAVMPageUI} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import {initialize, submit} from "./UnresolvedAVMPage.action";
import SaveButton from "components/SaveButton/SaveButton";
import {useTranslation} from "react-i18next";

interface Props {
  onPrevPage?: () => any;
  onNextPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const UnresolvedAVMPage = ({onPrevPage = () => {}, onNextPage, containerRef}: Props) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "propertyAddressInfoPage"});
  const {t} = useTranslation("", {keyPrefix: "UnresolvedAVMPage"});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {email} = useSelector(getContactInfo);
  const {isPending, failure} = useSelector(getOperationResult)(
    OperationType.submitUnresolvedAVMProperty
  );

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);

  const isKeyboardOpen = useDetectKeyboardOpen();

  const addressNotRecognized = useSelector(getPurchaseOrRenewalPropertyAddress);
  const potentialAddressMatches = useSelector(getPotentialAddressMatchesOrEmpty);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<number>();

  return (
    <UnresolvedAVMPageUI
      containerRef={containerRef}
      navigationBar={<NavigationBar />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" />}
      headerText={t("header")}
      backButtonText={t("back")}
      onNextPage={() => {
        if (selectedAddressIndex == undefined) return;
        dispatch(
          submit({
            data: {...potentialAddressMatches[selectedAddressIndex]},
            navigate,
            onNextPage,
          })
        );
      }}
      onPrevPage={onPrevPage}
      isPending={isPending}
      nextButtonText={t("submit")}
      youEnteredText={t("youEntered")}
      addressLine1={t("addressLine1", {
        unitNo: addressNotRecognized?.unitNo ? `${addressNotRecognized?.unitNo} - ` : "",
        streetNo: addressNotRecognized?.streetNo,
        streetName: addressNotRecognized?.streetName,
        streetType: null, // This is not separated in the user inputted address
        streetDirection: null, // This is not separated in the user inputted address
      })}
      addressLine2={t("addressLine2", {
        city: addressNotRecognized?.city,
        province: t(`provinces.${addressNotRecognized?.province}`),
        postalCode: addressNotRecognized?.postCode?.toUpperCase(),
        streetType: null, // This is not separated in the user inputted address
        streetDirection: null,
      })}
      description={t("description")}
      suggestedAddressesText={t("suggestedAddresses")}
      pageError={failure && t("badInputError")}
      potentialAddressMatches={potentialAddressMatches.map((address) => ({
        addressLine1: t("addressLine1", {
          unitNo: address.unitNo ? `${address.unitNo} - ` : "",
          streetNo: address.streetNo,
          streetName: address.streetName,
          streetType: address.streetType ? ` ${address.streetType}` : null,
          streetDirection: address.streetDirection ? ` ${address.streetDirection}` : null,
        }),
        addressLine2: t("addressLine2", {
          city: address.city,
          province: t(`provinces.${address.province}`),
          postalCode: address.postCode?.toUpperCase(),
        }),
      }))}
      selectedAddressIndex={selectedAddressIndex}
      onSelectAddressIndex={(index) => {
        setSelectedAddressIndex(index);
      }}
      isMobileKeyboardOpen={isKeyboardOpen}
      tipHeader={fixedT("tipHeader")!}
      tipBody={<span dangerouslySetInnerHTML={{__html: fixedT("tipBody", {email: email})!}} />}
      iconAlt={fixedT("iconAlt")}
    />
  );
};

export default UnresolvedAVMPage;
