import {RefObject, useEffect, useState} from "react";
import {Box, Button, Dialog, DialogContent, Fade, Typography, useTheme} from "@mui/material";
import {DRAWER_WIDTH} from "appConstants";
import {SignUpForm} from "components/SignUpForm/SignUpForm";
import {useSelector} from "react-redux";
import {getHasUserAccount} from "store/selectors/user";
import {PageRef, SubmitButton, TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {useLocation} from "react-router-dom";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import useOnResize from "hooks/useOnResize/useOnResize";
import {trackEvent} from "util/eventUtil";
import * as styles from "./SaveButton.module.scss";
import {usePreviewContext} from "contexts/PreviewContext";
import {useAnimationContext} from "contexts/AnimationContext";
import {useTranslation} from "react-i18next";

interface SaveButton {
  displayFor: "mobile" | "non-mobile";
  pageRef?: RefObject<PageRef> | null; // The ref to the page that exposes the submitForm method.
}

const SaveButton = ({displayFor, pageRef}: SaveButton) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "saveButton"});
  const isPreview = usePreviewContext();
  const isAnimating = useAnimationContext();
  const [open, setOpen] = useState<boolean>(false);
  const hasAccount: boolean = useSelector(getHasUserAccount);
  const location = useLocation();
  const theme = useTheme();
  const {isScreenMobileWidth} = useOnResize(theme.breakpoints);

  const handleOpen = () => {
    if (hasAccount) {
      trackEvent(TrackingEventType.clickSaveAndExitAfterSignUp);
      try {
        pageRef?.current?.submitForm();
      } catch (e) {
        // Do nothing.
      }
    } else {
      trackEvent(TrackingEventType.clickSaveAndExitBeforeSignUp);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleClose();
  }, [hasAccount]);

  if (
    [
      AppRouteUrl.LANDING_PAGE_URL,
      AppRouteUrl.SIGN_UP,
      AppRouteUrl.SIGN_IN,
      AppRouteUrl.DECLINED_TERMS_URL,
      AppRouteUrl.LOGIN_CALLBACK,
      AppRouteUrl.SECONDARY_SIGNUP,
      AppRouteUrl.ACTIVATE_ACCOUNT_URL,
      AppRouteUrl.RESEND_EMAIL_URL,
      AppRouteUrl.RESET_PASSWORD_URL,
      AppRouteUrl.ERROR_PAGE_URL,
      AppRouteUrl.SELECT_LENDERS_URL,
      AppRouteUrl.APPLICATION_COMPLETE_URL,
      AppRouteUrl.NO_LENDERS_URL,
    ].includes(location.pathname as AppRouteUrl)
  ) {
    // Don't render it, regardless mobile view or not.
    return null;
  }

  if (
    (displayFor === "mobile" && !isScreenMobileWidth) ||
    (displayFor === "non-mobile" && isScreenMobileWidth)
  ) {
    return null;
  }

  if (isPreview || isAnimating) {
    return null;
  }

  return (
    <>
      <Fade in={!isPreview && !isAnimating} timeout={300}>
        <Button
          onClick={handleOpen}
          sx={{
            position: isScreenMobileWidth ? "fixed" : "absolute",
            top: "12px", // TODO: change to 24px to match design. For now use 12px, because it works well with topbar where button is vertically centered, and button position will be consistent as screen width changes.
            right: "12px",
          }}
        >
          {fixedT("saveAndExit")}
        </Button>
      </Fade>
      <Dialog
        sx={{
          ml: {laptop: "21.25%", desktop: `${DRAWER_WIDTH}px`},
          "& .MuiPaper-root.MuiDialog-paper": {
            margin: 0,
            width: {
              mobile: "90vw",
              laptop: "744px",
            },
            height: {
              mobile: hasAccount ? "auto" : "calc(87vh - 33vw)",
              laptop: hasAccount ? "auto" : "656px",
            },
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogContent
          sx={{
            padding: {laptop: "64px", desktop: "64px"},
          }}
        >
          {hasAccount ? (
            <Box sx={{padding: {laptop: "24px", mobile: "24px 8px"}}}>
              <Typography variant="h1">{fixedT("headerSaved")}</Typography>
              <Typography sx={{margin: "24px 0px 46px 0px", lineHeight: "1.3"}}>
                {fixedT("subtitleSaved")}
              </Typography>
              <SubmitButton onClick={handleClose} className={styles.nextButton}>
                {fixedT("nextButton")}
              </SubmitButton>
            </Box>
          ) : (
            <SignUpForm headerText={fixedT("header")} subtitleText={fixedT("subtitle")} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
export default SaveButton;
