import {useSelector} from "react-redux";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {getContactInfo} from "store/selectors/basicInfo";
import {NoLendersPage as NoLendersPageUI} from "@pinch-financial/pinch-ui-components";
import i18next from "i18next";
import NavigationBar from "components/NavigationBar/NavigationBar";
import {useTranslation} from "react-i18next";

interface Props {
  onPrevPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const NoLendersPage = ({onPrevPage, containerRef}: Props) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "noLendersPage"});
  const {email} = useSelector(getContactInfo);

  return (
    <NoLendersPageUI
      containerRef={containerRef}
      header={fixedT("header")}
      body={<span dangerouslySetInnerHTML={{__html: fixedT("body", {email: email})!}} />}
      onBack={() => {
        trackEvent(TrackingEventType.lenderNoQualificationClickEdit);
        onPrevPage?.();
      }}
      buttonText={i18next.t("backButton")}
      navigationBar={<NavigationBar />}
    />
  );
};

export default NoLendersPage;
