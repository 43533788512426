import {call, put, select, takeEvery} from "redux-saga/effects";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {
  submit,
  submitConsent,
  SubmitConsentPayload,
  SubmitLenderPayload,
} from "./ProductSelectPage.action";
import {getIsAuthorized} from "store/selectors/auth";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {setContactConsentOperation, setLenderOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {calculateStepStatusChange} from "components/NavigationBar/NavigationBar.action";
import {NavStep} from "@pinch-financial/pinch-ui-components";
import {getApplicantId} from "store/selectors/basicInfo";

export function* onSubmit({payload: {navigate, lender}}: WithPayload<SubmitLenderPayload>) {
  const isAuthorized: boolean = yield select(getIsAuthorized);
  if (!isAuthorized) {
    navigate && navigate(AppRouteUrl.SIGN_IN);
  }
  const userId: string = yield select(getUserId);
  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    setLenderOperation.saga,
    userId,
    lender.productPublicId
  );
  if (Operation.isSuccess(result)) {
    yield put(calculateStepStatusChange(NavStep.SELECT_PRODUCT));
    navigate(AppRouteUrl.APPLICATION_COMPLETE_URL);
  }
}

export function* onSubmitConsent({
  payload: {consentedToContact},
}: WithPayload<SubmitConsentPayload>) {
  const applicantId: string = yield select(getApplicantId);
  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    setContactConsentOperation.saga,
    applicantId,
    consentedToContact
  );
}

export default function* productSelectPageSaga() {
  yield takeEvery(submit, onSubmit);
  yield takeEvery(submitConsent, onSubmitConsent);
}
