import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {WithNavigate} from "types/ui";
import {UserRequest} from "@pinch-financial/pinch-ui-components";

const namespace = "SignUpPage";

export const initialize = createAction<WithNavigate>(actionTypeOf(namespace, "initialize"));

export type RegisterPrimaryUserPayload = Partial<UserRequest> & WithNavigate;

export const registerPrimaryUser = createAction<RegisterPrimaryUserPayload>(
  actionTypeOf(namespace, "registerPrimaryUser")
);
