import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getSearchParamsOf} from "components/utils/urlUtil";
import {initialize, registerSecondaryUser} from "./SecondarySignUpPage.action";
import {getWhitelabelId} from "store/selectors/whitelabel";
import {fetchPolicyApi} from "api/basicInfo";
import {Link, Typography} from "@mui/material";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {
  SecondarySignUpPage as SecondarySignUpPageUI,
  RegisterSecondaryUserPayload,
} from "@pinch-financial/pinch-ui-components";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import NavigationBar from "components/NavigationBar/NavigationBar";
import {getAppAssociatedFinancialInstitution} from "store/selectors/financialInstitution";
import {getUtm} from "util/localStorageUtil";
import {getIsContactConsentDisplayed} from "store/selectors/configurations";
import {useTranslation} from "react-i18next";

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const SecondarySignUpPage = ({containerRef}: Props) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "secondarySignUpPage"});
  const {t: consentModalT} = useTranslation("", {keyPrefix: "consentModal"});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {isDone: fetchFinancialInstDone, failure: fetchFinancialInstPendingFailure} = useSelector(
    getOperationResult
  )(OperationType.fetchAppAssociatedFinancialInstitutionByAppOrAuthId);
  const {isPending: registerPending, failure: submissionFailure} = useSelector(getOperationResult)(
    OperationType.registerSecondaryUser
  );
  const {email, appId} = getSearchParamsOf("email", "appId");
  const isEmailLocked = !!email;
  const [error, setError] = useState<string>();
  const financialInstitution = useSelector(getAppAssociatedFinancialInstitution);

  const whiteLabelId = useSelector(getWhitelabelId);
  const showContactConsent = useSelector(getIsContactConsentDisplayed);

  const [checked, setChecked] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const isKeyboardOpen = useDetectKeyboardOpen();

  const handleSlideTransition = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    const fetchPolicy = async () => {
      try {
        const {data: privacyPolicy} = await fetchPolicyApi(whiteLabelId);
        setPolicyContent(privacyPolicy);
      } catch (error) {
        console.error("Failed to fetch policy", error);
      }
    };

    fetchPolicy();
  }, [whiteLabelId]);

  useEffect(() => {
    if (appId) {
      dispatch(initialize({appId}));
    }
  }, [dispatch, appId]);

  const [policyContent, setPolicyContent] = useState<string | undefined>(undefined);

  const onSubmit = (values: RegisterSecondaryUserPayload) => {
    if (!values?.basicInfo?.consentedToContact && showContactConsent && !modalOpen) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
      dispatch(
        registerSecondaryUser({
          navigate,
          appId,
          ...values,
        })
      );
    }
  };

  const {firstName, lastName, phone} = getUtm();

  return (
    <SecondarySignUpPageUI
      loading={!fetchFinancialInstDone}
      containerRef={containerRef}
      isMobileKeyboardOpen={isKeyboardOpen}
      navigationBar={<NavigationBar />}
      header={fixedT("header")!}
      description={fixedT("description")}
      disabled={registerPending || isEmailLocked}
      privacyPolicy={policyContent}
      privacyPolicyTitle={fixedT("policyTitle")}
      privacyPolicyError={fixedT("errorPolicy")}
      onNextPage={onSubmit}
      checked={checked}
      handleSlideTransition={handleSlideTransition}
      firstNameLabel={fixedT("fields.firstName.label")}
      firstNamePlaceholder={fixedT("fields.firstName.placeholder")}
      firstNameDefaultValue={firstName || undefined}
      lastNameLabel={fixedT("fields.lastName.label")}
      lastNamePlaceholder={fixedT("fields.lastName.placeholder")}
      lastNameDefaultValue={lastName || undefined}
      email={email || ""}
      emailLabel={fixedT("fields.email.label")}
      emailPlaceholder={fixedT("fields.email.placeholder")}
      phoneNumberLabel={fixedT("fields.phoneNumber.label")}
      phoneNumberPlaceholder={fixedT("fields.phoneNumber.placeholder")}
      phoneNumberDefaultValue={phone || undefined}
      privacyPolicyLinkText={
        <Typography variant="body2">
          {fixedT("termsAndConditions")}{" "}
          <Link tabIndex={0} variant="body2" onClick={handleSlideTransition}>
            {fixedT("termsAndConditionsLink")}
          </Link>
        </Typography>
      }
      optInText={
        financialInstitution ? fixedT("emailOptIn", {name: financialInstitution?.name}) : ""
      }
      contactConsentText={showContactConsent ? consentModalT("contactConsentText")! : undefined}
      nextButtonText={fixedT("forwardButton")}
      pageError={error}
      // Consent Modal props
      modalTitle={consentModalT("modalTitle")}
      modalSubtitle={consentModalT("modalSubtitle")}
      modalCancelButtonText={consentModalT("modalCancelButtonText")}
      modalConfirmButtonText={consentModalT("modalConfirmButtonText")}
      onModalUpdate={() => setModalOpen(false)}
      shouldModalOpen={modalOpen}
      // Schema props
      missingFirstName={fixedT("messages.missingFirstName")}
      missingLastName={fixedT("messages.missingLastName")}
      invalidEmail={fixedT("messages.invalidEmail")}
      invalidPhoneNumber={fixedT("messages.invalidPhoneNumber")}
      isPending={registerPending}
      iconAlt={fixedT("iconAlt")!}
    />
  );
};

export default SecondarySignUpPage;
