import {lowerAndUnderscoreRemoved} from "components/utils/stringUtil";
import {nullable} from "types/basic";
import OktaAuth from "components/utils/OktaAuth/OktaAuth";
import {cleanObject} from "components/utils/objectUtil";
import {QueryParamKeys, UTM} from "@pinch-financial/pinch-ui-components";

export function getUtm(): UTM {
  return cleanObject({
    [QueryParamKeys.BROKER_CODE]: localStorage.getItem(QueryParamKeys.BROKER_CODE),
    [QueryParamKeys.UTM_CAMPAIGN]: localStorage.getItem(QueryParamKeys.UTM_CAMPAIGN),
    [QueryParamKeys.UTM_CONTENT]: localStorage.getItem(QueryParamKeys.UTM_CONTENT),
    [QueryParamKeys.UTM_MEDIUM]: localStorage.getItem(QueryParamKeys.UTM_MEDIUM),
    [QueryParamKeys.UTM_SOURCE]: localStorage.getItem(QueryParamKeys.UTM_SOURCE),
    [QueryParamKeys.UTM_TERM]: localStorage.getItem(QueryParamKeys.UTM_TERM),
    [QueryParamKeys.INTEGRATION_USER_ID]: localStorage.getItem(QueryParamKeys.INTEGRATION_USER_ID),
    [QueryParamKeys.REFERRAL_CODE]: localStorage.getItem(QueryParamKeys.REFERRAL_CODE),
    [QueryParamKeys.SELECTED_PROVINCE]: localStorage.getItem(QueryParamKeys.SELECTED_PROVINCE),
    [QueryParamKeys.PROVINCE]: localStorage.getItem(QueryParamKeys.PROVINCE),
    [QueryParamKeys.STREET_NO]: localStorage.getItem(QueryParamKeys.STREET_NO),
    [QueryParamKeys.STREET_NAME]: localStorage.getItem(QueryParamKeys.STREET_NAME),
    [QueryParamKeys.UNIT_NO]: localStorage.getItem(QueryParamKeys.UNIT_NO),
    [QueryParamKeys.POSTAL_CODE]: localStorage.getItem(QueryParamKeys.POSTAL_CODE),
    [QueryParamKeys.POST_CODE]: localStorage.getItem(QueryParamKeys.POST_CODE),
    [QueryParamKeys.CITY]: localStorage.getItem(QueryParamKeys.CITY),
    [QueryParamKeys.LOCALE]: localStorage.getItem(QueryParamKeys.LOCALE),
    [QueryParamKeys.REFERRER]: localStorage.getItem(QueryParamKeys.REFERRER),
    [QueryParamKeys.LISTING_ID]: localStorage.getItem(QueryParamKeys.LISTING_ID),
    [QueryParamKeys.MLS]: localStorage.getItem(QueryParamKeys.MLS),
    [QueryParamKeys.FIRST_NAME]: localStorage.getItem(QueryParamKeys.FIRST_NAME),
    [QueryParamKeys.LAST_NAME]: localStorage.getItem(QueryParamKeys.LAST_NAME),
    [QueryParamKeys.PHONE]: localStorage.getItem(QueryParamKeys.PHONE),
    [QueryParamKeys.EMAIL]: localStorage.getItem(QueryParamKeys.EMAIL),
    [QueryParamKeys.EXTERNAL_USER_ID]: localStorage.getItem(QueryParamKeys.EXTERNAL_USER_ID),
    [QueryParamKeys.CHANNEL]: localStorage.getItem(QueryParamKeys.CHANNEL),
  });
}

export function extractSupportedQueryParams(
  urlSearchQueryString: string
): Record<QueryParamKeys, string> {
  const lowerKeyParams: Record<string, string> = {};
  try {
    const params = new URLSearchParams(urlSearchQueryString);
    params.forEach((value, key) => {
      lowerKeyParams[lowerAndUnderscoreRemoved(key)] = value;
    });
    return Object.values(QueryParamKeys).reduce(
      (prevMap: Record<string, string>, supportedKey: QueryParamKeys) => {
        prevMap[supportedKey] =
          lowerKeyParams[lowerAndUnderscoreRemoved(supportedKey)] || prevMap[supportedKey];
        return prevMap;
      },
      {}
    );
  } catch (e) {
    console.error("Error when extracting params: ", e);
    return lowerKeyParams;
  }
}

export function storeParams(): void {
  try {
    const extractedParams: Record<string, string> = extractSupportedQueryParams(
      window.location.search
    );

    decodeReferrerIfPresent(extractedParams);
    validateReferrerIfPresent(extractedParams);

    for (let key in extractedParams) {
      if (extractedParams[key] != null) {
        setLocalStorageItem(key, extractedParams[key]);
      }
    }
  } catch (e) {
    console.warn("Error occurred when storing params: ", e);
  }
}

export function decodeReferrerIfPresent(extractedParams: Record<QueryParamKeys, string>) {
  const referrer = extractedParams.referrer || document.referrer;
  if (referrer) {
    try {
      const decodedReferrer = decodeURIComponent(referrer);
      extractedParams.referrer = decodedReferrer;
    } catch (e) {
      console.warn("Error occurred when decoding referrer: ", e);
    }
  }
}

export function validateReferrerIfPresent(
  extractedParams: Record<QueryParamKeys, string | undefined>
): void {
  // Only store external referrers. Ignore referrers from our own app (e.g. from sign in page)
  try {
    const internalReferrers = [window.location.origin, OktaAuth.getIssuerOrigin()];
    const referrerUrl = extractedParams[QueryParamKeys.REFERRER]
      ? new URL(extractedParams[QueryParamKeys.REFERRER])
      : null;

    if (referrerUrl && internalReferrers.includes(referrerUrl?.origin)) {
      delete extractedParams[QueryParamKeys.REFERRER];
    }
  } catch (e) {
    console.warn("Error occurred when validating referrer: ", e);
  }
}

export function getReferralCode(): nullable<string> {
  return localStorage.getItem(QueryParamKeys.REFERRAL_CODE);
}

export function getReferrer(): nullable<string> {
  return localStorage.getItem(QueryParamKeys.REFERRER);
}

export function getSelectedProvince(): nullable<string> {
  return localStorage.getItem(QueryParamKeys.SELECTED_PROVINCE);
}

export function storeReferrer(referrer: nullable<string>): void {
  let ref = referrer || document.referrer;

  // Only store external referrers. Ignore referrers from our own app (e.g. from sign in page)
  try {
    const referrerUrl = new URL(ref);
    if (referrerUrl.origin !== window.location.origin) {
      localStorage.setItem(QueryParamKeys.REFERRER, ref);
    }
  } catch (e) {
    return;
  }
}

export function getAddress(): Partial<UTM> {
  return {
    province: localStorage.getItem(QueryParamKeys.PROVINCE),
    streetNo: localStorage.getItem(QueryParamKeys.STREET_NO),
    streetName: localStorage.getItem(QueryParamKeys.STREET_NAME),
    unitNo: localStorage.getItem(QueryParamKeys.UNIT_NO),
    postalCode:
      localStorage.getItem(QueryParamKeys.POSTAL_CODE) ||
      localStorage.getItem(QueryParamKeys.POST_CODE),
    city: localStorage.getItem(QueryParamKeys.CITY),
  };
}

export function setLocalStorageItem(key: string, value: string | null | undefined): void {
  if (value == null) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, value);
  }
  document.dispatchEvent(new CustomEvent("localStorageUpdate", {detail: key}));
}
