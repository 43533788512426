import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {initialize, registerPrimaryUser} from "./SignUpPage.action";
import {getOperationFailure} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {Typography, Link} from "@mui/material";
import {AppRouteUrl, AppRouteUrlKey, TextType} from "@pinch-financial/pinch-ui-components";

import {fetchPolicyApi} from "api/basicInfo";
import {getSelectedOrDefaultProviderName, getWhitelabelId} from "store/selectors/whitelabel";
import {removeNavigationError} from "components/NavigationBar/NavigationBar.action";
import {SignUpPage as SignUpPageUI, SignUpPageFormData} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import i18next from "i18next";
import {useNavigate} from "react-router-dom";
import {getUtm} from "util/localStorageUtil";
import {getIsContactConsentDisplayed} from "store/selectors/configurations";
import usePageText from "hooks/usePageText";
import {useTranslation} from "react-i18next";
import {getPageStatus} from "~/store/selectors/applicantMeta";
import {unsetPageStatus} from "~/store/actions/applicantMeta.action";

interface SignUpPageProps {
  onPrevPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const pageRoute: AppRouteUrlKey = "SIGN_UP";
const SignUpPage = ({onPrevPage, containerRef}: SignUpPageProps) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "signUpPage"});
  const {t: consentModalT} = useTranslation("", {keyPrefix: "consentModal"});
  const {getText, status: textStatus} = usePageText(pageRoute);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pageStatus = useSelector(getPageStatus)(pageRoute);
  const failureError = useSelector(getOperationFailure)(OperationType.registerPrimaryUser);
  const whiteLabelId = useSelector(getWhitelabelId);
  const providerName = useSelector(getSelectedOrDefaultProviderName);
  const showContactConsent = useSelector(getIsContactConsentDisplayed);

  const isKeyboardOpen = useDetectKeyboardOpen();

  const [policyContent, setPolicyContent] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string>("");
  const [checked, setChecked] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onSubmit = (values: SignUpPageFormData) => {
    setError("");
    dispatch(removeNavigationError());

    if (!values?.basicInfo?.consentedToContact && showContactConsent && !modalOpen) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
      dispatch(
        registerPrimaryUser({
          ...values,
          navigate,
        })
      );
    }
  };

  useEffect(() => {
    const fetchPolicy = async () => {
      const {data: privacyPolicy, error} = await fetchPolicyApi(whiteLabelId);
      if (error) {
        console.error("Failed to fetch policy", error);
      } else {
        setPolicyContent(privacyPolicy);
      }
    };

    fetchPolicy();
  }, [whiteLabelId, i18next.language]);

  useEffect(() => {
    if (failureError) {
      if (failureError?.status && (failureError.status === 400 || failureError.status === 409)) {
        setError(fixedT("error_emailExists")!);
      } else if (failureError?.status && failureError.status != 200) {
        setError(fixedT("error", {context: failureError?.status})!);
      }
    }
  }, [failureError]);

  useEffect(() => {
    dispatch(initialize({navigate}));
    return () => {
      dispatch(unsetPageStatus(pageRoute));
    };
  }, []);

  const handleSlideTransition = () => {
    setChecked(!checked);
  };

  const {firstName, lastName, email, phone} = getUtm();

  return (
    <SignUpPageUI
      containerRef={containerRef}
      loading={
        pageStatus === "submitting" || textStatus === "not-started" || textStatus === "pending"
      }
      header={getText(TextType.APP_PAGE_HEADER) ?? fixedT("header")!}
      subheader={
        textStatus === "success" ? (
          <span dangerouslySetInnerHTML={{__html: getText(TextType.APP_PAGE_HEADER2)!}}></span>
        ) : (
          <span>
            {fixedT("subheader.start")}
            <a tabIndex={checked ? 1 : 0} href={AppRouteUrl.SIGN_IN}>
              {fixedT("subheader.link")}
            </a>
            {fixedT("subheader.end")}
          </span>
        )
      }
      description={getText(TextType.APP_PAGE_BODY) ?? fixedT("description")}
      isMobileKeyboardOpen={isKeyboardOpen}
      navigationBar={<NavigationBar />}
      isPending={pageStatus === "submitting"}
      privacyPolicy={policyContent}
      privacyPolicyTitle={fixedT("policyTitle")}
      privacyPolicyError={fixedT("errorPolicy")}
      onPrevPage={onPrevPage}
      onNextPage={onSubmit}
      checked={checked}
      handleSlideTransition={handleSlideTransition}
      firstNameLabel={fixedT("fields.firstName.label")}
      firstNamePlaceholder={fixedT("fields.firstName.placeholder")}
      firstNameDefaultValue={firstName || undefined}
      lastNameLabel={fixedT("fields.lastName.label")}
      lastNamePlaceholder={fixedT("fields.lastName.placeholder")}
      lastNameDefaultValue={lastName || undefined}
      emailLabel={fixedT("fields.email.label")}
      emailPlaceholder={fixedT("fields.email.placeholder")}
      emailDefaultValue={email || undefined}
      phoneNumberLabel={fixedT("fields.phoneNumber.label")}
      phoneNumberPlaceholder={fixedT("fields.phoneNumber.placeholder")}
      phoneNumberDefaultValue={phone || undefined}
      privacyPolicyLinkText={
        <Typography sx={{fontSize: {laptop: "16px", mobile: "15px"}}}>
          {fixedT("termsAndConditions")}{" "}
          <Link
            tabIndex={checked ? 2 : 0}
            onClick={handleSlideTransition}
            onKeyDown={(e) => {
              e.key === "Enter" && handleSlideTransition();
            }}
          >
            {fixedT("termsAndConditionsLink")}
          </Link>
        </Typography>
      }
      optInText={fixedT("emailOptIn", {name: providerName})}
      contactConsentText={showContactConsent ? consentModalT("contactConsentText")! : undefined}
      backButtonText={i18next.t("backButton")}
      nextButtonText={fixedT("forwardButton")}
      pageError={error}
      // Consent Modal props
      modalTitle={consentModalT("modalTitle")}
      modalSubtitle={consentModalT("modalSubtitle")}
      modalCancelButtonText={consentModalT("modalCancelButtonText")}
      modalConfirmButtonText={consentModalT("modalConfirmButtonText")}
      onModalUpdate={() => setModalOpen(false)}
      shouldModalOpen={modalOpen}
      // Schema props
      missingFirstName={fixedT("messages.missingFirstName")}
      missingLastName={fixedT("messages.missingLastName")}
      invalidEmail={fixedT("messages.invalidEmail")}
      invalidPhoneNumber={fixedT("messages.invalidPhoneNumber")}
      iconAlt={fixedT("iconAlt")}
    />
  );
};

export default SignUpPage;
