import {SuccessPage as SuccessPageUI} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import {useDispatch, useSelector} from "react-redux";
import {getIsContactConsentDisplayed} from "store/selectors/configurations";
import {submitConsent} from "pages/ProductSelectPage/ProductSelectPage.action";
import {getDidConsentToContact} from "store/selectors/basicInfo";
import {useTranslation} from "react-i18next";

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const SuccessPage = ({containerRef}: Props) => {
  const {t} = useTranslation("", {keyPrefix: "successPage"});
  const {t: consentModalT} = useTranslation("", {keyPrefix: "consentModal"});

  const dispatch = useDispatch();
  const showContactConsent = useSelector(getIsContactConsentDisplayed);
  const didUserConsentToContact = useSelector(getDidConsentToContact);

  const onCheckConsent = (consentedToContact: boolean) => {
    dispatch(submitConsent({consentedToContact}));
  };

  return (
    <SuccessPageUI
      containerRef={containerRef}
      header={<span dangerouslySetInnerHTML={{__html: t("header")!}} />}
      body1={t("body1")}
      body2={t("body2")}
      navigationBar={<NavigationBar />}
      contactConsentText={
        showContactConsent && !didUserConsentToContact
          ? consentModalT("contactConsentText")!
          : undefined
      }
      onCheckConsent={onCheckConsent}
    />
  );
};

export default SuccessPage;
