import {useEffect, useRef, useState} from "react";
import i18next from "i18next";
import {Province} from "types/enums/province";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  getContactInfo,
  getIsLocked,
  getLoanTypeConsolidated,
  getMortgageDetailsOrEmpty,
} from "store/selectors/basicInfo";
import {submitPropertyInformation, initialize} from "./PropertyAddressInfoPage.action";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {
  AppRouteUrl,
  LoanType,
  OnNextPage,
  PageRef,
  TextType,
  TrackingEventType,
  moneyOrZero,
  PropertyAddressInfoPage as PropertyAddressInfoPageUI,
  PropertyAddressInfoPageFormData,
  PropertyType,
  LanguageType,
} from "@pinch-financial/pinch-ui-components";
import {trackEvent} from "util/eventUtil";
import NavigationBar from "components/NavigationBar/NavigationBar";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import SaveButton from "components/SaveButton/SaveButton";
import {getPreviousUrlFromHistory} from "store/selectors/navigationController";
import {useTranslation} from "react-i18next";
import usePageText from "hooks/usePageText";

export const LOAN_TYPES: Record<
  any,
  {trackingEventBack: TrackingEventType; trackingEventNext: TrackingEventType}
> = {
  [LoanType.NEW_PURCHASE]: {
    trackingEventBack: TrackingEventType.propertyInformationNewPurchaseClickBack,
    trackingEventNext: TrackingEventType.propertyInformationNewPurchaseClickNext,
  },
  [LoanType.RENEWAL]: {
    trackingEventBack: TrackingEventType.propertyInformationRenewalClickBack,
    trackingEventNext: TrackingEventType.propertyInformationRenewalClickNext,
  },
};

interface Props {
  onPrevPage?: () => any;
  onNextPage?: () => any;
  className?: string;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const PropertyAddressInfoPage = ({
  onPrevPage = () => {},
  onNextPage = () => {},
  containerRef,
}: Props) => {
  const {t} = useTranslation("", {keyPrefix: "propertyAddressInfoPage"});
  const {getText, status: textStatus} = usePageText("MORTGAGE_INFO_URL_PROPERTY_INFO");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pageRef = useRef<PageRef>(null);
  const isKeyboardOpen = useDetectKeyboardOpen();
  const {email} = useSelector(getContactInfo);
  const locked = useSelector(getIsLocked);
  const mortgageDetails = useSelector(getMortgageDetailsOrEmpty);
  const loanType = useSelector<any, LoanType | undefined>(getLoanTypeConsolidated);
  const previousURL = useSelector(getPreviousUrlFromHistory);
  const {isPending, failure} = useSelector(getOperationResult)(
    OperationType.submitPropertyInformation
  );
  const [fieldError, setFieldError] = useState<string>();

  const provinceOptions = (province: Province) => {
    return t(`form.provinces.${province}`);
  };

  const onSubmit: OnNextPage<PropertyAddressInfoPageFormData> = (values, event, shouldStay) => {
    if (loanType) {
      trackEvent(LOAN_TYPES[loanType].trackingEventNext);
    }

    setFieldError("");
    dispatch(
      submitPropertyInformation({
        navigate,
        data: {...values},
        shouldStay,
        onNextPage,
      })
    );
  };

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);

  useEffect(() => {
    // We never had a translation for this so
    // I'm not comfortable with displaying this error in the UI
    if (failure) {
      console.error(`Operation error found: ${failure}`);
    }
  }, [failure]);

  return (
    <PropertyAddressInfoPageUI
      ref={pageRef}
      containerRef={containerRef}
      loading={textStatus === "not-started" || textStatus === "pending"}
      header={
        LoanType.NEW_PURCHASE === loanType
          ? getText(TextType.APP_PAGE_HEADER__NEW_PURCHASE) ?? t("headerNewPurchase")!
          : getText(TextType.APP_PAGE_HEADER__RENEWAL) ?? t("headerRenewal")!
      }
      description={
        LoanType.NEW_PURCHASE === loanType
          ? getText(TextType.APP_PAGE_BODY__NEW_PURCHASE) ?? t("descriptionNewPurchase")!
          : getText(TextType.APP_PAGE_BODY__RENEWAL) ?? t("descriptionRenewal")!
      }
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? t("tipHeader")!}
      tipBody={
        <span
          dangerouslySetInnerHTML={{
            __html: getText(TextType.APP_PAGE_TIP_BODY, {email}) ?? t("tipBody", {email})!,
          }}
        />
      }
      onPrevPage={onPrevPage}
      onNextPage={onSubmit}
      isMobileKeyboardOpen={isKeyboardOpen}
      navigationBar={<NavigationBar pageRef={pageRef} />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" pageRef={pageRef} />}
      backButtonText={i18next.t("backButton")}
      nextButtonText={t("form.submit")}
      pageError={fieldError}
      isPending={isPending}
      locked={locked}
      validateOnRender={previousURL === AppRouteUrl.SELECT_LENDERS_URL}
      googlePlacesApiKey={window._env_.REACT_APP_GOOGLE_PLACES_API_KEY}
      labelStreetNumber={t("form.streetNumber")}
      labelSuiteNumber={t("form.suiteNumber")}
      labelStreetName={t("form.streetName")}
      labelCity={t("form.city")}
      labelProvince={t("form.province")}
      labelPostalCode={t("form.postalCode")}
      labelPropertyType={t("form.propertyType")}
      propertyTypeOptionsFreehold={t("form.propertyTypeOptions", {
        context: PropertyType.FREEHOLD,
      })}
      propertyTypeOptionsCondo={t("form.propertyTypeOptions", {context: PropertyType.CONDO})}
      labelMonthlyCondoFees={t("form.monthlyCondoFees")}
      labelAnnualPropertyTaxes={t("form.annualPropertyTaxes")}
      labelGoogleAddress={t("form.fields.googleAddress.label")}
      placeholderGoogleAddress={t("form.fields.googleAddress.placeholder")}
      labelMonthlyHeatingCosts={t("form.monthlyHeatingCosts")}
      labelSquareFootage={t("form.squareFootage")}
      // Default values
      mortgageDetails={mortgageDetails}
      mortgageDetailsCondoFees={moneyOrZero(mortgageDetails?.condoFees?.amount)}
      mortgageDetailsHeatingCosts={moneyOrZero(mortgageDetails?.heatingCosts?.amount)}
      mortgageDetailsPropertyTaxes={moneyOrZero(mortgageDetails?.propertyTaxes?.amount)}
      mortgageDetailsPropertyType={mortgageDetails?.propertyType}
      mortgageDetailsSquareFootage={mortgageDetails?.squareFootage}
      mortgageDetailsStreetNo={mortgageDetails?.streetNo ?? ""}
      mortgageDetailsUnitNo={mortgageDetails?.unitNo ?? ""}
      mortgageDetailsStreetName={mortgageDetails?.streetName ?? ""}
      mortgageDetailsCity={mortgageDetails?.city ?? ""}
      mortgageDetailsProvince={mortgageDetails?.province ?? Province.NULL}
      mortgageDetailsPostCode={mortgageDetails?.postCode ?? ""}
      provinceOptions={provinceOptions}
      selectedLoanType={loanType}
      // Schema
      cityMissingMessage={t("messages.cityMissing")}
      streetNameMissingMessage={t("messages.streetNameMissing")}
      streetNoMissingMessage={t("messages.streetNoMissing")}
      provinceMissingMessage={t("messages.provinceMissing")}
      propertyTypeMissingMessage={t("messages.propertyTypeMissing")}
      invalidPostalCodeMessage={t("messages.invalidPostalCode")}
      postalCodeMissingMessage={t("messages.postalCodeMissing")}
      squareFootageMissingMessage={t("messages.squareFootageMissing")}
      condoFeeMissingMessage={t("messages.condoFeeMissing")}
      heatingCostZeroOrNegativeMessage={t("messages.heatingCostZeroOrNegative")}
      propertyTaxZeroOrNegativeMessage={t("messages.propertyTaxZeroOrNegative")}
      isFrench={i18next.language === LanguageType.FR_CA}
      iconAlt={t("iconAlt")}
    />
  );
};

export default PropertyAddressInfoPage;
