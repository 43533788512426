import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  submit,
  acceptMissingLiabilitiesDataWarning,
  openUserAcceptedLiabilitiesDataWarningModal,
} from "./SelfReportedLiabilitiesPage.action";
import {getConsolidatedBasicInfo, getIsLocked} from "store/selectors/basicInfo";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {trackEvent} from "util/eventUtil";
import {
  getHasUserAcceptedLiabilitiesDataWarning,
  getShouldUserAcceptedLiabilitiesDataWarningModalOpen,
} from "store/selectors/applicantMeta";
import {
  LanguageType,
  OnNextPage,
  PageRef,
  SelfReportedLiabilitiesPageFormData,
  SelfReportedLiabilitiesPage as SelfReportedLiabilitiesPageUI,
  TextType,
  TrackingEventType,
} from "@pinch-financial/pinch-ui-components";
import i18next from "i18next";
import NavigationBar from "components/NavigationBar/NavigationBar";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import SaveButton from "components/SaveButton/SaveButton";
import {useTranslation} from "react-i18next";
import usePageText from "hooks/usePageText";

interface Props {
  className?: () => any;
  onPrevPage?: () => any;
  onNextPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const SelfReportedLiabilitiesPage: React.FC<Props> = ({onPrevPage, onNextPage, containerRef}) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "selfReportedLiabilitiesPage"});
  const {getText, status: textStatus} = usePageText("BASIC_INFO_URL_SELF_REPORTED_LIABILITIES");

  const pageRef = useRef<PageRef>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const locked = useSelector(getIsLocked);
  const selfReportedLiabilities = useSelector(getConsolidatedBasicInfo)?.selfReportedLiabilities;
  const {isPending, failure} = useSelector(getOperationResult)(
    OperationType.submitSelfReportedIncomeAssets
  );
  const [fieldError, setFieldError] = useState<string>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const hasUserAcceptedLiabilitiesDataWarning = useSelector(
    getHasUserAcceptedLiabilitiesDataWarning
  );

  const shouldUserAcceptedLiabilitiesDataWarningModalOpen = useSelector(
    getShouldUserAcceptedLiabilitiesDataWarningModalOpen
  );

  const isKeyboardOpen = useDetectKeyboardOpen();

  useEffect(() => {
    if (
      !hasUserAcceptedLiabilitiesDataWarning &&
      shouldUserAcceptedLiabilitiesDataWarningModalOpen &&
      !Boolean(selfReportedLiabilities?.length)
    ) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [shouldUserAcceptedLiabilitiesDataWarningModalOpen, hasUserAcceptedLiabilitiesDataWarning]);

  useEffect(() => {
    if (failure) {
      console.error(`Operation error found: ${failure}`);
    }
  }, [failure]);

  function onBack() {
    trackEvent(TrackingEventType.liabilitiesClickBackButton);
    onPrevPage?.();
  }

  function onModalUpdate(value: boolean) {
    dispatch(openUserAcceptedLiabilitiesDataWarningModal(value));
  }

  const onSubmit: OnNextPage<SelfReportedLiabilitiesPageFormData> = (values, event, shouldStay) => {
    trackEvent(TrackingEventType.liabilitiesClickNextButton);
    dispatch(
      submit({
        data: {...values},
        navigate,
        shouldStay,
        onNextPage,
      })
    );
  };

  return (
    <SelfReportedLiabilitiesPageUI
      containerRef={containerRef}
      ref={pageRef}
      loading={textStatus === "not-started" || textStatus === "pending"}
      header={getText(TextType.APP_PAGE_HEADER) ?? fixedT("header")!}
      description={getText(TextType.APP_PAGE_BODY) ?? fixedT("description")}
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? fixedT("tipHeader")!}
      tipBody={getText(TextType.APP_PAGE_TIP_BODY) ?? fixedT("tipBody")}
      onPrevPage={onBack}
      onNextPage={onSubmit}
      isMobileKeyboardOpen={isKeyboardOpen}
      navigationBar={<NavigationBar pageRef={pageRef} />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" pageRef={pageRef} />}
      isPending={isPending}
      locked={locked}
      pageError={fieldError}
      backButtonText={i18next.t("backButton")}
      shouldModalOpen={modalOpen}
      nextButtonText={i18next.t("submitButton")}
      selfReportedLiabilities={selfReportedLiabilities}
      hasUserAcceptedDataWarning={hasUserAcceptedLiabilitiesDataWarning}
      onSkipAndNextPage={(data: SelfReportedLiabilitiesPageFormData) => {
        trackEvent(TrackingEventType.liabilitiesClickSkipButton);
        dispatch(acceptMissingLiabilitiesDataWarning(true));
        dispatch(
          submit({
            data: {...data},
            navigate,
            shouldStay: false,
            onNextPage,
          })
        );
      }}
      otherLiabilityLabel={(index: number) => {
        return fixedT("otherLiabilityLabel", {index: index});
      }}
      valueLabel={fixedT("value")}
      modalTitle={fixedT("modalTitle")}
      modalSubtitle={fixedT("modalSubtitle")}
      modalCancelButtonText={fixedT("modalCancel")}
      modalConfirmButtonText={fixedT("modalForward")}
      requiredMessage={fixedT("required")}
      carPaymentLabel={fixedT("carPayment")}
      mortgageLabel={fixedT("mortgage")}
      loansLabel={fixedT("loans")}
      addLiabilityLabel={fixedT("addLiability")}
      onModalUpdate={onModalUpdate}
      isFrench={i18next.language === LanguageType.FR_CA}
      iconAlt={fixedT("iconAlt")}
    />
  );
};

export default SelfReportedLiabilitiesPage;
