import {
  AppRouteUrlKey,
  BankOrBrokerPage as BankOrBrokerPageUI,
  TextType,
} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {nextWithBank, nextWithBroker} from "./BankOrBrokerPage.action";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import {getBankProvider, getLenderProvider} from "store/selectors/whitelabel";
import {useTranslation} from "react-i18next";
import {safeArray} from "components/utils/listUtil";
import {ProductSummary} from "@pinch-financial/pinch-ui-components";
import {formatAsPercentage} from "util/numberUtil";
import {useEffect} from "react";
import {trackEvent} from "util/eventUtil";
import i18next from "i18next";
import {
  getIconDisplayMapperWithPrimaryLogoOverride,
  getIsBankingRateDisplayed,
  getIsBrokerageRateDisplayed,
} from "store/selectors/configurations";
import {getContactInfo} from "store/selectors/basicInfo";
import SaveButton from "components/SaveButton/SaveButton";
import {getIsAllOperationsDone} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {unsetPageStatus} from "store/actions/applicantMeta.action";
import {getPageStatus} from "store/selectors/applicantMeta";
import usePageText from "hooks/usePageText";

interface Props {
  onPrevPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const pageRoute: AppRouteUrlKey = "BANK_OR_BROKER";
const BankOrBrokerPage = ({onPrevPage, containerRef}: Props) => {
  const {t} = useTranslation("", {keyPrefix: "BankOrBrokerPage"});
  const {getText, status: textStatus} = usePageText(pageRoute);
  const isKeyboardOpen = useDetectKeyboardOpen();

  const iconDisplayMapper = useSelector(getIconDisplayMapperWithPrimaryLogoOverride);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isBankingRateDisplayed = useSelector(getIsBankingRateDisplayed) || false;
  const isBrokerageRateDisplayed = useSelector(getIsBrokerageRateDisplayed) || false;

  const {products: bankProducts, displayRate: bankDisplayRate} = useSelector(getBankProvider) || {};
  const {products: brokerProducts, displayRate: brokerDisplayRate} =
    useSelector(getLenderProvider) || {};
  const {email} = useSelector(getContactInfo);
  const pageState = useSelector(getPageStatus)(pageRoute);
  const dataReady = useSelector(getIsAllOperationsDone)(
    OperationType.fetchProviderConfigurations,
    OperationType.fetchMortgageProviders
  );

  useEffect(() => {
    return () => {
      dispatch(unsetPageStatus(pageRoute));
    };
  }, []);

  return (
    <BankOrBrokerPageUI
      containerRef={containerRef}
      loading={
        textStatus === "not-started" ||
        textStatus === "pending" ||
        !dataReady ||
        pageState === "submitting"
      } // Prevents showing products when they are not suppose to be shown
      header={getText(TextType.APP_PAGE_HEADER) ?? t("header")!}
      description={getText(TextType.APP_PAGE_BODY) ?? t("description")!}
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? t("tipHeader")!}
      tipBody={
        <span
          dangerouslySetInnerHTML={{
            __html: getText(TextType.APP_PAGE_TIP_BODY, {email}) ?? t("tipBody", {email})!,
          }}
        />
      }
      trackEvent={trackEvent}
      onClickBank={(bankProviderId) =>
        dispatch(nextWithBank({providerId: bankProviderId, navigate}))
      }
      onClickBroker={() => dispatch(nextWithBroker({navigate}))}
      onPrevPage={onPrevPage}
      isMobileKeyboardOpen={isKeyboardOpen}
      loadingMessage=""
      navigationBar={<NavigationBar />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" />}
      iconAlt={t("iconAlt")}
      bankSectionHeader1={t("bankSectionHeader1")!}
      bankSectionHeader2={
        isBankingRateDisplayed && bankDisplayRate
          ? t("displayRate", {rate: formatAsPercentage(bankDisplayRate * 100)})
          : ""
      }
      bankDisplayRate={bankDisplayRate ?? 0}
      brokerSectionHeader1={t("brokerSectionHeader1")!}
      brokerSectionHeader2={
        isBrokerageRateDisplayed && brokerDisplayRate
          ? t("displayRate", {rate: formatAsPercentage(brokerDisplayRate * 100)})
          : ""
      }
      brokerDisplayRate={brokerDisplayRate ?? 0}
      banks={safeArray(bankProducts).map((prod: ProductSummary) => {
        const {icon, background} = iconDisplayMapper(prod);
        return {
          id: prod.financialInstitutionPublicId,
          iconSrc: icon,
          backgroundColor: `rgb(${background})`, // Expects BE format like: `255, 255, 255`
          name: prod.productName, // Rectangular image fits better here
        };
      })}
      brokers={safeArray(brokerProducts).map((prod: ProductSummary) => {
        const {icon, background} = iconDisplayMapper(prod);
        return {
          iconSrc: icon,
          backgroundColor: `rgb(${background})`,
          name: prod.productName, // Small and square/circle image fits better here
          useFullWidthDisplay: prod.useFinancialInstitutionPrimaryLogo,
        };
      })}
      backButtonText={i18next.t("backButton")!}
    />
  );
};

export default BankOrBrokerPage;
