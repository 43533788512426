import {EmploymentLength} from "types/enums/employmentLength";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getConsolidatedBasicInfo, getContactInfo, getIsLocked} from "store/selectors/basicInfo";
import {EmploymentInfoFormData, submitEmployment} from "./EmploymentInfoPage.action";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {IndustryType} from "types/enums/industryType";
import {EmploymentType} from "types/enums/employmentType";
import {IncomeType} from "types/enums/incomeType";
import {OccupationType} from "types/enums/occupationType";
import {trackEvent} from "util/eventUtil";
import i18next from "i18next";
import {
  EmploymentInfoPage as EmploymentInfoPageUI,
  TrackingEventType,
  LanguageType,
  PageRef,
  OnNextPage,
  AppRouteUrl,
  TextType,
  moneyOrZero,
} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import SaveButton from "components/SaveButton/SaveButton";
import {useRef} from "react";
import {getPreviousUrlFromHistory} from "store/selectors/navigationController";
import {useTranslation} from "react-i18next";
import usePageText from "hooks/usePageText";

interface Props {
  className?: string;
  onPrevPage?: () => any;
  onNextPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const EmploymentInfoPage = ({
  onPrevPage = () => {},
  onNextPage = () => {},
  containerRef,
}: Props) => {
  const pageRef = useRef<PageRef>(null);
  const {t} = useTranslation("", {keyPrefix: "employmentInfoPage"});
  const {getText, status: textStatus} = usePageText("BASIC_INFO_URL_EMPLOYMENT");

  const INDUSTRY_TYPE: Record<IndustryType, {label: string; value: IndustryType}> = {
    BANKING_OR_FINANCE: {
      label: t("form.industryTypeBanking"),
      value: IndustryType.BANKING_OR_FINANCE,
    },
    CONSTRUCTION: {
      label: t("form.industryTypeConstruction"),
      value: IndustryType.CONSTRUCTION,
    },
    EDUCATION: {
      label: t("form.industryTypeEducation"),
      value: IndustryType.EDUCATION,
    },
    FARMING_OR_NATURAL_RESOURCES: {
      label: t("form.industryTypeFarming"),
      value: IndustryType.FARMING_OR_NATURAL_RESOURCES,
    },
    GOVERNMENT: {
      label: t("form.industryTypeGovernment"),
      value: IndustryType.GOVERNMENT,
    },
    HEALTH: {
      label: t("form.industryTypeHealth"),
      value: IndustryType.HEALTH,
    },
    HIGH_TECH: {
      label: t("form.industryTypeHighTech"),
      value: IndustryType.HIGH_TECH,
    },
    LEISURE_OR_ENTERTAINMENT: {
      label: t("form.industryTypeLeisure"),
      value: IndustryType.LEISURE_OR_ENTERTAINMENT,
    },
    MANUFACTURING: {
      label: t("form.industryTypeManufacturing"),
      value: IndustryType.MANUFACTURING,
    },
    RETAIL_SALES: {
      label: t("form.industryTypeRetailSales"),
      value: IndustryType.RETAIL_SALES,
    },
    SERVICES: {
      label: t("form.industryTypeServices"),
      value: IndustryType.SERVICES,
    },
    TRANSPORTATION: {
      label: t("form.industryTypeTransportation"),
      value: IndustryType.TRANSPORTATION,
    },
    VARIES: {
      label: t("form.industryTypeVaries"),
      value: IndustryType.VARIES,
    },
    OTHER: {
      label: t("form.industryTypeOther"),
      value: IndustryType.OTHER,
    },
  };

  const EMPLOYMENT_TYPE: Record<EmploymentType, {label: string; value: EmploymentType}> = {
    FULL_TIME: {
      label: t("form.employmentTypeFullTime"),
      value: EmploymentType.FULL_TIME,
    },
    SELF_EMPLOYED: {
      label: t("form.employmentTypeSelfEmployed"),
      value: EmploymentType.SELF_EMPLOYED,
    },
    PART_TIME: {
      label: t("form.employmentTypePartTime"),
      value: EmploymentType.PART_TIME,
    },
  };

  const EMPLOYMENT_LENGTH: Record<EmploymentLength, {label: string; value: EmploymentLength}> = {
    FEWER_THAN_2_YEARS: {
      label: t("form.employmentLength", {context: "FEWER_THAN_2_YEARS"}),
      value: EmploymentLength.FEWER_THAN_2_YEARS,
    },
    BETWEEN_2_AND_5_YEARS: {
      label: t("form.employmentLength", {context: "BETWEEN_2_AND_5_YEARS"}),
      value: EmploymentLength.BETWEEN_2_AND_5_YEARS,
    },
    MORE_THAN_5_YEARS: {
      label: t("form.employmentLength", {context: "MORE_THAN_5_YEARS"}),
      value: EmploymentLength.MORE_THAN_5_YEARS,
    },
  };

  const INCOME_TYPE: Record<IncomeType, {label: string; value: IncomeType}> = {
    SALARY: {
      label: t("form.incomeTypeSalary"),
      value: IncomeType.SALARY,
    },
    HOURLY: {
      label: t("form.incomeTypeHourly"),
      value: IncomeType.HOURLY,
    },
    HOURLY_AND_COMMISSIONS: {
      label: t("form.incomeTypeHourlyCommissions"),
      value: IncomeType.HOURLY_AND_COMMISSIONS,
    },
    COMMISSIONS: {
      label: t("form.incomeTypeCommissions"),
      value: IncomeType.COMMISSIONS,
    },
    SELF_EMPLOYED: {
      label: t("form.incomeTypeSelfEmployed"),
      value: IncomeType.SELF_EMPLOYED,
    },
    PENSION: {
      label: t("form.incomeTypePension"),
      value: IncomeType.PENSION,
    },
    RENTAL_INCOME: {
      label: t("form.incomeTypeRentalIncome"),
      value: IncomeType.RENTAL_INCOME,
    },
    OTHER: {
      label: t("form.incomeTypeOther"),
      value: IncomeType.OTHER,
    },
  };

  const OCCUPATION_TYPE: Record<OccupationType, {label: string; value: OccupationType}> = {
    MANAGER: {
      label: t("form.OccupationTypeManager"),
      value: OccupationType.MANAGER,
    },
    CLERICAL: {
      label: t("form.OccupationTypeClerical"),
      value: OccupationType.CLERICAL,
    },
    LABOURER_OR_TRADESPERSON: {
      label: t("form.OccupationTypeLabourerTradesperson"),
      value: OccupationType.LABOURER_OR_TRADESPERSON,
    },
    RETIRED: {
      label: t("form.OccupationTypeRetired"),
      value: OccupationType.RETIRED,
    },
    PROFESSIONAL: {
      label: t("form.OccupationTypeProfessional"),
      value: OccupationType.PROFESSIONAL,
    },
    SELF_EMPLOYED: {
      label: t("form.OccupationTypeSelfEmployed"),
      value: OccupationType.SELF_EMPLOYED,
    },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const basicInfo = useSelector(getConsolidatedBasicInfo);

  const {email} = useSelector(getContactInfo);
  const locked = useSelector(getIsLocked);
  const isKeyboardOpen = useDetectKeyboardOpen();

  const {isPending, failure: submissionFailure} = useSelector(getOperationResult)(
    OperationType.submitEmploymentInfo
  );
  const previousURL = useSelector(getPreviousUrlFromHistory);

  const onSubmit: OnNextPage<EmploymentInfoFormData> = (values, event, shouldStay) => {
    trackEvent(TrackingEventType.employmentInfoClickNextButton);
    dispatch(
      submitEmployment({
        data: {...values} as EmploymentInfoFormData,
        navigate,
        onNextPage,
        shouldStay,
      })
    );
  };

  return (
    <EmploymentInfoPageUI
      containerRef={containerRef}
      ref={pageRef}
      loading={textStatus === "not-started" || textStatus === "pending"}
      header={getText(TextType.APP_PAGE_HEADER) ?? t("header")}
      description={getText(TextType.APP_PAGE_BODY) ?? t("description")}
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? t("tipHeader")}
      tipBody={
        <span
          dangerouslySetInnerHTML={{
            __html: getText(TextType.APP_PAGE_TIP_BODY, {email}) ?? t("tipBody", {email})!,
          }}
        />
      }
      onPrevPage={onPrevPage}
      onNextPage={onSubmit}
      isMobileKeyboardOpen={isKeyboardOpen}
      navigationBar={<NavigationBar pageRef={pageRef} />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" pageRef={pageRef} />}
      backButtonText={i18next.t("backButton")}
      nextButtonText={t("nextButton")}
      pageError={submissionFailure}
      isPending={isPending}
      locked={locked}
      validateOnRender={previousURL === AppRouteUrl.SELECT_LENDERS_URL}
      isFrench={i18next.language === LanguageType.FR_CA}
      INDUSTRY_TYPE={INDUSTRY_TYPE}
      EMPLOYMENT_TYPE={EMPLOYMENT_TYPE}
      EMPLOYMENT_LENGTH={EMPLOYMENT_LENGTH}
      INCOME_TYPE={INCOME_TYPE}
      OCCUPATION_TYPE={OCCUPATION_TYPE}
      employerLabel={t("form.optionsLabelEmployer")}
      jobTitleLabel={t("form.optionsLabelJobTitle")}
      industryTypeLabel={t("form.optionsLabelIndustryType")}
      grossIncomeLabel={t("form.optionsLabelGrossIncome")}
      occupationTypeLabel={t("form.optionsLabelOccupationType")}
      incomeTypeLabel={t("form.optionsLabelIncomeType")}
      employmentTypeLabel={t("form.optionsLabelEmploymentType")}
      durationLabel={t("form.optionsLabelDuration")}
      previousEmployerLabel={t("form.optionsLabelPreviousEmployer")}
      previousEmploymentLengthLabel={t("form.optionsLabelPreviousTime")}
      // Schema props
      employmentHistory={basicInfo?.employmentHistory}
      grossIncomeAmount={moneyOrZero(basicInfo?.grossIncome?.amount)}
      employerMissing={t("form.messages.missing", {context: "employer"})}
      employerMaxCharacterLimit={t("form.messages.characterLimit", {context: "employer"})}
      jobTitleMissing={t("form.messages.missing", {context: "jobTitle"})}
      jobTitleMaxCharacterLimit={t("form.messages.characterLimit", {context: "jobTitle"})}
      industryTypeMissing={t("form.messages.missing", {context: "industryType"})}
      occupationTypeMissing={t("form.messages.missing", {context: "occupationType"})}
      incomeTypeMissing={t("form.messages.missing", {context: "incomeType"})}
      employmentTypeMissing={t("form.messages.missing", {context: "employmentType"})}
      durationMissing={t("form.messages.missing", {context: "duration"})}
      previousEmployerNameMissing={t("form.messages.missing", {
        context: "previousEmployerName",
      })}
      previousEmploymentLengthMissing={t("form.messages.missing", {
        context: "previousEmploymentLength",
      })}
      grossIncomeMissing={t("form.messages.missing", {context: "grossIncome"})}
      iconAlt={t("iconAlt")}
    />
  );
};

export default EmploymentInfoPage;
