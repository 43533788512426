import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  getConsolidatedBasicInfo,
  getContactInfo,
  getHasCreditSuccess,
  getIsLocked,
} from "store/selectors/basicInfo";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {submitAddressInfo} from "./AddressInfoPage.action";
import {
  AppRouteUrl,
  LanguageType,
  OnNextPage,
  PageRef,
  TextType,
  TrackingEventType,
} from "@pinch-financial/pinch-ui-components";
import {trackEvent} from "util/eventUtil";
import {Province} from "types/enums/province";
import {
  AddressInfoPageFormData,
  AddressInfoPage as AddressInfoPageUI,
  OwnershipStatus,
  YearsAtAddressLength,
} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import i18next from "i18next";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import {useEffect, useRef, useState} from "react";
import SaveButton from "components/SaveButton/SaveButton";
import {getPreviousUrlFromHistory} from "store/selectors/navigationController";
import {useTranslation} from "react-i18next";
import usePageText from "hooks/usePageText";

interface Props {
  className?: string;
  onPrevPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const AddressInfoPage = ({onPrevPage = () => {}, className = "", containerRef}: Props) => {
  const {t} = useTranslation("", {keyPrefix: "addressInfoPage"});
  const {getText, status: textStatus} = usePageText("BASIC_INFO_URL_ADDRESS");
  const pageRef = useRef<PageRef>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const basicInfo = useSelector(getConsolidatedBasicInfo);
  const hasCreditSuccess = useSelector(getHasCreditSuccess);
  const locked = useSelector(getIsLocked);
  const {isPending, failure} = useSelector(getOperationResult)(OperationType.submitAddressInfo);
  const previousURL = useSelector(getPreviousUrlFromHistory);
  const isKeyboardOpen = useDetectKeyboardOpen();

  const {email} = useSelector(getContactInfo);
  const [fieldError, setFieldError] = useState<string>();

  const provinceOptions = (typeVal: Province) => {
    return t(`provinces.${typeVal}`);
  };

  const timeAtAddressOptions = (typeVal: YearsAtAddressLength) => {
    return t("timeAtAddress", {context: typeVal});
  };

  const onSubmit: OnNextPage<AddressInfoPageFormData> = (values, event, shouldStay) => {
    trackEvent(TrackingEventType.addressInfoClickNextButton);
    setFieldError("");
    dispatch(
      submitAddressInfo({
        data: {...values} as AddressInfoPageFormData,
        navigate,
        shouldStay,
      })
    );
  };

  const onBack = () => {
    trackEvent(TrackingEventType.addressInfoClickBackButton);
    onPrevPage?.();
  };

  useEffect(() => {
    if (failure) {
      console.error(`Operation error found: ${failure}`);
    }
  }, [failure]);

  return (
    <AddressInfoPageUI
      containerRef={containerRef}
      ref={pageRef}
      loading={textStatus === "not-started" || textStatus === "pending"}
      header={getText(TextType.APP_PAGE_HEADER) ?? t("header")!}
      description={getText(TextType.APP_PAGE_BODY) ?? t("description")}
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? t("tipHeader")!}
      tipBody={
        <span
          dangerouslySetInnerHTML={{
            __html: getText(TextType.APP_PAGE_TIP_BODY, {email}) ?? t("tipBody", {email})!,
          }}
        />
      }
      onPrevPage={onBack}
      onNextPage={onSubmit}
      isMobileKeyboardOpen={isKeyboardOpen}
      navigationBar={<NavigationBar pageRef={pageRef} />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" pageRef={pageRef} />}
      backButtonText={i18next.t("backButton")}
      nextButtonText={i18next.t("submitButton")}
      iconAlt={t("iconAlt")}
      pageError={fieldError}
      isPending={isPending}
      locked={locked}
      validateOnRender={previousURL === AppRouteUrl.SELECT_LENDERS_URL}
      lockCurrentAddress={hasCreditSuccess}
      googlePlacesApiKey={window._env_.REACT_APP_GOOGLE_PLACES_API_KEY}
      labelStreetNumber={t("labelStreetNumber")}
      labelSuiteNumber={t("labelSuiteNumber")}
      labelStreetName={t("labelStreetName")}
      labelCity={t("labelCity")}
      labelProvince={t("labelProvince")}
      labelPostalCode={t("labelPostalCode")}
      labelOwnershipStatus={t("labelOwnershipStatus")}
      ownershipStatusRenter={t("ownershipStatus", {context: OwnershipStatus.RENTER})}
      ownershipStatusOwner={t("ownershipStatus", {context: OwnershipStatus.OWNER})}
      labelTimeAtAddress={t("labelTimeAtAddress")}
      previousAddressMessage={t("previousAddressMessage")}
      labelGoogleAddressCurrent={t("fields.googleAddressCurrent.label")}
      placeholderGoogleAddressCurrent={t("fields.googleAddressCurrent.placeholder")}
      labelGoogleAddressPrevious={t("fields.googleAddressPrevious.label")}
      placeholderGoogleAddressPrevious={t("fields.googleAddressPrevious.placeholder")}
      // Default values
      timeAtAddress={basicInfo?.timeAtAddress}
      ownershipStatus={basicInfo?.ownershipStatus}
      residentialAddressUnitNo={basicInfo?.residentialAddress?.unitNo}
      residentialAddressStreetNo={basicInfo?.residentialAddress?.streetNo}
      residentialAddressStreetName={basicInfo?.residentialAddress?.streetName}
      residentialAddressCity={basicInfo?.residentialAddress?.city}
      residentialAddressProvince={basicInfo?.residentialAddress?.province}
      residentialAddressPostCode={basicInfo?.residentialAddress?.postCode}
      previousResidentialAddressUnitNo={basicInfo?.previousResidentialAddress?.unitNo}
      previousResidentialAddressStreetNo={basicInfo?.previousResidentialAddress?.streetNo}
      previousResidentialAddressStreetName={basicInfo?.previousResidentialAddress?.streetName}
      previousResidentialAddressCity={basicInfo?.previousResidentialAddress?.city}
      previousResidentialAddressProvince={basicInfo?.previousResidentialAddress?.province}
      previousResidentialAddressPostCode={basicInfo?.previousResidentialAddress?.postCode}
      timeAtAddressOptions={timeAtAddressOptions}
      provinceOptions={provinceOptions}
      missingCurrentStreetNo={t("messages.missing", {
        context: "currentStreetNo",
      })}
      missingCurrentStreetName={t("messages.missing", {
        context: "currentStreetName",
      })}
      missingCurrentCity={t("messages.missing", {
        context: "currentCity",
      })}
      missingCurrentProvince={t("messages.missing", {
        context: "currentProvince",
      })}
      missingCurrentPostCode={t("messages.missing", {
        context: "currentPostCode",
      })}
      missingPreviousStreetNo={t("messages.missing", {
        context: "previousStreetNo",
      })}
      missingPreviousStreetName={t("messages.missing", {
        context: "previousStreetName",
      })}
      missingPreviousCity={t("messages.missing", {
        context: "previousCity",
      })}
      missingPreviousProvince={t("messages.missing", {
        context: "previousProvince",
      })}
      missingPreviousPostCode={t("messages.missing", {
        context: "previousPostCode",
      })}
      missingTimeAtAddress={t("messages.missing", {
        context: "timeAtAddress",
      })}
      missingOwnershipStatus={t("messages.missing", {
        context: "ownershipStatus",
      })}
      invalidPostCode={t("messages.invalid", {
        context: "postCode",
      })}
      invalidPreviousPostCode={t("messages.invalid", {
        context: "postCode",
      })}
      isFrench={i18next.language === LanguageType.FR_CA}
    />
  );
};

export default AddressInfoPage;
