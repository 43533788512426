import {nonNull} from "components/utils/booleanUtil";
import {equalIgnoreCase, isBlank} from "components/utils/stringUtil";
import {nullable} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {LoanType, NotFoundPage} from "@pinch-financial/pinch-ui-components";
import {Step} from "@pinch-financial/pinch-ui-components";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import AddressInfoPage from "pages/AddressInfoPage/AddressInfoPage";
import BankOrBrokerPage from "pages/BankOrBrokerPage/BankOrBrokerPage";
import BanksAndFlinksPage from "pages/BanksAndFlinksPage/BanksAndFlinksPage";
import CalculateQualificationPage from "pages/CalculateQualificationPage/CalculateQualificationPage";
import DeclinedPage from "pages/DeclinedPage/DeclinedPage";
import DisclosurePage from "pages/DisclosurePage/DisclosurePage";
import DocumentUploadPage from "pages/DocumentUploadPage/DocumentUploadPage";
import EmploymentInfoPage from "pages/EmploymentInfoPage/EmploymentInfoPage";
import EquifaxPage from "pages/EquifaxPage/EquifaxPage";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import InviteSecondaryApplicantPage from "pages/InviteSecondaryApplicantPage/InviteSecondaryApplicantPage";
import MortgageInfoPage from "pages/MortgageInfoPage/MortgageInfoPage";
import MortgageTypePage from "pages/MortgageTypePage/MortgageTypePage";
import NoLendersPage from "pages/NoLendersPage/NoLendersPage";
import ProductSelectPage from "pages/ProductSelectPage/ProductSelectPage";
import PropertyAddressInfoPage from "pages/PropertyAddressInfoPage/PropertyAddressInfoPage";
import PurchaseTimeframePage from "pages/PurchaseTimeframePage/PurchaseTimeframePage";
import ResendEmailPage from "pages/ResendEmailPage/ResendEmailPage";
import SecondaryApplicantCompletePage from "pages/SecondaryApplicantCompletePage/SecondaryApplicantCompletePage";
import SecondarySignUpPage from "pages/SecondarySignUpPage/SecondarySignUpPage";
import SelfReportedAssetsPage from "pages/SelfReportedAssetsPage/SelfReportedAssetsPage";
import SelfReportedCreditScorePage from "pages/SelfReportedCreditScorePage/SelfReportedCreditScorePage";
import SelfReportedLiabilitiesPage from "pages/SelfReportedLiabilitiesPage/SelfReportedLiabilitiesPage";
import SetPasswordPage from "pages/SetPasswordPage/SetPasswordPage";
import SignInPage from "pages/SignInPage/SignInPage";
import SignUpPage from "pages/SignUpPage/SignUpPage";
import SuccessPage from "pages/SuccessPage/SuccessPage";
import UnresolvedAVMPage from "pages/UnresolvedAVMPage/UnresolvedAVMPage";
import WelcomePage from "pages/WelcomePage/WelcomePage";
import {ApplicantMetaState} from "store/reducers/applicantMeta";
import ProvinceSelectPage from "pages/ProvinceSelectPage/ProvinceSelectPage";
import ProductFetchTimeoutPage from "pages/ProductFetchTimeoutPage/ProductFetchTimeoutPage";
import PurposeOfPurchasePage from "pages/PurposeOfPurchasePage/PurposeOfPurchasePage";

// activeRouteSequence should take the precedence when deciding the next page.
export const steps = [
  // Step.SELF_REPORTED_CREDIT_SCORE, // This is part of sign up
  Step.SIGNED_UP,
  Step.ACCEPT_POLICY,
  Step.PERSONAL_PROFILE,
  Step.EMPLOYMENT_INFO,
  Step.ADDRESS_INFO,
  Step.SELF_REPORTED_ASSETS,
  Step.SELF_REPORTED_LIABILITIES,
  Step.SELF_REPORTED_DOCUMENTS,
  Step.ADD_BANK,
  Step.LEGAL_NAMES_SIN,
  Step.LOAN_TYPE,
  Step.MORTGAGE_INFO,
  Step.PROPERTY_INFO,
  Step.SELECT_LENDER,
  Step.REVIEW_SUBMISSION,
];

export const pageMap = {
  [AppRouteUrl.ERROR_PAGE_URL]: ErrorPage,
  [AppRouteUrl.PROVINCE_SELECT_PAGE_URL]: ProvinceSelectPage,
  [AppRouteUrl.LANDING_PAGE_URL]: WelcomePage,
  [AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE]: MortgageTypePage,
  [AppRouteUrl.PURCHASE_TIMEFRAME_URL]: PurchaseTimeframePage,
  [AppRouteUrl.SELF_REPORTED_CREDIT_DIRECT]: SelfReportedCreditScorePage,
  [AppRouteUrl.BANK_OR_BROKER]: BankOrBrokerPage,
  [AppRouteUrl.BASIC_INFO_URL_EMPLOYMENT]: EmploymentInfoPage,
  [AppRouteUrl.BASIC_INFO_URL_SIN_FORM]: EquifaxPage,
  [AppRouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS]: DocumentUploadPage,
  [AppRouteUrl.ACTIVATE_ACCOUNT_URL]: SetPasswordPage,
  [AppRouteUrl.RESET_PASSWORD_URL]: SetPasswordPage,
  [AppRouteUrl.SIGN_UP]: SignUpPage,
  [AppRouteUrl.SIGN_IN]: SignInPage,
  [AppRouteUrl.SECONDARY_SIGNUP]: SecondarySignUpPage,
  [AppRouteUrl.DECLINED_TERMS_URL]: DeclinedPage,
  [AppRouteUrl.DISCLOSURE_URL]: DisclosurePage,
  [AppRouteUrl.INVITE_SECONDARY_APPLICANT]: InviteSecondaryApplicantPage,
  [AppRouteUrl.RESEND_EMAIL_URL]: ResendEmailPage,
  [AppRouteUrl.BASIC_INFO_URL]: ErrorPage,
  [AppRouteUrl.BASIC_INFO_URL_ADDRESS]: AddressInfoPage,
  [AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_ASSETS]: SelfReportedAssetsPage,
  [AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_LIABILITIES]: SelfReportedLiabilitiesPage,
  [AppRouteUrl.BASIC_INFO_URL_ADD_BANK_INFO]: BanksAndFlinksPage,
  [AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION]: MortgageInfoPage,
  [AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO]: PropertyAddressInfoPage,
  [AppRouteUrl.DETAILS_COMPLETE_URL]: CalculateQualificationPage,
  [AppRouteUrl.SELECT_LENDERS_URL]: ProductSelectPage,
  [AppRouteUrl.APPLICATION_COMPLETE_URL]: SuccessPage,
  [AppRouteUrl.NO_LENDERS_URL]: NoLendersPage,
  [AppRouteUrl.PRODUCT_FETCH_TIMEOUT_URL]: ProductFetchTimeoutPage,
  [AppRouteUrl.UNRESOLVED_AVM_URL]: UnresolvedAVMPage,
  [AppRouteUrl.JOINT_COMPLETED_URL]: SecondaryApplicantCompletePage,
  [AppRouteUrl.LOGIN_CALLBACK]: ErrorPage,
  [AppRouteUrl.NOT_FOUND_PAGE_URL]: NotFoundPage,
  [AppRouteUrl.PURPOSE_OF_PURCHASE_URL]: PurposeOfPurchasePage,
};

export function getPageComponent(url: AppRouteUrl) {
  return pageMap[url] || undefined;
}

export function getLastStepCompletedMortgageDetailsFlow(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): Step | null {
  if (hasChosenProduct(basicInfo)) {
    return Step.REVIEW_SUBMISSION;
  }
  if (hasCompletedMortgageInfo(basicInfo)) {
    return Step.MORTGAGE_INFO;
  }
  if (hasCompletedSubjectPropertyIfRequired(basicInfo)) {
    return Step.PROPERTY_INFO;
  }
  if (hasSelectedLoanType(basicInfo)) {
    return Step.LOAN_TYPE;
  }
  return null;
}

export function hasSelectedLoanType(basicInfo?: ResidentialApplicationBasicInfoResponse): boolean {
  return nonNull(basicInfo?.mortgageDetails?.loanType);
}

export function hasCompletedMortgageInfo(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return hasSelectedLoanType(basicInfo);
}

export function hasCompletedSubjectPropertyIfRequired(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return nonNull(basicInfo?.mortgageDetails?.streetName);
}

export function hasChosenProduct(basicInfo?: ResidentialApplicationBasicInfoResponse): boolean {
  return nonNull(basicInfo?.mortgageDetails?.productPublicId);
}

export function hasPulledCreditData(basicInfo?: ResidentialApplicationBasicInfoResponse): boolean {
  return (
    nonNull(basicInfo?.legalFirstName) &&
    nonNull(basicInfo?.legalLastName) &&
    nonNull(basicInfo?.creditReportPublicId)
  );
}

export function hasAcceptedPrivacyPolicy(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return !isBlank(basicInfo?.acceptedPrivacyPolicyAt);
}

export function hasCompletedPersonalInfo(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return nonNull(basicInfo?.dateOfBirth);
}

export function hasCompletedEmploymentInfo(basicInfo?: ResidentialApplicationBasicInfoResponse) {
  return nonNull(basicInfo?.employmentHistory);
}

export function hasCompletedAddressInfo(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return nonNull(basicInfo?.residentialAddress);
}

export function hasSelfReportedAssets(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return nonNull(basicInfo?.selfReportedAssets) && !!basicInfo?.selfReportedAssets?.length;
}

export function hasSelfReportedLiabilities(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return (
    nonNull(basicInfo?.selfReportedLiabilities) && !!basicInfo?.selfReportedLiabilities?.length
  );
}

export function hasSelfReportedCreditScore(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return Boolean(nonNull(basicInfo?.creditScore) && nonNull(basicInfo?.dateOfBirth));
}

export function hasSelfReportedGrossIncome(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return nonNull(basicInfo?.grossIncome);
}

export function hasUploadedRequiredDocuments(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return (
    (basicInfo?.uploadedT4 &&
      basicInfo?.uploadedNOA &&
      basicInfo?.uploadedPayStub &&
      basicInfo?.uploadedBankStatement) ??
    false
  );
}

// This is for the pages that are actually being used in the flow
export let activeRouteSequenceList: AppRouteUrl[] = [
  // This is to be decided by BE
  AppRouteUrl.LANDING_PAGE_URL,
  AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE,
  AppRouteUrl.PURCHASE_TIMEFRAME_URL,
  AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_ASSETS,
  AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_LIABILITIES,
  AppRouteUrl.SELF_REPORTED_CREDIT_DIRECT,
  AppRouteUrl.BANK_OR_BROKER,
  AppRouteUrl.SIGN_UP,
  AppRouteUrl.DISCLOSURE_URL,
  AppRouteUrl.INVITE_SECONDARY_APPLICANT,
  AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO,
  AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION,
  AppRouteUrl.BASIC_INFO_URL_EMPLOYMENT,
  AppRouteUrl.BASIC_INFO_URL_ADDRESS,
  AppRouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS,
  AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_ASSETS,
  AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_LIABILITIES,
  AppRouteUrl.BASIC_INFO_URL_ADD_BANK_INFO,
  AppRouteUrl.BASIC_INFO_URL_SIN_FORM,
  AppRouteUrl.SELECT_LENDERS_URL,
  AppRouteUrl.APPLICATION_COMPLETE_URL,
];

function shouldSkipPage(
  page: AppRouteUrl,
  basicInfo: ResidentialApplicationBasicInfoResponse | null,
  hasNavbarAssetLiabilitiesStepClicked?: boolean
): boolean {
  // if the user has signed up, skip sign up page and BankOrBrokerPage
  if (
    basicInfo?.applicationPublicId &&
    (page === AppRouteUrl.SIGN_UP || page === AppRouteUrl.BANK_OR_BROKER)
  )
    return true;
  // if the disclosure has been accepted, skip disclosure page
  if (basicInfo?.acceptedPrivacyPolicyAt && page === AppRouteUrl.DISCLOSURE_URL) return true;
  // if the user is secondary applicant or if the user has accepted disclosure or if there is already a secondary user,
  // skip invite secondary applicant page (we need a better way of tracking if the user said no to inviting a secondary applicant in the app)
  if (
    (!basicInfo?.isPrimaryApplicant || basicInfo?.secondaryUserEmail) &&
    page === AppRouteUrl.INVITE_SECONDARY_APPLICANT
  )
    return true;

  // if the user chose a mortgage type, skip mortgage type page
  if (basicInfo?.mortgageDetails?.loanType && page === AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE)
    return true;

  // if the user chose a self reported credit score, skip self reported credit score page
  if (basicInfo?.creditScore && page === AppRouteUrl.SELF_REPORTED_CREDIT_DIRECT) return true;

  // if secondary applicant, skip mortgage info pages
  if (
    !basicInfo?.isPrimaryApplicant &&
    basicInfo?.applicationPublicId &&
    (page === AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION ||
      page === AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO)
  ) {
    return true;
  }

  // if secondary applicant, skip purpose of purchase page
  if (
    !basicInfo?.isPrimaryApplicant &&
    basicInfo?.applicationPublicId &&
    page === AppRouteUrl.PURPOSE_OF_PURCHASE_URL
  ) {
    return true;
  }

  // If preapproval, skip property info page
  if (
    basicInfo?.mortgageDetails?.loanType &&
    equalIgnoreCase(LoanType.PREAPPROVAL, basicInfo?.mortgageDetails?.loanType) &&
    page === AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO
  )
    return true;

  if (
    page === AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_ASSETS &&
    !hasNavbarAssetLiabilitiesStepClicked &&
    Boolean(basicInfo?.selfReportedAssets?.length)
  )
    return true;
  if (
    page === AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_LIABILITIES &&
    !hasNavbarAssetLiabilitiesStepClicked &&
    Boolean(basicInfo?.selfReportedLiabilities?.length)
  )
    return true;

  return false;
}

export function getNextPageUrl(
  currentPage: AppRouteUrl,
  pages: AppRouteUrl[],
  basicInfo: ResidentialApplicationBasicInfoResponse | null,
  hasNavbarAssetLiabilitiesStepClicked?: boolean
): AppRouteUrl {
  const currentIndex = !!basicInfo?.applicantDetailsPublicId
    ? pages.findLastIndex((page) => currentPage === page)
    : pages.indexOf(currentPage);

  if (currentIndex === -1) {
    console.warn(
      `Abnormal - Current page ${currentPage} is not in the page list. Will pick a best available page.`
    );
    return currentPage;
  }

  let nextPageIndex = currentIndex + 1;

  while (
    nextPageIndex < pages.length - 1 &&
    shouldSkipPage(pages[nextPageIndex], basicInfo, hasNavbarAssetLiabilitiesStepClicked)
  ) {
    nextPageIndex++;
  }

  if (nextPageIndex >= pages.length) {
    nextPageIndex = currentIndex;
  }

  const nextPage = pages[nextPageIndex];

  return nextPage || currentPage;
}

export function getPreviousPageUrl(
  currentPage: AppRouteUrl,
  pages: AppRouteUrl[],
  basicInfo: ResidentialApplicationBasicInfoResponse | null,
  hasNavbarAssetLiabilitiesStepClicked?: boolean
): AppRouteUrl {
  // Save computation time and avoid warnings, this will always be the case.
  if (currentPage === AppRouteUrl.DECLINED_TERMS_URL) {
    return AppRouteUrl.DISCLOSURE_URL;
  }

  const currentIndex = pages.indexOf(currentPage);

  if (currentIndex === -1) {
    console.warn(
      `Abnormal - Current page ${currentPage} is not in the page list. Will pick a best available page.`
    );
    return pages[0];
  }

  let previousPageIndex = currentIndex - 1;

  while (
    previousPageIndex >= 0 &&
    shouldSkipPage(pages[previousPageIndex], basicInfo, hasNavbarAssetLiabilitiesStepClicked)
  ) {
    previousPageIndex--;
  }

  if (previousPageIndex < 0) {
    previousPageIndex = currentIndex;
  }

  const previousPage = pages[previousPageIndex];

  return previousPage || currentPage;
}

export function getNextStep(currentStep: Step, steps: Step[]): Step {
  const currentIndex = steps.indexOf(currentStep);
  if (steps.length > currentIndex + 1) {
    return steps[currentIndex + 1];
  }
  return steps[0];
}

export function getMortgagePageUrl(url: AppRouteUrl, loanType: nullable<LoanType>): AppRouteUrl {
  if (AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO == url && LoanType.PREAPPROVAL == loanType) {
    return AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION;
  }
  return url;
}

// Some routes don't have user/application, but we need to load the financial institution to display the page.
export const RoutesRequireFinancialInstitutionByAppOrAuthId: string[] = [
  AppRouteUrl.SECONDARY_SIGNUP,
  AppRouteUrl.ACTIVATE_ACCOUNT_URL,
  AppRouteUrl.RESET_PASSWORD_URL,
  AppRouteUrl.LOGIN_CALLBACK,
];
