import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {WithNavigate} from "types/ui";
import {ResidentialApplicationQualificationsResponse} from "@pinch-financial/pinch-ui-components";

const namespace = "ProductSelectPage";

export interface SubmitLenderPayload extends WithNavigate {
  lender: ResidentialApplicationQualificationsResponse;
}

export interface SubmitConsentPayload {
  consentedToContact: boolean;
}

export const submit = createAction<SubmitLenderPayload>(actionTypeOf(namespace, "submit"));

export const submitConsent = createAction<SubmitConsentPayload>(
  actionTypeOf(namespace, "submitConsent")
);
