import React from "react";
import {root} from "./AcceptLegalInfo.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import {Box, IconButton, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

interface AcceptLegalInfoProps {
  title?: string;
  children?: React.ReactNode;
  onAgree?: () => void;
  onClose?: () => void;
}

const AcceptLegalInfo = React.forwardRef(
  (
    {title = "", children = "", onClose = () => {}}: AcceptLegalInfoProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const {t} = useTranslation("", {keyPrefix: "components.templates.acceptLegalInfo"});

    return (
      <div className={root} ref={ref}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h5">{title}</Typography>
          </Box>
          <Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        {children}
      </div>
    );
  }
);

export default AcceptLegalInfo;
