import {UUID} from "@pinch-financial/pinch-ui-components";
import {createSelector} from "@reduxjs/toolkit";
import {random} from "lodash";
import {WhiteLabelDetailsDto} from "types/WhiteLabelDetailsDto";
import {maybe} from "types/basic";
import {FinancialInstitutionType} from "@pinch-financial/pinch-ui-components";
import {State} from "types/store";
import {WhitelabelState} from "types/whitelabelState";

export const get = (rootState: State): WhitelabelState => rootState.whitelabel;

export const getWhitelabel = createSelector(get, (state) => state.whitelabel);

export const getWhitelabelId = createSelector(getWhitelabel, (whiteLabel) => whiteLabel?.publicId);

export const getWhitelabelToken = createSelector(getWhitelabel, (whiteLabel) => whiteLabel?.token);

export const getWhitelabelName = createSelector(getWhitelabel, (whiteLabel) => whiteLabel?.name);

export const getWhiteLabelEntityState = createSelector(get, (state) => state.whitelabel?.state);

export const getWhitelabelTitleFr = createSelector(
  getWhitelabel,
  (whiteLabel) => whiteLabel?.titleFr
);

export const getWhitelabelTitleEn = createSelector(
  getWhitelabel,
  (whiteLabel) => whiteLabel?.titleEn
);

export const getDefaultProviderId = createSelector(get, (state) => state.defaultProviderId);

export const getSelectedProviderId = createSelector(get, (state) => state.selectedProviderId);
export const getHasUserSelectedProvider = createSelector(get, (state) =>
  Boolean(state.selectedProviderId)
);

export const getProducts = createSelector(get, (state) => [
  ...(state.bank?.products ?? []),
  ...(state.lender?.products ?? []),
]);

export const getSampleProduct = createSelector(get, (state) => new Error("Deprecated"));

export const getSelectedProvider = createSelector(
  getSelectedProviderId,
  getProducts,
  (selectedProviderId, providers) => {
    if (!providers || !providers?.length) {
      if (selectedProviderId) {
        console.error(
          `Fallback to undefined, for inconsistent state - has selectedProviderId=${selectedProviderId}, but no providers`
        );
      }
      return undefined;
    }
    if (!selectedProviderId) {
      return undefined;
    }
    const selected = providers.find(
      (provider) => provider?.financialInstitutionPublicId === selectedProviderId
    );
    if (!selected && selectedProviderId) {
      console.error(
        `Fallback to undefined, for inconsistent state - has selectedProviderId=${selectedProviderId}, and providers, but just can't find related provider.`
      );
    }
    return selected;
  }
);

export const getSelectedProviderType = createSelector(
  getSelectedProvider,
  (provider) => provider?.financialInstitutionType
);

export const getSelectedProviderName = createSelector(
  getSelectedProvider,
  (provider) => provider?.financialInstitutionName
);

export const getDirectProviders = createSelector(getProducts, (providers) => {
  if (!providers) {
    return undefined;
  }
  return providers.filter(
    (provider) => provider.financialInstitutionType === FinancialInstitutionType.BANK
  );
});

export const getDirectProvidersOrEmpty = createSelector(
  getDirectProviders,
  (providers) => providers || []
);

export const getProviderId = createSelector(
  getHasUserSelectedProvider,
  getSelectedProviderId,
  getDefaultProviderId,
  (hasUserSelectedProvider, selectedProviderId, defaultProviderId) => {
    if (hasUserSelectedProvider) {
      return selectedProviderId;
    }
    return defaultProviderId;
  }
);

export const getSelectedOrDefaultProviderName = createSelector(
  getHasUserSelectedProvider,
  getSelectedProviderName,
  getDefaultProviderId,
  getDirectProviders,
  getProducts,
  (hasUserSelectedProvider, selectedProviderName, defaultProviderId, directProviders, products) => {
    if (hasUserSelectedProvider) {
      // If the user has selected a provider, return its name.
      return selectedProviderName;
    }
    if (Boolean(defaultProviderId)) {
      // If there's a default provider ID, find its corresponding product.
      const selected = products.find(
        (product) => product?.financialInstitutionPublicId === defaultProviderId
      );
      if (!selected && defaultProviderId && Boolean(products?.length)) {
        console.error(
          `Fallback to undefined, for inconsistent state - has defaultProviderId=${defaultProviderId}, and providers, but just can't find related provider.`
        );
        return undefined;
      }
      return selected?.financialInstitutionName;
    }

    // If no selected provider, use the first available direct provider's name as the default.
    if (directProviders?.length) {
      return directProviders[0]?.financialInstitutionName ?? undefined;
    }

    // Return undefined if no selected provider and no direct providers are available.
    return undefined;
  }
);

export const getBrokerProviders = createSelector(getProducts, (providers) => {
  if (!providers) {
    return undefined;
  }
  return providers.filter(
    (provider) => provider.financialInstitutionType === FinancialInstitutionType.BROKERAGE
  );
});

export const getBankProvider = createSelector(get, (state: WhitelabelState) => state?.bank);
export const getLenderProvider = createSelector(get, (state: WhitelabelState) => state?.lender);
export const getBankProducts = createSelector(getBankProvider, (bank) => bank?.products);
export const getLenderProducts = createSelector(getLenderProvider, (lender) => lender?.products);

export const getBrokerProvidersOrEmpty = createSelector(
  getBrokerProviders,
  (providers) => providers || []
);

export const getBrokerageOrganizationIdsDistinct = createSelector(
  getBrokerProvidersOrEmpty,
  (brokerProviders) => {
    const brokerageIds: UUID[] = brokerProviders.map(
      (provider) => provider.financialInstitutionPublicId
    );
    const distinctBrokerageIds = new Set(brokerageIds);
    return Array.from(distinctBrokerageIds);
  }
);

export const getBrokerageOrganizationId = createSelector(
  getBrokerageOrganizationIdsDistinct,
  (distinctBrokerageIds) => {
    if (distinctBrokerageIds.length > 1) {
      console.error(
        "Fallback to use first brokearge, due to mult brokerages organizations found from products: ",
        distinctBrokerageIds
      );
    }
    return distinctBrokerageIds.length > 0 ? distinctBrokerageIds[0] : undefined;
  }
);

/**
 * @returns {number} The number of financial institutions that are configured to service this white label.
 */
export const getNumberFinancialInstitutions = createSelector(getProducts, (providers) => {
  return new Set(providers?.map((provider) => provider.financialInstitutionPublicId)).size;
});

export const getHasProviders = createSelector(getProducts, (providers) =>
  Boolean(providers?.length)
);

export const getWhiteLabelLogoUrlEn = createSelector(
  getWhitelabel,
  (wl: maybe<WhiteLabelDetailsDto>) =>
    wl?.logo && `${window._env_.REACT_APP_IMGIX_DOMAIN}/${wl.logo}`
);

export const getWhiteLabelLogoUrlFr = createSelector(
  getWhitelabel,
  (wl: maybe<WhiteLabelDetailsDto>) =>
    wl?.logoFr && `${window._env_.REACT_APP_IMGIX_DOMAIN}/${wl.logoFr}`
);

export const getWhiteLabelEmailJointInviteUrl = createSelector(
  getWhitelabel,
  (wl: maybe<WhiteLabelDetailsDto>): string | undefined =>
    wl?.emailJointInviteImage &&
    `${window._env_.REACT_APP_IMGIX_DOMAIN}/${wl.emailJointInviteImage}`
);

export const getWhiteLabelFaviconUrl = createSelector(
  getWhitelabel,
  (wl: maybe<WhiteLabelDetailsDto>) =>
    wl?.favicon && `${window._env_.REACT_APP_IMGIX_DOMAIN}/${wl.favicon}`
);

export const getWhiteLabelBackgroundColor = createSelector(
  getWhitelabel,
  (wl: maybe<WhiteLabelDetailsDto>) => wl?.logoBackgroundColor
);

// Pick one when no provider associated to app, no user selected, and no lead assignment provider.
export const pickProviderForDefaultProvider = createSelector(
  getBankProducts,
  getLenderProducts,
  (bankProducts, lenderProducts) => {
    if (lenderProducts?.length) {
      // if there's a brokerage lender, it should have been the leadAssignmentProviderId.
      return lenderProducts[0].financialInstitutionPublicId;
    }
    // If there's no lender, then there's no lead assignment happen, and leadAssignmentProviderId will be absent.
    if (bankProducts?.length) {
      const randomBank = bankProducts[random(bankProducts.length - 1)];
      return randomBank.financialInstitutionPublicId;
    }

    // Page would be broken, and it should have be redirected to error page prior to this.
    return undefined;
  }
);
