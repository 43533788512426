import {createSelector} from "@reduxjs/toolkit";
import {State} from "types/store";
import {Theme, ThemeState} from "types/theme";
import {getUtm} from "util/localStorageUtil";

export const getTheme = (rootState: State): ThemeState => rootState.theme || null;

// platform/name in theme, is the whitelabel name or token
export const getPlatform = createSelector(getTheme, (theme) => theme?.name);

export const getFeatures = createSelector(getTheme, (theme) => theme?.features);

export const getIsMortgageProviderLogoEnabled = createSelector(
  getFeatures,
  (features) => features?.isMortgageProviderLogoEnabled !== false
);

export const getHeaderLink = createSelector(getTheme, (theme) => {
  if (getUtm().utmSource == "rr") {
    return window._env_.REACT_APP_REGIONAL_REDIRECTOR_URL;
  }

  return theme?.headerText && theme?.headerLink ? theme?.headerLink : undefined;
});

export const getHasFeatureSin = createSelector(
  getTheme,
  (theme) => theme?.features?.enableSin !== false // has SIN feature if either undefined/true.
);
