import {useState} from "react";
import {ApplicantRelationshipType} from "types/enums/applicantRelationshipType";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getUserId} from "store/selectors/user";
import {
  getContactInfo,
  getIsLocked,
  getIsPrimaryAndHasSecondary,
  getIsSecondary,
} from "store/selectors/basicInfo";
import {submitInviteSecondary, submitNo} from "./InviteSecondaryApplicantPage.action";
import {getOperationFailure, getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {trackEvent, trackEventMemoized} from "util/eventUtil";
import {TextType, TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {
  InviteSecondaryApplicantPage as InviteSecondaryApplicantPageUI,
  InviteSecondaryApplicantPageFormData,
} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import i18next from "i18next";
import SaveButton from "components/SaveButton/SaveButton";
import usePageText from "hooks/usePageText";
import {useTranslation} from "react-i18next";

interface Props {
  className?: string;
  onPrevPage?: () => any;
  onNextPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const InviteSecondaryApplicantPage = ({
  onPrevPage = () => {},
  onNextPage = () => {},
  containerRef,
}: Props) => {
  const {t} = useTranslation("", {keyPrefix: "inviteSecondaryApplicantPage"});
  const {getText, status: textStatus} = usePageText("INVITE_SECONDARY_APPLICANT");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locked = useSelector(getIsLocked);
  const primaryUserId = useSelector(getUserId);
  const isSecondary = useSelector(getIsSecondary);
  const operationFailure = useSelector(getOperationFailure)(OperationType.inviteSecondaryApplicant);
  const isPrimaryAndHasSecondary: boolean = useSelector(getIsPrimaryAndHasSecondary);
  const {email} = useSelector(getContactInfo);

  const {isPending} = useSelector(getOperationResult)(OperationType.inviteSecondaryApplicant);
  const [otherError, setOtherError] = useState<string>(
    !primaryUserId ? t("errorMessages.userIdMissing")! : ""
  );

  function validate(): boolean {
    if (isSecondary) {
      setOtherError(t("errorMessages.primaryApplicantError")!);
      return false;
    }
    if (!primaryUserId) {
      setOtherError(t("errorMessages.userIdMissing")!);
      return false;
    }
    return true;
  }

  let operationFailureMessage = "";
  if (
    operationFailure?.status &&
    (operationFailure.status === 400 || operationFailure.status === 409)
  ) {
    operationFailureMessage = t("errorMessages.error_emailExists");
  } else if (operationFailure?.status && operationFailure.status != 200) {
    operationFailureMessage = t("errorMessages.error", {context: operationFailure?.status});
  }

  const onSubmit = (values: InviteSecondaryApplicantPageFormData) => {
    if (validate()) {
      trackEventMemoized(TrackingEventType.inviteSecondaryApplicantSubmit);
      dispatch(
        submitInviteSecondary({
          navigate,
          ...values,
        })
      );
    }
  };

  const onSubmitNo = () => {
    dispatch(
      submitNo({
        navigate,
      })
    );
  };

  const relationshipOptions = (typeVal: ApplicantRelationshipType) => {
    return t("fields.relationship.options", {context: typeVal});
  };

  const isKeyboardOpen = useDetectKeyboardOpen();

  return (
    <InviteSecondaryApplicantPageUI
      containerRef={containerRef}
      loading={textStatus === "not-started" || textStatus === "pending"}
      header={getText(TextType.APP_PAGE_HEADER) ?? t("header")}
      description={getText(TextType.APP_PAGE_BODY) ?? t("description")}
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? t("tipHeader")!}
      tipBody={
        <span
          dangerouslySetInnerHTML={{
            __html: getText(TextType.APP_PAGE_BODY, {email}) ?? t("tipBody", {email})!,
          }}
        />
      }
      onPrevPage={onPrevPage}
      onNextPage={onSubmit}
      isMobileKeyboardOpen={isKeyboardOpen}
      isPending={isPending}
      locked={locked}
      navigationBar={<NavigationBar />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" />}
      isPrimaryAndHasSecondary={isPrimaryAndHasSecondary}
      backButtonText={i18next.t("backButton")}
      firstNameLabel={t("fields.firstName.label")}
      firstNamePlaceholder={t("fields.firstName.placeholder")}
      lastNameLabel={t("fields.lastName.label")}
      lastNamePlaceholder={t("fields.lastName.placeholder")}
      emailLabel={t("fields.email.label")}
      emailPlaceholder={t("fields.email.placeholder")}
      relationshipLabel={t("fields.relationship.label")}
      relationshipOptions={relationshipOptions}
      completedHeader={t("completionTitle")}
      completedOnNextPage={() => {
        trackEvent(TrackingEventType.inviteSecondaryApplicantSubmit);
        onNextPage && onNextPage();
      }}
      completedNextButtonText={t("forwardButton")}
      completedDetails={t("completionDetailsPrimaryApplicant")}
      firstNameMissing={t("errorMessages.firstNameMissing")}
      lastNameMissing={t("errorMessages.lastNameMissing")}
      validEmailMissing={t("errorMessages.validEmailMissing")}
      relationshipMissing={t("errorMessages.relationshipMissing")}
      pageError={otherError || operationFailureMessage}
      nextButtonText={t("forwardButton")}
      yesText={t("yesText")}
      noText={t("noText")}
      onNoClick={onSubmitNo}
    />
  );
};

export default InviteSecondaryApplicantPage;
