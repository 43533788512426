import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {initialize} from "./CalculateQualificationPage.action";
import {GeneralPageTemplate, Message} from "@pinch-financial/pinch-ui-components";
import {CircularProgress, Typography} from "@mui/material";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {useTranslation} from "react-i18next";

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const CalculateQualification = ({containerRef}: Props) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "calculateQualificationPage"});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {failure} = useSelector(getOperationResult)(OperationType.fetchQualificationStatus);

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);

  return (
    <GeneralPageTemplate ref={containerRef}>
      <Message message={failure} />
      <CircularProgress />
      <Typography align="center" variant="h1">
        {fixedT("message")}
      </Typography>
    </GeneralPageTemplate>
  );
};

export default CalculateQualification;
