import {
  BanksAndFlinksPage as BanksAndFlinksPageUI,
  Status,
  TextType,
  UUID,
} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import {safeArray} from "components/utils/listUtil";
import i18next from "i18next";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getBanks, getCanAddBank, getIsAnyBankPending} from "store/selectors/basicInfo";
import {getWhitelabelId} from "store/selectors/whitelabel";
import {isProduction} from "util/envUtil";
import {trackEvent} from "util/eventUtil";
import {toLanguageCode} from "util/languageUtil";
import {initialize, submitBank} from "./BanksAndFlinksPage.action";
import {MessageType} from "types/ui";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import SaveButton from "components/SaveButton/SaveButton";
import usePageText from "hooks/usePageText";

const FLINKS_IFRAME_DOMAIN = "https://pinchfinancial-iframe.private.fin.ag/v2/";
export const showDemoInstitution = (whiteLabelId?: UUID) => {
  if (!isProduction()) {
    return window._env_.REACT_APP_FLINKS_DEMO === "true";
  }
  const isWhiteLabelDemoEnabled =
    !!whiteLabelId && whiteLabelId === window._env_.REACT_APP_FLINKS_DEMO_WHITE_LABEL_ID;
  return isWhiteLabelDemoEnabled;
};

const buildFlinksUrl = (shouldShowDemoInstitution: boolean, languageCode: string) => {
  const searchParams = new URLSearchParams();
  searchParams.append("?redirectUrl", ``);
  searchParams.append("daysOfTransactions", "Days365");
  searchParams.append("fetchAllAccounts", "true");
  searchParams.append("accountSelectorEnable", "false");
  searchParams.append("desktopLayout", "true");
  searchParams.append("institutionFilterEnable", "true");
  searchParams.append("theme", "light");
  searchParams.append("backgroundColor", "FFFFFF");
  searchParams.append("accountSelectorCurrency", "cad");
  searchParams.append("demo", `${shouldShowDemoInstitution}`);
  searchParams.append("language", languageCode);
  searchParams.append("detailsAndStatementEnable", "true");
  searchParams.append("monthsOfStatements", "Months3");
  searchParams.append("consentEnable", "false");
  searchParams.append("staticLoadingEnable", "true");
  return new URL(`${searchParams}`, FLINKS_IFRAME_DOMAIN);
};

interface Props {
  onNextPage?: () => any;
  onPrevPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const BanksAndFlinksPage = ({onNextPage, onPrevPage, containerRef}: Props) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "BanksAndFlinksPage"});
  const {getText, status: textStatus} = usePageText("BASIC_INFO_URL_ADD_BANK_INFO");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [addBankStarted, setAddBankStarted] = useState(false);
  const [iframeHistoryDepth, setIframeHistoryDepth] = useState(0);

  const canAddBank = useSelector(getCanAddBank);
  const isAnyBankPending = useSelector(getIsAnyBankPending);
  const banks = useSelector(getBanks);
  const whiteLabelId = useSelector(getWhitelabelId);
  const {failure} = useSelector(getOperationResult)(OperationType.submitNewBank);

  const shouldShowDemoInstitution = showDemoInstitution(whiteLabelId);
  const languageCode = toLanguageCode(i18next.language);
  const url = buildFlinksUrl(shouldShowDemoInstitution, languageCode);

  const handleFlinksRedirect = (loginId: string, institution: string) => {
    dispatch(
      submitBank({
        navigate,
        bankLoginId: loginId,
        bankInstitution: institution,
      })
    );
  };

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);

  useEffect(() => {
    const handleFlinksEvent = (event: MessageEvent) => {
      if (event.data?.step === "COMPONENT_LOAD_INSTITUTION_SELECTOR") {
        setIframeHistoryDepth((prevDepth) => prevDepth + 1);
      }
      if (event.data?.step === "COMPONENT_LOAD_CREDENTIAL") {
        setIframeHistoryDepth((prevDepth) => prevDepth + 1);
      }
      if (event.data?.step === "COMPONENT_LOAD_MFA") {
        setIframeHistoryDepth((prevDepth) => prevDepth + 1);
      }
    };

    window.addEventListener("message", handleFlinksEvent);
    return () => {
      window.removeEventListener("message", handleFlinksEvent);
    };
  }, []);

  const handlePrevPage = () => {
    if (iframeHistoryDepth > 0) {
      navigate(-iframeHistoryDepth);
    } else {
      onPrevPage?.();
    }
  };

  return (
    <BanksAndFlinksPageUI
      containerRef={containerRef}
      loading={textStatus === "not-started" || textStatus === "pending"}
      header={getText(TextType.APP_PAGE_HEADER) ?? fixedT("header")!}
      description={getText(TextType.APP_PAGE_BODY) ?? fixedT("description")}
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? fixedT("tipHeader")!}
      tipBody={getText(TextType.APP_PAGE_TIP_BODY) ?? fixedT("tipBody")}
      addBankButtonText={fixedT("addBankButtonText")}
      iconAlt={fixedT("iconAlt")}
      trackEvent={trackEvent}
      flinksUrl={url.href}
      onFlinksRedirect={handleFlinksRedirect}
      onAddBankStartedChange={setAddBankStarted}
      onNextPage={onNextPage}
      onPrevPage={handlePrevPage}
      isMobileKeyboardOpen={false}
      loadingMessage=""
      backButtonText={i18next.t("backButton")}
      nextButtonText={i18next.t("submitButton")}
      navigationBar={<NavigationBar />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" />}
      canAddBank={canAddBank}
      banks={safeArray(banks).map((bank) => {
        let text = fixedT("bankPending");
        let status: Status = "PENDING";

        if (bank.error) {
          text = fixedT("bankError");
          status = "FAILED";
        } else if (bank.financialDataId && bank.isDone) {
          text = fixedT("bankAdded");
          status = "SUCCESS";
        }
        return {
          id: bank.financialDataId,
          text,
          status,
        };
      })}
      pageNotificationMessage={
        failure
          ? i18next.t("errors.generalMessage")
          : isAnyBankPending
          ? fixedT("messages.pending")
          : undefined
      }
      pageNotificationType={
        failure ? MessageType.error : isAnyBankPending ? MessageType.info : undefined
      }
    />
  );
};

export default BanksAndFlinksPage;
