import {useDispatch, useSelector} from "react-redux";
import {getBankProducts, getLenderProducts} from "store/selectors/whitelabel";
import {getIconDisplayMapper} from "store/selectors/configurations";
import {TextType, WelcomePage as WelcomePageUI} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import React from "react";
import {safeArray} from "components/utils/listUtil";
import {hasClickedGetStarted} from "./WelcomePage.action";
import {useTranslation} from "react-i18next";
import usePageText from "hooks/usePageText";

interface Props {
  onNextPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const WelcomePage = ({onNextPage, containerRef}: Props) => {
  const {t} = useTranslation("", {keyPrefix: "welcomePage"});
  const {getText, status: textStatus} = usePageText("LANDING_PAGE_URL");

  const iconDisplayMapper = useSelector(getIconDisplayMapper);
  const bankProducts = useSelector(getBankProducts);
  const lenderProducts = useSelector(getLenderProducts);
  const products = [...(bankProducts ?? []), ...(lenderProducts ?? [])];
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(hasClickedGetStarted());
    if (onNextPage) {
      onNextPage();
    }
  };

  return (
    <WelcomePageUI
      containerRef={containerRef}
      loading={textStatus === "not-started" || textStatus === "pending"}
      header={getText(TextType.APP_PAGE_HEADER) ?? t("header")}
      body1={getText(TextType.APP_PAGE_BODY) ?? t("body1")}
      body2={getText(TextType.APP_PAGE_BODY2) ?? t("body2")}
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? t("tipHeader")}
      tipBody={getText(TextType.APP_PAGE_TIP_BODY) ?? t("tipBody")}
      nextButtonText={t("nextButton")}
      onNextPage={handleClick}
      products={safeArray(products).map((product) => {
        const {icon, background} = iconDisplayMapper(product);
        return {
          id: product.financialInstitutionPublicId,
          iconSrc: icon!,
          backgroundColor: `rgb(${background})`,
          name: product.productName,
        };
      })}
      navigationBar={<NavigationBar />}
      iconAlt={t("iconAlt")}
    />
  );
};

export default WelcomePage;
