import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getContactInfo, getIsLocked} from "store/selectors/basicInfo";
import {PurposeOfPurchaseType, TextType} from "@pinch-financial/pinch-ui-components";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {PurposeOfPurchasePage as PurposeOfPurchasePageUI} from "@pinch-financial/pinch-ui-components";
import {getNavigationError} from "store/selectors/navigationController";
import i18next from "i18next";
import NavigationBar from "components/NavigationBar/NavigationBar";
import SaveButton from "components/SaveButton/SaveButton";
import {submit} from "./PurposeOfPurchasePage.action";
import {getPurposeOfPurchase} from "store/selectors/applicantMeta";
import {useTranslation} from "react-i18next";
import usePageText from "~/hooks/usePageText";

interface Props {
  onPrevPage?: () => any;
  onNextPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const PurposeOfPurchasePage = ({
  containerRef,
  onPrevPage = () => {},
  onNextPage = () => {},
}: Props) => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "purposeOfPurchasePage"});
  const {getText, status: textStatus} = usePageText("PURPOSE_OF_PURCHASE_URL");

  const dispatch = useDispatch();
  const locked = useSelector(getIsLocked);
  const {email} = useSelector(getContactInfo);
  const storedPurposeOfPurchase = useSelector(getPurposeOfPurchase);
  const [purposeOfPurchase, setPurposeOfPurchase] = useState(storedPurposeOfPurchase);
  const [fieldError, setFieldError] = useState<string>();
  const navigationError = useSelector(getNavigationError);
  const {isPending} = useSelector(getOperationResult)(OperationType.submitPurposeOfPurchase);

  function onBack() {
    onPrevPage();
  }

  function onSetPurposeOfPurchase(purposeOfPurchase: PurposeOfPurchaseType) {
    if (locked) {
      setFieldError(fixedT("messages.appLocked")!);
    } else {
      setPurposeOfPurchase(purposeOfPurchase);
      dispatch(submit({purposeOfPurchase, onNextPage}));
    }
  }

  useEffect(() => {
    setFieldError(navigationError ?? "");
  }, [navigationError]);

  return (
    <PurposeOfPurchasePageUI
      containerRef={containerRef}
      loading={textStatus === "not-started" || textStatus === "pending"}
      header={getText(TextType.APP_PAGE_HEADER) ?? fixedT("header")!}
      description={getText(TextType.APP_PAGE_BODY) ?? fixedT("description")}
      tipHeader={getText(TextType.APP_PAGE_TIP_HEADER) ?? fixedT("tipHeader")!}
      tipBody={
        <span
          dangerouslySetInnerHTML={{
            __html: getText(TextType.APP_PAGE_TIP_BODY, {email}) ?? fixedT("tipBody", {email})!,
          }}
        />
      }
      navigationBar={<NavigationBar />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" />}
      backButtonText={i18next.t("backButton")}
      storedPurposeOfPurchase={purposeOfPurchase}
      pageError={fieldError}
      disabled={locked || isPending}
      onPrevPage={onBack}
      onClickPurposeButton={(purpose: PurposeOfPurchaseType) => onSetPurposeOfPurchase(purpose)}
      purposes={[
        PurposeOfPurchaseType.EXISTING_BUILD,
        PurposeOfPurchaseType.NEW_BUILD,
        PurposeOfPurchaseType.UNSURE,
      ].map((type) => ({
        type,
        title: fixedT(`purposeOfPurchase.${type}.title`),
        subtitle: fixedT(`purposeOfPurchase.${type}.subtitle`),
      }))}
      iconAlt={fixedT("iconAlt")}
    />
  );
};

export default PurposeOfPurchasePage;
