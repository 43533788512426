import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {EmploymentHistoryDto} from "types/dto/employmentHistoryDto";
import {WithNavigatePayload, Money} from "@pinch-financial/pinch-ui-components";

const namespace = "EmploymentInfo";

export interface EmploymentInfoFormData extends EmploymentHistoryDto {
  grossIncome: Money;
}

export const submitEmployment = createAction<WithNavigatePayload<EmploymentInfoFormData>>(
  actionTypeOf(namespace, "submit")
);
