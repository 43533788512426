import {call, put, select, takeEvery} from "redux-saga/effects";
import {submitLegalNamesSinOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {
  getCreditReportError,
  getCreditReportFailedAttempts,
  getDidUserConsentToCreditPull,
  getIsSecondary,
} from "store/selectors/basicInfo";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {Step} from "@pinch-financial/pinch-ui-components";
import {EquifaxPageFormData, submit} from "./EquifaxPage.action";
import {
  NavStep,
  ResidentialApplicationBasicInfoRequest,
  WithNavigatePayload,
} from "@pinch-financial/pinch-ui-components";
import {calculateStepStatusChange} from "components/NavigationBar/NavigationBar.action";
import {getIsCreditPullConsentDisplayedEnabled} from "~/store/selectors/providerconfigurations";

export function* onSubmit({payload}: WithPayload<WithNavigatePayload<EquifaxPageFormData>>) {
  const userId: string = yield select(getUserId);
  const {
    onNextPage,
    navigate,
    shouldStay,
    data: {legalFirstName, legalLastName, sinNo, dateOfBirth, consentedToCreditPull},
  } = payload;
  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    legalFirstName,
    legalLastName,
    sinNo,
    dateOfBirth,
    consentedToCreditPull,
  };

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitLegalNamesSinOperation.saga,
    userId,
    basicInfoRequest,
    Step.LEGAL_NAMES_SIN
  );

  if (Operation.isSuccess(result)) {
    const creditReportFailedAttempts: number = yield select(getCreditReportFailedAttempts);
    const creditReportError: string = yield select(getCreditReportError);
    const isSecondary: boolean = yield select(getIsSecondary);

    const failedButMoreAttemptsAllowed = creditReportError && creditReportFailedAttempts == 1;

    if (failedButMoreAttemptsAllowed) {
      // User might retry credit report
      const isCreditPullConsentDisplayed: boolean = yield select(
        getIsCreditPullConsentDisplayedEnabled
      );
      const userConsentedToCreditPull: boolean = yield select(getDidUserConsentToCreditPull);
      const userChoseNoPull = isCreditPullConsentDisplayed && !userConsentedToCreditPull;

      if (!userChoseNoPull) {
        return;
      }
    }
    if (isSecondary) {
      yield put(calculateStepStatusChange(NavStep.CREDIT));
    }

    !shouldStay && onNextPage?.();
  }
}

export default function* equifaxPageSaga() {
  yield takeEvery(submit, onSubmit);
}
