import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "./util";
import {
  AppRouteUrlKey,
  ResidentialApplicationBasicInfoRequest,
} from "@pinch-financial/pinch-ui-components";
import {PageStatus} from "~/types/ui";

const namespace = "applicantMeta";

export const saveBasicInfo = createAction<Partial<ResidentialApplicationBasicInfoRequest>>(
  actionTypeOf(namespace, "saveBasicInfo")
);

export const unsetPageStatus = createAction<AppRouteUrlKey>(
  actionTypeOf(namespace, "unsetPageState")
);

export const setPageStatus = createAction<{page: AppRouteUrlKey; status: PageStatus}>(
  actionTypeOf(namespace, "setPageStatus")
);
