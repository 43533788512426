import {TRACK_EVENT_DEBOUNCE_WAIT_MS} from "appConstants";
import {debounce} from "lodash";
import {TrackingEventType} from "@pinch-financial/pinch-ui-components";
import memoizeOne from "memoize-one";

export const trackEvent = (event: TrackingEventType | string, data?: string) => {
  // Deprecated
};

export const trackEventDebounced = debounce(trackEvent, TRACK_EVENT_DEBOUNCE_WAIT_MS);

export const trackEventMemoized = memoizeOne(trackEventDebounced);

export function preventDefaultBehaviour(event: any) {
  event.preventDefault();
  event.stopPropagation();
}
